import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { MESSAGE, BUTTON_TEXT } from "../../config/config";
import { Button } from "@material-ui/core";

export default function UserBlockedDialog({ openDialog, handleClick }) {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="dialog">
          BLOCKED
        </DialogTitle>
        <DialogContent id="alert-dialog-title">
          <div style={{ fontSize: 15, lineHeight: 1.5 }}>
            BLOCKED USER
            {/* <a
              style={{ color: "#832cff" }}
              href={MESSAGE.WELCOME_MESSAGE.MAILTO_LINK}
            >
              {MESSAGE.WELCOME_MESSAGE.EMAIL_TEXT}
            </a> */}
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{ padding: "10px 16px", color: "#832cff" }}
            onClick={handleClick}
            color="primary"
            autoFocus
          >
            {BUTTON_TEXT.OK}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
