import React from "react";
import { DOMAIN } from "../../config/config";

export const PdfView = (props) => {
  const { filePdf } = props.match.params;
  return (
    <div>
      <div>
        {/* http://www.pdf995.com/samples/pdf.pdf */}
        {/* https://gigsomething.sg/profile/sample.pdf */}

        <iframe
          src="https://docs.google.com/viewer?url=https://gigsomething.sg/profile/sample.pdf&embedded=true"
          frameborder="0"
        />
      </div>
    </div>
  );
};
