import List from "@material-ui/core/List";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  API,
  JOB_LIST_PAGE_SIZE,
  LOCAL_STORAGE_KEY,
} from "../../config/config";
import { FETCH_MY_GIGS, GET_AREAS } from "../../store/actions/actionTypes";
import { setAxiosToken, getNewAreas } from "../../ultils/ultils";
import GigItem from "../DashboardItem/Items/GigItem";

const JobsList = ({
  filterCondition,
  giggerId,
  isMyCompletedGigs,
  showApplications,
  isGigBossExpiredGigs,
}) => {
  const [hasMore, setHasMore] = useState(true);
  const [pageSize, setPageSize] = useState(JOB_LIST_PAGE_SIZE);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.reduxReducer);
  const areas = useSelector((state) => state.reduxReducer.areas);
  const onForeGroundStatus = useSelector(
    (state) => state.reduxReducer.onForeGroundStatus
  );
  useEffect(() => {
    const fetchAllAreas = async () => {
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      let areaData = getAllAreas.data;
      const newAreas = getNewAreas(areaData);
      dispatch({ type: GET_AREAS, payload: newAreas });
      //setAreas(getAllAreas.data);
    };
    if (!areas) {
      fetchAllAreas();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setAxiosToken(localStorage[LOCAL_STORAGE_KEY.TOKEN]);
      const result = await axios.post(API.GET_ALL_JOBS_BY_GIGGER_ID, {
        giggerId: giggerId,
        pageSize: pageSize,
        startIndex: 0,
        filterCondition: filterCondition,
      });
      // if (onForeGroundStatus === null) {
      //   dispatch({
      //     type: FETCH_MY_GIGS,
      //     payload: result.data,
      //     filter: filterCondition,
      //     loadNew: true,
      //   });
      // }
      dispatch({
        type: FETCH_MY_GIGS,
        payload: result.data,
        filter: filterCondition,
        loadNew: true,
      });
      // dispatch({
      //   type: FETCH_MY_GIGS,
      //   payload: result.data,
      //   filter: filterCondition,
      //   loadNew: true,
      // });
      //setJobsToRender(result.data);
    };

    if (!state[filterCondition]) {
      fetchData();
    } else {
      if (onForeGroundStatus !== null) {
        //alert("JOB_LIST_FETCH_AGAIN");
        fetchData();
      }
    }
  }, [filterCondition, onForeGroundStatus]);

  const fetchMoreData = () => {
    const getMoreData = async () => {
      const getJobs = await axios.post(API.GET_ALL_JOBS_BY_GIGGER_ID, {
        giggerId: giggerId,
        pageSize: pageSize,
        startIndex: state[filterCondition].length,
        filterCondition: filterCondition,
      });

      //setJobsToRender(jobsToRender.concat(getJobs.data));
      dispatch({
        type: FETCH_MY_GIGS,
        payload: getJobs.data,
        filter: filterCondition,
      });
      if (getJobs.data.length < pageSize) {
        setHasMore(false);
      }
    };

    getMoreData();
  };

  return (
    <Fragment>
      {areas ? (
        <div className="listJob">
          <List style={{ width: "100%", padding: 0 }}>
            {state[filterCondition] && state[filterCondition].length > 0 ? (
              <InfiniteScroll
                dataLength={state[filterCondition].length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <div style={{ textAlign: "center" }}>
                    <div class="loader"></div>
                  </div>
                }
              >
                {state[filterCondition].map((item) => {
                  let currentAreas =
                    item.Area.length > 0 ? item.Area.split(",") : [];
                  let areasName = [];
                  if (currentAreas.length > 0) {
                    currentAreas.map((currentArea) => {
                      let areaName = areas[parseInt(currentArea)].Name;
                      areasName.push(areaName);
                    });
                  }
                  return (
                    <GigItem
                      verticalList={false}
                      areasName={
                        areasName.length > 0 ? areasName.join(", ") : ""
                      }
                      item={item}
                      isMyCompletedGigs={isMyCompletedGigs}
                      showApplications={showApplications}
                      isGigBossExpiredGigs={isGigBossExpiredGigs}
                    />
                  );
                })}
              </InfiniteScroll>
            ) : null}
          </List>
        </div>
      ) : null}
    </Fragment>
  );
};

export default JobsList;
