import axios from "axios";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState, useEffect } from "react";
import {
  APPLY_GIG,
  SAVE_GIG,
  API,
  LOCAL_STORAGE_KEY,
  MESSAGE,
  WEBVIEW_ACTION,
  BUTTON_TEXT
} from "../../config/config";
import { sendMessage } from "../../ultils/ultils";

const JobDetailsDialog = (props) => {
  const hasError = props.hasError;
  const successfullySaved = props.successfullySaved;
  const openDialog = props.openDialog;
  const handleCloseDialog = props.handleCloseDialog;
  const isGigger = props.isGigger;
  const isConfirmedCancel = props.isConfirmedCancel;
  const refreshPage = props.refreshPage;
  const jobTitle = props.jobTitle;
  const giggerJob = props.giggerJob;

  const [giggerJobId, setGiggerJobId] = useState(null);

  useEffect(() => {
    if (giggerJob) {
      setGiggerJobId(giggerJob.Id);
    }
    console.log(giggerJob);
  }, [giggerJob]);

  const handleCancel = () => {
    const cancel = async () => {
      try {
        let data = {
          giggerJobId: giggerJobId
        };

        const result = await axios.post(API.DELETE_GIGGER_JOB_BY_ID, data);
        const error = result.data.error;
        if (!error) {
          sendMessage({ action: WEBVIEW_ACTION.RELOAD_MY_GIGS });
          handleCloseDialog();
          refreshPage();
        }
      } catch (error) {
        console.log(error);
      }
    };

    cancel();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!isConfirmedCancel && hasError && (
          <>
            <DialogTitle id="alert-dialog-title">
              {isGigger
                ? APPLY_GIG.DIALOG_MESSAGE.ERROR_TITLE
                : SAVE_GIG.DIALOG_MESSAGE.ERROR_TITLE}
            </DialogTitle>
            <DialogContent>
              {isGigger ? (
                <DialogContentText id="alert-dialog-description">
                  {APPLY_GIG.DIALOG_MESSAGE.ERROR}
                </DialogContentText>
              ) : (
                <DialogContentText id="alert-dialog-description">
                  {SAVE_GIG.DIALOG_MESSAGE.ERROR}
                </DialogContentText>
              )}
            </DialogContent>
          </>
        )}
        {!isConfirmedCancel && successfullySaved && (
          <>
            <DialogTitle id="alert-dialog-title">
              {isGigger
                ? APPLY_GIG.DIALOG_MESSAGE.SUCCESS_TITLE
                : SAVE_GIG.DIALOG_MESSAGE.SUCCESS_TITLE}
            </DialogTitle>
            <DialogContent>
              {isGigger ? (
                <DialogContentText id="alert-dialog-description">
                  {APPLY_GIG.DIALOG_MESSAGE.SUCCESS}
                </DialogContentText>
              ) : (
                <DialogContentText id="alert-dialog-description">
                  {SAVE_GIG.DIALOG_MESSAGE.SUCCESS}
                </DialogContentText>
              )}
            </DialogContent>
          </>
        )}
        {isConfirmedCancel && (
          <DialogContent id="alert-dialog-title">
            <div style={{ fontSize: 15 }}>
              {`${MESSAGE.CANCEL_ALERT} ${jobTitle}?`}
            </div>
          </DialogContent>
        )}
        <DialogActions>
          {!isConfirmedCancel && (
            <Button onClick={handleCloseDialog} color="primary" autoFocus>
              {BUTTON_TEXT.OK}
            </Button>
          )}
          {isConfirmedCancel && (
            <>
              <Button onClick={handleCancel} color="primary" autoFocus>
                {BUTTON_TEXT.YES}
              </Button>
              <Button onClick={handleCloseDialog} color="primary">
                {BUTTON_TEXT.NO}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobDetailsDialog;
