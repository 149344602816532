import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderItem from "../../Banner/SliderItem";
export const SliderPhotos = ({ photos }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    dots: true,
  };
  return (
    <div>
      <Slider {...settings} className="slider sliderPhoto">
        {photos.map((item, i) => (
          <SliderItem key={i} image={item} link={null} />
        ))}
        {/* <SliderItem
          image={
            "https://www.myfitnesschat.com/wp-content/uploads/2019/03/pexels-photo-1509428.jpeg"
          }
          link={"https://gigsomething.sg/Gigs/Details/42135"}
          openInternal={true}
        />
        <SliderItem
          image={
            "https://i.pinimg.com/originals/8f/21/51/8f2151358d662366a73364ac6d39d7ea.jpg"
          }
          link={"https://www.google.com/"}
          openInternal={false}
        /> */}
      </Slider>
    </div>
  );
};
