import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Slide,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import {
  API,
  COMMENT_FORM,
  DOMAIN,
  JOB_STATUS,
  LABELS,
  LOCAL_STORAGE_KEY,
  REGEX,
  REVIEW_MAX_LENGTH
} from "../config/config";
import NotificationPostedCommentDialog from "./JobDetails/NotificationPostedCommentDialog";
import ReviewErrorDialog from "./Reviews/ReviewErrorDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const labels = {
  0: LABELS.DEFAULT,
  1: LABELS.TERRIBLE,
  2: LABELS.POOR,
  3: LABELS.FAIR,
  4: LABELS.GOOD,
  5: LABELS.EXCELLENT
};

const FullScreenDialog = ({
  job,
  giggerJob,
  props,
  reviewId,
  openCommentDialog,
  handleCloseCommentDialog,
  isGigBossReviewGigger,
  revieweeId,
  setReviewRatingPercentKey,
  setApplicantsKey
}) => {
  //#region Initial

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(0);
  const [formData, setFormData] = useState({
    comment: "",
    charsLeft: REVIEW_MAX_LENGTH
  });
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [
    isSuccessfullyPostedComment,
    setIsSuccessfullyPostedComment
  ] = useState(false);
  const [isEditComment, setIsEditComment] = useState(false);
  const [isReviewExisting, setIsReviewExisting] = useState(false);
  const [newReviewId, setNewReviewId] = useState(0);
  const [isReloadAfterDeleteOrEdit, setIsReloadAfterDeleteOrEdit] = useState(
    false
  );
  const [fieldValidation, setFieldValidation] = useState({
    errors: {}
  });
  const [isOpenReviewErrorDialog, setIsOpenReviewErrorDialog] = useState(false);
  const [badWords, setBadWords] = useState([]);
  const [isContainLink, setIsContainLink] = useState(false);

  //#endregion

  //#region useEffect

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(API.GET_ALL_BAD_WORDS);
        console.log(result);

        const error = result.data.error;
        if (!error) {
          let words = result.data[0]["Value"].split(",");
          setBadWords(words);
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (props && props.location.state) {
      setIsReloadAfterDeleteOrEdit(
        props.location.state.isReloadAfterDeleteOrEdit
      );
    }
  }, [props]);

  useEffect(() => {
    const fetchData = async () => {
      if (job) {
        const isReviewExistingByJobIdAndReviewerId = await axios.post(
          API.IS_REVIEW_EXISTING_BY_JOB_ID_AND_REVIEWER_ID,
          { jobId: job.Id, token: localStorage[LOCAL_STORAGE_KEY.TOKEN] }
        );

        setIsReviewExisting(isReviewExistingByJobIdAndReviewerId.data);
      }
    };

    fetchData();
    setIsReloadAfterDeleteOrEdit(false);
  }, [job, isReloadAfterDeleteOrEdit]);

  useEffect(() => {
    const fetchData = async () => {
      if (reviewId) {
        const getReviewById = await axios.post(
          API.GET_GIGGER_REVIEW_RATING_BY_ID,
          { reviewId: reviewId }
        );

        if (getReviewById.data) {
          setFormData({
            comment: getReviewById.data[0]["Comment"],
            charsLeft:
              REVIEW_MAX_LENGTH - getReviewById.data[0]["Comment"].length
          });
          setValue(getReviewById.data[0]["Rating"]);
        }

        setIsEditComment(true);
      }

      if (openCommentDialog) {
        setOpen(openCommentDialog);
      }
    };

    fetchData();
  }, [reviewId, openCommentDialog]);

  //#endregion

  //#region Validations

  const handleValidation = () => {
    let errors = {};
    let isValidForm = true;

    if (value === 0) {
      isValidForm = false;
      errors["rating"] = COMMENT_FORM.ERROR_MESSAGE.RATING;
    }

    setFieldValidation({ errors: errors });
    return isValidForm;
  };

  //#endregion

  //#region onClick

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (handleCloseCommentDialog) {
      handleCloseCommentDialog(false);
    }
    setFormData({ comment: "" });
    setValue(0);
    setHover(0);
    setFieldValidation({ errors: {} });
  };

  const handleCloseNotificationDialog = () => {
    setOpenNotificationDialog(false);
  };

  const handleCloseReviewErrorDialog = () => {
    setIsOpenReviewErrorDialog(false);
  };

  const onSubmitClick = () => {
    const submit = async () => {
      if (handleValidation()) {
        try {
          let isReviewIncludeBadWord = badWords.some((word) =>
            formData.comment.toLocaleLowerCase().includes(word)
          );
          let isReviewIncludeLink =
            formData.comment.toLocaleLowerCase().includes("www") ||
            formData.comment.toLocaleLowerCase().includes("http") ||
            formData.comment.toLocaleLowerCase().includes("https");
          setIsContainLink(isReviewIncludeLink);

          if (!isReviewIncludeBadWord && !isReviewIncludeLink) {
            let data = {};
            let result = null;
            if (!reviewId) {
              data = {
                jobId: job.Id,
                revieweeId: revieweeId ? revieweeId : job.GigBossId,
                comment: formData.comment,
                rating: value,
                isGigBossReviewGigger: isGigBossReviewGigger,
                token: localStorage[LOCAL_STORAGE_KEY.TOKEN]
              };

              result = await axios.post(API.INSERT_GIGGER_REVIEW_RATING, data);
              setNewReviewId(!result.data.error ? result.data.ReviewId : 0);
              if (setApplicantsKey) {
                setApplicantsKey(1);
              }
            } else {
              data = {
                reviewRatingId: reviewId,
                comment: formData.comment,
                rating: value
              };

              result = await axios.post(API.UPDATE_GIGGER_REVIEW_RATING, data);
            }

            const error = result.data.error;
            // TODO: add spinner
            if (error) {
              // setSpinnerOn(false);
              // setHasError(true);
              // setOpenDialog(true);

              setIsSuccessfullyPostedComment(false);
            } else {
              // setSpinnerOn(false);
              // setActiveStep(0);
              // setHasError(false);
              // //setOpenDialog(true);
              // setDisableSave(true);
              setIsSuccessfullyPostedComment(true);
            }

            setOpenNotificationDialog(true);
          } else {
            setIsOpenReviewErrorDialog(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    submit();
  };

  //#endregion

  return (
    <div>
      {!reviewId ? (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={handleClickOpen}
          disabled={
            !giggerJob ||
            (giggerJob.Status === JOB_STATUS.GIG_COMPLETED &&
              isReviewExisting) ||
            giggerJob.Status !== JOB_STATUS.GIG_COMPLETED
          }
        >
          Write a review
        </Button>
      ) : null}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Review
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="listJob" style={{ marginTop: 56 }}>
          <List style={{ padding: 0 }}>
            <ListItem button>
              <Avatar
                className="avatar"
                //src={`${DOMAIN}profile/thumbnail-${job.Image}`}
                src={`${DOMAIN}profile/${
                  job.CompanyImage ? job.CompanyImage : job.Image
                }`}
              />
              <div className="content">
                <div className="title">{job.Title}</div>
                <span className="location">{job.AreaDescription}</span>
                <span className="time">{job.PayByDescription}</span>
              </div>
            </ListItem>
          </List>
        </div>
        <div className="ratingbox">
          {<h4 ml={2}>{labels[value]}</h4>}
          {/* <Rating
            name="hover-feedback"
            size="large"
            value={value}
            precision={1}
            onChange={(event, newValue) => {
              //alert(newValue);
              setValue(newValue ? newValue : 0);
              setFieldValidation({ errors: {} });
              //setFormData({ ...formData, rating: newValue });
            }}
            onChangeActive={(event, newHover) => {
              //alert(newHover);
              setHover(newHover);
            }}
          /> */}
          <Rating
            style={{ marginTop: 7 }}
            emptySymbol={
              <StarIcon fontSize="large" style={{ color: "#bdbdbd" }} />
            }
            fullSymbol={
              <StarIcon fontSize="large" style={{ color: "#ffb400" }} />
            }
            onChange={(value) => {
              setValue(value);
              setFieldValidation({ errors: {} });
            }}
            initialRating={value}
          />
          <span className="error" style={{ color: "red" }}>
            {fieldValidation.errors["rating"]}
          </span>
          <div className="type">
            <TextField
              name="description"
              aria-label="minimum height"
              label="Review"
              multiline
              variant="outlined"
              className="textarea"
              value={formData.comment}
              inputProps={{ maxlength: REVIEW_MAX_LENGTH }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  comment: e.target.value,
                  charsLeft: REVIEW_MAX_LENGTH - e.target.value.length
                });
              }}
            />
          </div>
          <span
            style={{
              color: "rgb(189, 189, 189)",
              fontSize: "14px",
              textAlign: "right",
              width: "100%"
            }}
          >
            {formData.charsLeft}/{REVIEW_MAX_LENGTH}
          </span>
        </div>
        <div className="btnWhatapp" style={{ paddingBottom: "15px" }}>
          <Button
            name="action"
            type="submit"
            variant="contained"
            color="primary"
            value="Save"
            style={{
              width: "100%",
              margin: "0 16px",
              maxWidth: "552px"
            }}
            onClick={onSubmitClick}
          >
            Submit
          </Button>
        </div>
      </Dialog>
      <NotificationPostedCommentDialog
        openDialog={openNotificationDialog}
        closeDialog={handleCloseNotificationDialog}
        closeCommentDialog={handleClose}
        isSuccessfullyPostedComment={isSuccessfullyPostedComment}
        jobCode={job.Code}
        props={props}
        isEditComment={isEditComment}
        setReviewRatingPercentKey={setReviewRatingPercentKey}
        newReviewId={newReviewId}
      />

      {isOpenReviewErrorDialog ? (
        <ReviewErrorDialog
          openDialog={isOpenReviewErrorDialog}
          handleClose={handleCloseReviewErrorDialog}
          isContainLink={isContainLink}
        />
      ) : null}
    </div>
  );
};

export default FullScreenDialog;
