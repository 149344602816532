import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { Container, Grid, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import FAQTabs from "./FAQTabs";
import { WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import DarkMode from "../DarkMode/DarkMode";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FAQ() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [darkState, setDarkState] = useState(null);
  const handleClose = () => {
    sendMessage({
      action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
      open: false,
    });
  };
  return (
    <DarkMode darkState={darkState}>
      <div>
        <Container className="bgCs" maxWidth="sm">
          <div className="cstandard">
            <div className="close">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <FAQTabs />
          </div>
        </Container>
      </div>
    </DarkMode>
  );
}
