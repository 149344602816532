import React, { Fragment, useEffect, useState } from "react";
import {
  API,
  GIGGER_TYPE_CONST,
  LOCAL_STORAGE_KEY,
  PROFILE,
} from "../../config/config";
import JobBrowsingDialog from "../JobBrowsing/JobBrowsingDialog";
import axios from "axios";
import { setAxiosToken } from "../../ultils/ultils";
export default function GigAlert({
  openState,
  handleClose,
  fromProfile,
  onForeground,
}) {
  const [filterData, setFilterData] = useState(null);
  //const [onForeground, setOnForeground] = useState(true);
  const [open, setOpen] = useState(openState);
  const [openFilter, setOpenFilter] = useState(false);
  const [areas, setAreas] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [isGigger, setIsGigger] = useState(false);
  const handleFilterJobsApply = (data) => {
    setFilterData(data);
    console.log("test filter");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseAlert = () => {
    handleClose();
  };
  // useEffect(() => {
  //   setOpen(openState);
  // }, [openState]);
  // const _onForeGround = (data) => {
  //   setOnForeground(true);
  // };
  // const event = new Event("WebViewBridge");
  // window.dispatchEvent(event);
  // useEffect(() => {
  //   window.WebViewBridge = {
  //     on_foreground: _onForeGround,
  //   };
  // }, []);
  useEffect(() => {
    //alert(localStorage.token);
    const fetchData = async () => {
      setAxiosToken(localStorage[LOCAL_STORAGE_KEY.TOKEN]);
      const getGigger = await axios.post(API.GET_GIGGER_BY_TOKEN, {
        token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
      });
      if (getGigger.data) {
        let giggerValue = parseInt(
          PROFILE.GIGER_TYPE.find(
            (type) =>
              type.label.toLowerCase() ===
              GIGGER_TYPE_CONST.GIGGER.toLowerCase()
          )?.value
        );
        setIsGigger(giggerValue === getGigger.data.Type);
      }
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      setAreas(getAllAreas.data);
      const getAllJobCategories = await axios.get(API.GET_ALL_JOB_CATEGORIES);
      setJobCategories(getAllJobCategories.data);
      const getGiggerFilterByGiggerIdResult = await axios.post(
        API.GET_GIGGER_FILTER_BY_GIGGER_ID,
        {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        }
      );

      if (
        getGiggerFilterByGiggerIdResult.data &&
        Object.keys(getGiggerFilterByGiggerIdResult.data.filterValue).length > 0
      ) {
        let filterValue = getGiggerFilterByGiggerIdResult.data.filterValue;
        setFilterData({
          areas: filterValue.areas,
          jobCategories: filterValue.jobCategories,
          dateFrom: filterValue.dateFrom,
          dateTo: filterValue.dateTo,
        });
      }
    };
    if (openState) {
      fetchData();
    }
  }, [openState]);
  return (
    <Fragment>
      <JobBrowsingDialog
        fromProfile={fromProfile}
        openDialog={openState}
        handleDialogClose={handleClose}
        onFilterJobsApply={handleFilterJobsApply}
        existingFilterData={filterData ? filterData : null}
      />
    </Fragment>
  );
}
