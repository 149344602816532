import React, { useState } from "react";
import Swal from "sweetalert2";
import { API, LOGIN } from "../config/config";

const Login = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    success: false
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = {
      Email: formData.email,
      Password: formData.password
    };

    //if (this.handleValidation(this.state))
    {
      fetch(`${API.LOGIN}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then((response) => {
          if (response.ok) {
            formData.success = true;
          } else {
            formData.success = false;
            Swal.fire({
              title: "Error",
              icon: "error",
              type: "error",
              text: LOGIN.ERROR_MESSAGE.INCORRECT_EMAIL_OR_PASSWORD
            });
          }
          return response.json();
        })
        .then((data) => {
          if (formData.success) {
            localStorage.setItem("token", data.token);
            //localStorage.setItem("username", this.state.username);
            props.history.push({
              pathname: "/"
            });
          }
        });
    }
  };

  return (
    <div>
      <div>
        <input
          type="text"
          name="email"
          className="form-control"
          placeholder="Enter email"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div>
        <input
          type="password"
          name="password"
          className="form-control"
          placeholder="Password"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div>
        <input
          type="button"
          className="form-control"
          value="Submit"
          onClick={(e) => handleSubmit(e)}
        />
      </div>
    </div>
  );
};

export default Login;
