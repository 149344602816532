import React, { Fragment, useEffect } from "react";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { createMuiTheme } from "@material-ui/core/styles";
import GigItem from "./Items/GigItem";
import { useState } from "react";
import {
  LOCAL_STORAGE_KEY,
  WEBVIEW_ACTION,
  API,
  BUTTON_TEXT,
} from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import ListJob from "../ListJob";

export default function GigForYou({ areas, onForeGround }) {
  const [giggerId, setGiggerId] = useState(
    localStorage[LOCAL_STORAGE_KEY.CURRENT_ID]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.CURRENT_ID])
      : null
  );
  const [filterData, setFilterData] = useState(null);

  //#region useEffect

  useEffect(() => {
    const fetchData = async () => {
      const getGiggerFilterByGiggerIdResult = await axios.post(
        API.GET_GIGGER_FILTER_BY_GIGGER_ID,
        {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        }
      );

      if (
        getGiggerFilterByGiggerIdResult.data &&
        Object.keys(getGiggerFilterByGiggerIdResult.data.filterValue).length > 0
      ) {
        let filterValue = getGiggerFilterByGiggerIdResult.data.filterValue;
        setFilterData({
          areas: filterValue.areas,
          jobCategories: filterValue.jobCategories,
          dateFrom: filterValue.dateFrom,
          dateTo: filterValue.dateTo,
        });
      }
    };

    fetchData();
  }, []);

  //#endregion

  const goToGigs = async () => {
    sendMessage({ action: WEBVIEW_ACTION.GO_TO_GIGS });
  };
  useEffect(() => {}, []);
  return (
    <Fragment>
      <h2>
        Gigs For You
        <Button size="small" color="primary" onClick={goToGigs}>
          {BUTTON_TEXT.VIEW_ALL}
        </Button>
      </h2>

      <div className="listJob">
        <ListJob
          filterData={filterData}
          isGigForYou={true}
          onForeGround={onForeGround}
        />
      </div>
    </Fragment>
  );
}
