import { Button, Container, Grid, Link } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import {
  API,
  BUTTON_TEXT,
  CLIENT_DOMAIN,
  DOMAIN,
  FORMAT,
  GIGGER_TYPE_CONST,
  GIGSOMETHING_SG_HOME_PAGE,
  JOB_STATUS,
  LOCAL_STORAGE_KEY,
  POST_GIG_FORM,
  WEBVIEW_ACTION,
} from "../config/config";
import Reviews from "../containers/Reviews";
import Loading from "../layout/Loading";
import { openUrl, sendMessage, setAxiosToken } from "../ultils/ultils";
import DarkMode from "./DarkMode/DarkMode";
import DialogComment from "./DialogComment";
import ApplyNotificationDialog from "./JobDetails/ApplyNotificationDialog";
import CompleteGigConfirmDialog from "./JobDetails/CompleteGigConfirmDialog";
import JobDetailsDialog from "./JobDetails/JobDetailsDialog";
import ReportGigConfirmDialog from "./JobDetails/ReportGigConfirmDialog";
import StopAcceptingApplicationsDialog from "./JobDetails/StopAcceptingApplicationsDialog";
import MissingUserInfoDialog from "./JobDetails/MissingUserInfoDialog";
import { Fragment } from "react";
import { Helmet } from "react-helmet";

const ExpansionPanel = withStyles({
  root: {
    marginBottom: 16,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    // "&$expanded": {
    //   margin: "16px 0"
    // }
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    minHeight: 36,
    "&$expanded": {
      minHeight: 36,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: "10px 16px",
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const getConfirmedSteps = () => {
  return [
    { title: JOB_STATUS.GIG_APPLIED },
    { title: JOB_STATUS.GIG_CONFIRMED },
    { title: JOB_STATUS.GIG_COMPLETED },
  ];
};

const getRejectedSteps = () => {
  return [
    { title: JOB_STATUS.GIG_APPLIED },
    { title: JOB_STATUS.GIG_REJECTED },
  ];
};

const JobDetails = (props) => {
  const { jobCode } = props.match.params;
  const [job, setJob] = useState(null);
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const [isFollow, setIsFollow] = useState(false);
  const [isGigger, setIsGigger] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [giggers, setGiggers] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [giggerJob, setGiggerJob] = useState(null);
  const [onApp, setonApp] = useState(false);
  const [onAppSave, setOnAppSave] = useState(false);
  const [tempToken, setTempToken] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [isJobPostedByCurrentUser, setIsJobPostedByCurrentUser] =
    useState(false);
  const [defaultDarkState, setDefaultDarkState] = useState(
    localStorage[LOCAL_STORAGE_KEY.DARK_MODE]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.DARK_MODE])
      : false
  );
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [activeStep, setActiveStep] = React.useState(-1);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [
    allGiggerReviewRatingsNoLimitation,
    setAllGiggerReviewRatingsNoLimitation,
  ] = useState([]);
  const [reviewsData, setReviewData] = useState({
    revieweeId: 0,
    existingReviewId: null,
  });
  const [
    disableStopAcceptingApplications,
    setDisableStopAcceptingApplications,
  ] = useState(false);
  const [hideCancel, setHideCancel] = useState(true);
  const [isConfirmedCancel, setIsConfirmedCancel] = useState(false);
  const [reloadPage, setReloadPage] = useState(0);
  const [isApplied, setIsApplied] = useState(false);
  const [isConfirmOrRejectClicked, setIsConfirmOrRejectClicked] =
    useState(false);
  const [updatedApplications, setUpdatedApplications] = useState([]);
  const [
    openStopAcceptingApplicationsDialog,
    setOpenStopAcceptingApplicationsDialog,
  ] = useState(false);
  const [applicationsCanReview, setApplicationsCanReview] = useState([]);
  const [openReportGigConfirmDialog, setOpenReportGigConfirmDialog] =
    useState(false);
  const [openCompleteGigConfirmDialog, setOpenCompleteGigConfirmDialog] =
    useState(false);
  const [openApplyNotificationDialog, setOpenApplyNotificationDialog] =
    useState(false);
  const [openMissingUserInfoDialog, setOpenMissingUserInfoDialog] =
    useState(false);
  const [applicantsKey, setApplicantsKey] = useState(0);
  const [gigBossInfo, setGigBossInfo] = useState(null);

  const classes = useStyles();
  const confirmedSteps = getConfirmedSteps();
  const rejectedSteps = getRejectedSteps();

  const _onMessage = (data) => {
    if (data.onApp) {
      //alert("ON_GIG_DETAIL");
      setonApp(true);
      if (data.token) {
        localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, data.token);
        localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_ID, data.currentId);
      }
      //alert(localStorage.token);
    }
  };
  const _onGigBossAction = async () => {
    setOnAppSave(true);
  };
  const _onLikedGig = async () => {
    try {
      if (currentId) {
        //alert(currentId);
        const updateLikeStatus = await axios.post(API.UPDATE_LIKED_GIG, {
          UserId: currentId,
          GigId: jobId,
          Code: jobCode,
        });
        const { message } = updateLikeStatus.data;
        sendMessage({
          action: WEBVIEW_ACTION.UPDATE_TITLE,
          showLikeIcon: currentId ? true : false,
          isLiked: message ? true : false,
        });
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (onAppSave) {
      handleSaveChanges();
    }
  }, [onAppSave]);
  // useEffect(() => {
  //   if (selectedApplications.length > 0) {
  //     alert(JSON.stringify({ selectedApplications }));
  //   }
  // }, [selectedApplications]);
  useEffect(() => {
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    window.WebViewBridge = {
      on_detail: _onMessage,
      gig_boss_action: _onGigBossAction,
      on_liked_gig: _onLikedGig,
    };
  }, []);
  useEffect(() => {
    const getJobId = async () => {
      // GET JOB_ID
      try {
        //console.log("GET JOB_ID");
        let result = await axios.post(API.GET_JOB_ID_BY_JOB_CODE, { jobCode });
        //console.log({ result: result.data });
        setJobId(result.data);
      } catch (error) {
        console.log({ error });
        if (currentId) {
          window.location.href = `${CLIENT_DOMAIN}PageNotFound/${true}`;
        } else {
          window.location.href = `${CLIENT_DOMAIN}PageNotFound`;
        }
      }
    };
    getJobId();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setAxiosToken(localStorage[LOCAL_STORAGE_KEY.TOKEN]);
      const getJobById = await axios.post(API.GET_JOB_BY_ID, {
        jobId: jobId,
      });
      let likedStatus = null;
      if (currentId) {
        const getLikedStatus = await axios.post(API.GET_LIKED_STATUS, {
          UserId: currentId,
          GigId: jobId,
        });

        likedStatus = getLikedStatus.data.IsLiked;
        //alert(JSON.stringify({ likedStatus }));
      }
      if (getJobById.data) {
        sendMessage({
          action: WEBVIEW_ACTION.UPDATE_TITLE,
          title: getJobById.data[0].Title,
          showLikeIcon: currentId ? true : false,
          isLiked: likedStatus,
        });
        if (localStorage[LOCAL_STORAGE_KEY.TOKEN]) {
          const getGiggerByToken = await axios.post(API.GET_GIGGER_BY_TOKEN, {
            token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
          });
          setIsJobPostedByCurrentUser(
            getGiggerByToken.data["Id"] === getJobById.data[0]["GigBossId"]
          );
        }

        setJob(getJobById.data[0]);

        const getGigBossInfo = await axios.post(API.GET_GIGGER_BY_ID, {
          giggerId: getJobById.data[0]["GigBossId"],
        });
        if (getGigBossInfo.data) {
          setGigBossInfo(getGigBossInfo.data);

          const currentUserFollowings = await axios.post(
            API.GET_FOLLOW_BY_FOLLOWER_ID,
            {
              token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
            }
          );

          if (
            currentUserFollowings.data &&
            currentUserFollowings.data.length > 0
          ) {
            var isCurrentGigBossExistingInFollowings =
              currentUserFollowings.data.some(
                (a) => a.UserId === getGigBossInfo.data.Id
              );
            setIsFollow(isCurrentGigBossExistingInFollowings);
          }
        }
      }

      const getGiggerJobByJobId = await axios.post(
        API.GET_GIGGER_JOB_BY_JOB_ID,
        {
          jobId: jobId,
        }
      );
      if (getGiggerJobByJobId.data) {
        let giggerIds = [];
        getGiggerJobByJobId.data.map((giggerJob) => {
          giggerIds.push(giggerJob.GigerId);
          setSelectedApplications((oldArray) => [
            ...oldArray,
            {
              giggerId: giggerJob.GigerId,
              isJobConfirmed: giggerJob.Status === JOB_STATUS.GIG_CONFIRMED,
              isJobRejected: giggerJob.Status === JOB_STATUS.GIG_REJECTED,
              currentJobStatus: giggerJob.Status,
            },
          ]);
        });
        const getGiggerById = await axios.post(API.GET_GIGGERS_BY_ID, {
          giggerIds: giggerIds,
        });
        setGiggers(getGiggerById.data);
      }

      const getGiggerJobByJobIdAndGiggerId = await axios.post(
        API.GET_GIGGER_JOB_BY_JOB_ID_AND_GIGGER_ID,
        { jobId: jobId, token: localStorage[LOCAL_STORAGE_KEY.TOKEN] }
      );
      if (
        getGiggerJobByJobIdAndGiggerId.data &&
        getGiggerJobByJobIdAndGiggerId.data.length > 0
      ) {
        // setDisableSave(true);
        setGiggerJob(getGiggerJobByJobIdAndGiggerId.data[0]);
        setHideCancel(
          getGiggerJobByJobIdAndGiggerId.data[0]["Status"] ===
            JOB_STATUS.GIG_APPLIED
        );
        const giggerJobStatus = getGiggerJobByJobIdAndGiggerId.data[0]["Status"]
          ? getGiggerJobByJobIdAndGiggerId.data[0]["Status"].trim()
          : "";
        switch (giggerJobStatus) {
          case JOB_STATUS.GIG_CONFIRMED:
          case JOB_STATUS.GIG_REJECTED:
            setActiveStep(1);
            break;

          case JOB_STATUS.GIG_COMPLETED:
            setActiveStep(2);
            break;
          default:
            setActiveStep(0);
            break;
        }
      } else {
        setDisableSave(false);
      }

      const getAllGiggerReviewRatingsNoLimitationByJobId = await axios.post(
        API.GET_ALL_GIGGER_REVIEW_RATINGS_NO_LIMITATION_BY_JOB_ID,
        { jobId: jobId }
      );
      if (getAllGiggerReviewRatingsNoLimitationByJobId.data) {
        setAllGiggerReviewRatingsNoLimitation(
          getAllGiggerReviewRatingsNoLimitationByJobId.data[0]
        );
      }

      let applicationsCanReviewArr = [];
      if (getGiggerJobByJobId.data) {
        getGiggerJobByJobId.data.map((giggerJob) => {
          if (
            getAllGiggerReviewRatingsNoLimitationByJobId.data[0].some(
              (review) => review.RevieweeId === giggerJob.GigerId
            )
          )
            return giggerJob;

          if (giggerJob.Status === JOB_STATUS.GIG_COMPLETED) {
            applicationsCanReviewArr.push({ giggerId: giggerJob.GigerId });
          }
        });
        setApplicationsCanReview(applicationsCanReviewArr);
      }
    };
    if (jobId) {
      fetchData();
      setSpinnerOn(false);

      if (reloadPage === 1) {
        setActiveStep(-1);
        setHideCancel(true);
        setGiggerJob(null);
        setDisableSave(true);
        setReloadPage(0);
        setIsApplied(false);
        setIsConfirmOrRejectClicked(false);
        setSpinnerOn(true);
        setApplicantsKey(0);
        console.log("re-render successfully");
      }
    }
  }, [jobId, reloadPage, openStopAcceptingApplicationsDialog, applicantsKey]);

  const refreshPage = () => {
    setReloadPage(1);
  };

  const handleSaveChanges = async (e, isApplyButtonClick) => {
    // if (!localStorage.token) {
    //   props.history.push({
    //     pathname: "/login"
    //   });
    //   return;
    // }
    if (onApp) {
      if (!localStorage[LOCAL_STORAGE_KEY.TOKEN]) {
        //alert("ONE");
        sendMessage({
          action: WEBVIEW_ACTION.GO_AUTH,
          jobData: { JobId: jobCode },
        });
        return;
      } else {
        if (localStorage[LOCAL_STORAGE_KEY.TOKEN]) {
          //setSpinnerOn(true);
          if (!isJobPostedByCurrentUser) {
            console.log("GIGER");

            const getGiggerByToken = await axios.post(API.GET_GIGGER_BY_TOKEN, {
              token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
            });
            const { FirstName, LastName, PhoneNumber } = getGiggerByToken.data;

            if (
              FirstName &&
              FirstName.length > 0 &&
              LastName &&
              LastName.length > 0 &&
              PhoneNumber &&
              PhoneNumber.length > 0
            ) {
              const applyType = isApplyButtonClick
                ? "App"
                : job.ApplyVia === POST_GIG_FORM.APPLY_VIA.FORM
                ? "Form"
                : job.ApplyVia;
              const result = await axios.post(API.INSERT_GIGGER_JOB, {
                jobId: jobId,
                token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
                jobStatus: confirmedSteps[0].title,
                applyType: applyType,
              });
              const error = result.data.error;
              if (error) {
                //setSpinnerOn(false);
                setHasError(true);
                setOpenDialog(true);
              } else {
                //setSpinnerOn(false);
                if (isApplyButtonClick) {
                  setOpenApplyNotificationDialog(true);
                } else {
                  setReloadPage(1);
                  sendMessage({
                    action: WEBVIEW_ACTION.APPLY_JOB,
                    jobData: { JobId: jobCode },
                    applyVia: job.ApplyVia,
                    formUrl: job.ApplyFormUrl,
                  });
                }
                // sendMessage({
                //   action: WEBVIEW_ACTION.RELOAD_PAGE,
                // });
              }
            } else {
              setOpenMissingUserInfoDialog(true);
            }
          } else {
            console.log("GIG BOSS");
            let applications = [];
            selectedApplications.map((application) => {
              if (
                !updatedApplications.some(
                  (a) => a.giggerId === application.giggerId
                ) ||
                (!application.isJobConfirmed && !application.isJobRejected)
              )
                return application;

              let status = "";
              if (application.isJobConfirmed && !application.isJobRejected) {
                status = JOB_STATUS.GIG_CONFIRMED;
              }

              if (!application.isJobConfirmed && application.isJobRejected) {
                status = JOB_STATUS.GIG_REJECTED;
              }

              applications.push({
                giggerId: application.giggerId,
                jobId: jobId,
                status: status,
              });
            });

            const result = await axios.post(API.UPDATE_GIGGER_JOB, {
              applications: applications,
            });
            sendMessage({
              action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
            });
            console.log("HERE");
            console.log(result);
            const error = result.data.error;
            if (error) {
              //setSpinnerOn(false);
              setHasError(true);
              setOpenDialog(true);
            } else {
              //setSpinnerOn(false);
              setHasError(false);
              setOpenDialog(true);
              setDisableSave(true);
              setIsConfirmOrRejectClicked(false);
              handleGigBossAction({ isSaved: true });
              //setGiggerJobsToHideConfirmAndRejectButton(result.data.giggerIds);
              if (result.data.giggerIds.length > 0) {
                setSelectedApplicationsAfterUpdate(result.data.giggerIds);
              }
            }
          }
        }
      }
    } else {
      window.location.href = GIGSOMETHING_SG_HOME_PAGE;
    }
  };
  useEffect(() => {}, []);
  const setSelectedApplicationsAfterUpdate = (giggerIds) => {
    let newSelectedApplications = [...selectedApplications];
    giggerIds.map((giggerJob) => {
      let applicationToUpdateIndex = newSelectedApplications.findIndex(
        (a) => a.giggerId === giggerJob.giggerId
      );
      newSelectedApplications.splice(applicationToUpdateIndex, 1);
      newSelectedApplications.splice(applicationToUpdateIndex, 0, {
        giggerId: giggerJob.giggerId,
        isJobConfirmed: giggerJob.status === JOB_STATUS.GIG_CONFIRMED,
        isJobRejected: giggerJob.status === JOB_STATUS.GIG_REJECTED,
        currentJobStatus: giggerJob.status,
      });
    });
    setSelectedApplications(newSelectedApplications);
    console.log(newSelectedApplications);
  };

  const goToGigProfile = async ({ GiggerUserName, type }) => {
    try {
      const { UserName, GigBossId } = job;
      // let profileName;

      const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
      //alert(currentId);
      switch (type) {
        case GIGGER_TYPE_CONST.GIGBOSS:
          if (GigBossId !== parseInt(currentId)) {
            sendMessage({
              action: WEBVIEW_ACTION.GO_TO_GIG_PROFILE,
              profileName: UserName,
              canShare: true,
            });
          }
          break;
        case GIGGER_TYPE_CONST.GIGGER:
          sendMessage({
            action: WEBVIEW_ACTION.GO_TO_GIG_PROFILE,
            profileName: GiggerUserName,
            canShare: true,
          });
          break;
        default:
          break;
      }
    } catch (error) {}
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setHasError(false);
  };

  const handleConfirmJob = (e, giggerId) => {
    setDisableSave(false);
    setIsConfirmOrRejectClicked(true);
    setUpdatedApplications((oldArray) => [
      ...oldArray,
      {
        giggerId: giggerId,
      },
    ]);
    let newSelectedApplications = [...selectedApplications];
    const selectedApplicationIndex = selectedApplications.findIndex(
      (application) => application.giggerId === giggerId
    );
    newSelectedApplications.splice(selectedApplicationIndex, 1);
    newSelectedApplications.splice(selectedApplicationIndex, 0, {
      giggerId: giggerId,
      isJobConfirmed: true,
      isJobRejected: false,
      currentJobStatus:
        selectedApplications[selectedApplicationIndex]["currentJobStatus"],
    });

    setSelectedApplications(newSelectedApplications);
    handleGigBossAction({ isSaved: false });
  };

  const handleRejectJob = (e, giggerId) => {
    setDisableSave(false);
    setIsConfirmOrRejectClicked(true);
    setUpdatedApplications((oldArray) => [
      ...oldArray,
      {
        giggerId: giggerId,
      },
    ]);
    let newSelectedApplications = [...selectedApplications];
    const selectedApplicationIndex = selectedApplications.findIndex(
      (application) => application.giggerId === giggerId
    );
    newSelectedApplications.splice(selectedApplicationIndex, 1);
    newSelectedApplications.splice(selectedApplicationIndex, 0, {
      giggerId: giggerId,
      isJobConfirmed: false,
      isJobRejected: true,
      currentJobStatus:
        selectedApplications[selectedApplicationIndex]["currentJobStatus"],
    });

    setSelectedApplications(newSelectedApplications);
    handleGigBossAction({ isSaved: false });
  };

  const handleOpenCommentDialogClick = (e, revieweeId, review) => {
    // alert("DONE");
    setOpenCommentDialog(true);

    setReviewData({
      ...reviewsData,
      revieweeId: revieweeId,
      existingReviewId: review ? review.Id : null,
    });
  };

  const handleStopAcceptingApplicationsClick = () => {
    const stopAcceptingApplications = async () => {
      try {
        let data = {
          jobId: jobId,
          dataToUpdate: { AcceptApplications: 0 },
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        };

        const updateResult = await axios.post(API.UPDATE_JOB, data);
        const error = updateResult.data.error;
        if (error) {
          setHasError(true);
          //setOpenDialog(true);
        } else {
          setHasError(false);
          //setOpenDialog(true);
          setDisableStopAcceptingApplications(true);
          handleCloseStopAcceptingApplicationsDialog();
          //setDisableSave(true);
        }
      } catch (error) {}
    };

    stopAcceptingApplications();
  };

  const handleCancelClick = () => {
    setOpenDialog(true);
    setIsConfirmedCancel(true);
  };

  const handleOpenStopAcceptingApplicationsDialog = () => {
    setOpenStopAcceptingApplicationsDialog(true);
  };

  const handleCloseStopAcceptingApplicationsDialog = () => {
    setOpenStopAcceptingApplicationsDialog(false);
  };

  //#region Reporting Confirm Dialog

  const handleReportGigClick = () => {
    setOpenReportGigConfirmDialog(true);
  };

  const handleCloseReportGigConfirmDialog = () => {
    setOpenReportGigConfirmDialog(false);
  };

  //#endregion

  //#region Complete Gig Confirm Dialog

  const handleCompleteGigClick = () => {
    setOpenCompleteGigConfirmDialog(true);
  };

  const handleCloseCompleteGigDialog = () => {
    setOpenCompleteGigConfirmDialog(false);
  };

  //#endregion

  //#region Apply Notification Dialog

  const handleCloseApplyNotificationDialog = () => {
    setOpenApplyNotificationDialog(false);
  };

  //#endregion

  //#region Missing User Info Dialog

  const handleCloseMissingUserInfoDialog = () => {
    setOpenMissingUserInfoDialog(false);
  };

  //#endregion

  // const handleCloseCommentDialogClick = () => {
  //   setOpenCommentDialog(false);
  // };

  // Toggle Dark Mode
  const [darkState, setDarkState] = useState(null);
  const createClickedLog = async (logData) => {
    try {
      await axios.post(API.CREATE_CLICK_LOG, logData, {});
    } catch (error) {
      //console.log({ createClickedLogError: error });
    }
  };
  const contactViaWhatsapp = async ({ type, contactNumber }) => {
    if (!localStorage[LOCAL_STORAGE_KEY.TOKEN]) {
      sendMessage({
        action: WEBVIEW_ACTION.GO_AUTH,
        jobData: { JobId: jobCode },
      });
      return;
    } else {
      if (contactNumber) {
        //alert(contactNumber);
        if (currentId) {
          const logData = {
            UserId: currentId,
            GigCode: jobCode,
            Type: BUTTON_TEXT.CONTACT_VIA_WHATSAPP,
          };
          await createClickedLog(logData);
        }
        sendMessage({
          action: WEBVIEW_ACTION.CONTACT_VIA_WHATSAPP,
          contactNumber: contactNumber,
          type: type,
          gigTitle: job.Title,
        });
      }
    }
  };
  console.log({ job });
  const handleClickUrl = (url) => {
    if (url) {
      openUrl(url, onApp);
    }
  };
  const handleGigBossAction = ({ isSaved }) => {
    sendMessage({ action: WEBVIEW_ACTION.GIG_BOSS_ACTION, isSaved: isSaved });
  };
  const duplicateGig = () => {
    if (parseInt(currentId)) {
      sendMessage({
        action: WEBVIEW_ACTION.ON_DUPLICATE_GIG,
        jobId: jobId,
      });
    }
  };
  const followGig = async () => {
    try {
      if (currentId) {
        let token = tempToken
          ? tempToken
          : localStorage[LOCAL_STORAGE_KEY.TOKEN];
        await setAxiosToken(token);
        const result = await axios.post(API.FOLLOW_GIG, {
          username: gigBossInfo.UserName,
        });
        sendMessage({
          action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
        });
        if (result) {
          setIsFollow(true);
        }
      } else {
        sendMessage({ action: WEBVIEW_ACTION.LOG_OUT });
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const unfollowGig = async () => {
    try {
      let token = tempToken ? tempToken : localStorage[LOCAL_STORAGE_KEY.TOKEN];
      await setAxiosToken(token);
      const result = await axios.post(API.UN_FOLLOW_GIG, {
        username: gigBossInfo.UserName,
      });
      sendMessage({
        action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
      });
      if (result.data) {
        setIsFollow(false);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  return (
    <DarkMode darkState={darkState}>
      {/* <Switch checked={darkState} onChange={handleThemeChange} /> */}
      <div className="jobdetails" style={{ marginBottom: "-60px" }}>
        <Container maxWidth="sm" style={{ padding: "0" }}>
          {spinnerOn ? (
            <div style={{ position: "fixed", left: "50%", top: "50%" }}>
              <Loading />
            </div>
          ) : (
            <>
              {job ? (
                <>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{job.Title}</title>
                  </Helmet>
                  <div className="stepbg">
                    {!isJobPostedByCurrentUser &&
                      !giggerJob &&
                      localStorage[LOCAL_STORAGE_KEY.TOKEN] && (
                        <div>
                          <Stepper
                            className="Stepper"
                            steps={confirmedSteps}
                            activeStep={activeStep}
                            activeColor="#832cff"
                            completeColor="#832cff"
                            circleFontSize={13}
                            titleFontSize={13}
                            size={25}
                            circleTop={5}
                            defaultBarColor="#bdbdbd"
                            completeBarColor="#bdbdbd"
                            activeTitleColor={
                              defaultDarkState == true ? "#fbfbfb" : "#000000"
                            }
                            completeTitleColor={
                              defaultDarkState == true ? "#fbfbfb" : "#000000"
                            }
                          />
                        </div>
                      )}
                    {!isJobPostedByCurrentUser &&
                      giggerJob &&
                      localStorage[LOCAL_STORAGE_KEY.TOKEN] &&
                      (giggerJob.Status !== JOB_STATUS.GIG_REJECTED ? (
                        <div>
                          <Stepper
                            className="Stepper"
                            steps={confirmedSteps}
                            activeStep={activeStep}
                            activeColor="#832cff"
                            completeColor="#832cff"
                            circleFontSize={13}
                            titleFontSize={13}
                            size={25}
                            circleTop={5}
                            defaultBarColor="#bdbdbd"
                            completeBarColor="#bdbdbd"
                            activeTitleColor={
                              defaultDarkState == true ? "#fbfbfb" : "#000000"
                            }
                            completeTitleColor={
                              defaultDarkState == true ? "#fbfbfb" : "#000000"
                            }
                          />
                        </div>
                      ) : (
                        <div>
                          <Stepper
                            className="Stepper"
                            steps={rejectedSteps}
                            activeStep={activeStep}
                            activeColor="#832cff"
                            completeColor="#832cff"
                            circleFontSize={13}
                            titleFontSize={13}
                            size={25}
                            circleTop={5}
                            defaultBarColor="#bdbdbd"
                            completeBarColor="#bdbdbd"
                            activeTitleColor={
                              defaultDarkState == true ? "#fbfbfb" : "#000000"
                            }
                            completeTitleColor={
                              defaultDarkState == true ? "#fbfbfb" : "#000000"
                            }
                          />
                        </div>
                      ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Avatar
                      className="avatar"
                      style={{ width: 65, height: 65, marginTop: 20 }}
                      // src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jpg"
                      src={`${DOMAIN}/profile/${
                        job.CompanyImage ? job.CompanyImage : job.Image
                      }`}
                      onClick={() =>
                        goToGigProfile({ type: GIGGER_TYPE_CONST.GIGBOSS })
                      }
                    />
                    {process.env.NODE_ENV === "development" && <p>TEST</p>}
                  </div>

                  <div className="title" style={{ marginBottom: 14 }}>
                    <h2 style={{ textAlign: "center" }}>{job.Title}</h2>
                    {job?.Website && (
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickUrl(job.Website)}
                      >
                        {job.Website}
                      </Link>
                    )}
                    <span className="location">{job.AreaDescription}</span>
                    <h4>{job.PayByDescription}</h4>
                    {onApp && gigBossInfo && gigBossInfo.UserName && (
                      <Fragment>
                        {!isFollow ? (
                          <div className="followbtnGig">
                            <Button
                              variant="primary"
                              size="small"
                              onClick={followGig}
                            >
                              {BUTTON_TEXT.FOLLOW}
                            </Button>
                          </div>
                        ) : (
                          <div className="followbtnGig">
                            <Button
                              variant="primary"
                              size="small"
                              onClick={unfollowGig}
                            >
                              {BUTTON_TEXT.UNFOLLOW}
                            </Button>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                  <div className="contentDetail">
                    <ExpansionPanel defaultExpanded className="ExpansionPanel">
                      <ExpansionPanelSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        className="ExpansionPanelSummary"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className="fwbold">Description</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        className="ExpansionPanelDetails"
                        style={{ flexDirection: "column" }}
                      >
                        <Grid item xs={12}>
                          {gigBossInfo?.CompanyName &&
                          gigBossInfo?.CompanyName.trim() != "" ? (
                            <div className="typographyflex">
                              <span>Company Name:</span>{" "}
                              {gigBossInfo.CompanyName}
                            </div>
                          ) : null}
                          {gigBossInfo?.Website &&
                          gigBossInfo?.Website.trim() != "" ? (
                            <div className="typographyflex">
                              <span>Website:</span>{" "}
                              <Link
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleClickUrl(gigBossInfo.Website)
                                }
                              >
                                {gigBossInfo.Website}
                              </Link>
                            </div>
                          ) : null}
                          {gigBossInfo?.ContactName &&
                          gigBossInfo?.ContactName.trim() != "" ? (
                            <div className="typographyflex">
                              <span>Contact Name:</span>{" "}
                              {gigBossInfo.ContactName}
                            </div>
                          ) : null}
                          <div className="typographyflex">
                            <span>Gig Type:</span> {job.JobCategoryDescription}
                          </div>
                          <div className="typographyflex flexcolm">
                            <span>Description:</span>{" "}
                            <span className="multiline">
                              {job.Descriptions}
                            </span>
                          </div>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {/* {job.JobScope && job.JobScope.length > 0 && (
                      <ExpansionPanel
                        defaultExpanded
                        className="ExpansionPanel"
                      >
                        <ExpansionPanelSummary
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                          className="ExpansionPanelSummary"
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography className="fwbold">Gig Scope</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          className="ExpansionPanelDetails"
                          style={{ flexDirection: "column" }}
                        >
                          <Typography>{job.JobScope}</Typography>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )} */}
                    <ExpansionPanel defaultExpanded className="ExpansionPanel">
                      <ExpansionPanelSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                        className="ExpansionPanelSummary"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className="fwbold">Requirement</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        className="ExpansionPanelDetails"
                        style={{ flexDirection: "column" }}
                      >
                        {job.Requirement && job.Requirement.length > 0 && (
                          <div className="typographyflex flexcolm">
                            <span>Requirement:</span>{" "}
                            <span className="multiline">{job.Requirement}</span>
                          </div>
                        )}
                        <div className="typographyflex">
                          <span>Pax:</span> {job.NoPerson}
                        </div>
                        {/* <div className="typographyflex">
                      <span>Skills:</span> {job.Skills}
                    </div> */}
                        {job.Attire && job.Attire.length > 0 && (
                          <div className="typographyflex flexcolm">
                            <span>Attire:</span>{" "}
                            <span className="multiline">{job.Attire}</span>
                          </div>
                        )}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {(job.Location && job.Location.length > 0) ||
                    (job.Area && job.Area > 0) ? (
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary
                          aria-controls="panel4d-content"
                          id="panel4d-header"
                          className="ExpansionPanelSummary"
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography className="fwbold">Location</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          className="ExpansionPanelDetails"
                          style={{ flexDirection: "column" }}
                        >
                          {job.Location && job.Location.length > 0 ? (
                            <div className="typographyflex">
                              <span>Location: </span> {job.Location}
                            </div>
                          ) : null}
                          {job.Area && job.Area > 0 ? (
                            <div className="typographyflex">
                              <span>Area:</span> {job.AreaDescription}
                            </div>
                          ) : null}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    ) : null}
                    <ExpansionPanel defaultExpanded className="ExpansionPanel">
                      <ExpansionPanelSummary
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                        className="ExpansionPanelSummary"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className="fwbold">Time</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        className="ExpansionPanelDetails"
                        style={{ flexDirection: "column" }}
                      >
                        <div className="typographyflex">
                          <span>Date:</span>{" "}
                          {moment(job.DateFrom).format(FORMAT.DATE_TIME_CLIENT)}
                          {job.DateTo !== ""
                            ? ` to ${moment(job.DateTo).format(
                                FORMAT.DATE_TIME_CLIENT
                              )}`
                            : ""}
                        </div>
                        <div className="typographyflex">
                          <span>Time:</span>
                          {job.HourFrom}
                          {job.DateTo && job.DateTo !== ""
                            ? ` to ${job.HourTo}`
                            : ""}
                        </div>
                        <div className="typographyflex">
                          <span>Application Deadline:</span>{" "}
                          {moment(job.Deadline).format(FORMAT.DATE_TIME_CLIENT)}
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel defaultExpanded className="ExpansionPanel">
                      <ExpansionPanelSummary
                        aria-controls="panel7d-content"
                        id="panel7d-header"
                        className="ExpansionPanelSummary"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className="fwbold">Contact Us</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        className="ExpansionPanelDetails"
                        style={{ flexDirection: "column" }}
                      >
                        {(gigBossInfo?.UserName &&
                          gigBossInfo?.UserName.trim() !== "") ||
                        (gigBossInfo?.FirstName &&
                          gigBossInfo?.FirstName.trim()) ? (
                          <div className="typographyflex">
                            <span>Gig Boss:</span>
                            {gigBossInfo.FirstName &&
                            gigBossInfo.FirstName.trim() !== ""
                              ? `${gigBossInfo.FirstName}${
                                  gigBossInfo.LastName &&
                                  gigBossInfo.LastName.trim() !== ""
                                    ? ` ${gigBossInfo.LastName}`
                                    : ""
                                }`
                              : `${gigBossInfo.UserName}`}
                          </div>
                        ) : null}
                        <div className="typographyflex">
                          <span>Phone:</span>
                          <a
                            href={`tel:${job.ContactNumber}`}
                            className="linkt"
                          >
                            {job.ContactNumber}
                          </a>
                        </div>
                        {job.ContactEmail ? (
                          <div className="typographyflex">
                            <span>Email:</span>
                            <a
                              href={`mailto:${job.ContactEmail}`}
                              className="linkt"
                            >
                              {job.ContactEmail}
                            </a>
                          </div>
                        ) : null}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {isJobPostedByCurrentUser && (
                      <ExpansionPanel
                        defaultExpanded
                        className="ExpansionPanel"
                      >
                        <ExpansionPanelSummary
                          aria-controls="panel8d-content"
                          id="panel8d-header"
                          className="ExpansionPanelSummary"
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography className="fwbold">Applicants</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          style={{ flexDirection: "column" }}
                          className="groupApplicants"
                        >
                          {giggers.map((gigger) => {
                            let applications = [...selectedApplications];
                            let selectedApplication = applications.find(
                              (application) =>
                                parseInt(application.giggerId) === gigger.Id
                            );
                            console.log(selectedApplication);
                            let severity = "";
                            switch (selectedApplication.currentJobStatus) {
                              case JOB_STATUS.GIG_CONFIRMED:
                                severity = "info";
                                break;
                              case JOB_STATUS.GIG_REJECTED:
                                severity = "error";
                                break;
                              case JOB_STATUS.GIG_COMPLETED:
                                severity = "success";
                                break;
                              default:
                                severity = "none";
                                break;
                            }
                            selectedApplication.currentJobStatus =
                              selectedApplication.currentJobStatus !==
                              JOB_STATUS.GIG_APPLIED
                                ? selectedApplication.currentJobStatus
                                : "";

                            let existingReview =
                              allGiggerReviewRatingsNoLimitation.find(
                                (reviewRating) =>
                                  reviewRating.RevieweeId === gigger.Id &&
                                  reviewRating.Type === 1
                              );

                            return (
                              <div className="applicants">
                                {selectedApplication.currentJobStatus !== "" ? (
                                  <div>
                                    <Alert severity={severity}>
                                      {selectedApplication.currentJobStatus}
                                    </Alert>
                                  </div>
                                ) : null}

                                <div
                                  style={
                                    selectedApplication.currentJobStatus &&
                                    selectedApplication.currentJobStatus !== ""
                                      ? {
                                          paddingBottom: 10,
                                          paddingTop: 16,
                                        }
                                      : { paddingBottom: 10 }
                                  }
                                >
                                  <Typography
                                    variant="h5"
                                    component="h3"
                                    style={{ color: "#832CFF" }}
                                    onClick={() =>
                                      goToGigProfile({
                                        GiggerUserName: gigger.UserName,
                                        type: GIGGER_TYPE_CONST.GIGGER,
                                      })
                                    }
                                  >
                                    {gigger.FirstName &&
                                    gigger.FirstName.trim() !== ""
                                      ? `${gigger.FirstName}${
                                          gigger.LastName &&
                                          gigger.LastName.trim() !== ""
                                            ? ` ${gigger.LastName}`
                                            : ""
                                        }`
                                      : `${gigger.UserName}`}
                                  </Typography>
                                  <Typography>
                                    <a
                                      href={`mailto:${gigger.Email}`}
                                      className="linkt"
                                    >
                                      {gigger.Email}
                                    </a>
                                  </Typography>
                                  <Typography>
                                    <a
                                      href={`tel:${gigger.PhoneNumber}`}
                                      className="linkt"
                                    >
                                      {gigger.PhoneNumber}
                                    </a>
                                  </Typography>
                                </div>
                                <div className="button">
                                  <div>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      style={{
                                        display:
                                          job.Status ===
                                            JOB_STATUS.GIG_COMPLETED ||
                                          selectedApplication.currentJobStatus ===
                                            JOB_STATUS.GIG_COMPLETED ||
                                          selectedApplication.currentJobStatus ===
                                            JOB_STATUS.GIG_CONFIRMED
                                            ? "none"
                                            : "",
                                      }}
                                      disabled={
                                        selectedApplication.isJobConfirmed ||
                                        (selectedApplication.currentJobStatus ===
                                          JOB_STATUS.GIG_CONFIRMED &&
                                          selectedApplication.currentJobStatus &&
                                          selectedApplication.currentJobStatus.trim() !==
                                            "")
                                      }
                                      onClick={(e) =>
                                        handleConfirmJob(
                                          e,
                                          selectedApplication.giggerId
                                        )
                                      }
                                    >
                                      {BUTTON_TEXT.CONFIRM}
                                    </Button>
                                  </div>

                                  <div>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={{
                                        //width: "100%",
                                        // margin: "0 16px",
                                        // maxWidth: "552px",
                                        backgroundColor: "#25D366",
                                        color: "#ffffff",
                                      }}
                                      //disabled={job.AcceptApplications === 0}
                                      onClick={() =>
                                        contactViaWhatsapp({
                                          type: GIGGER_TYPE_CONST.GIGBOSS,
                                          contactNumber: gigger.PhoneNumber,
                                        })
                                      }
                                    >
                                      {BUTTON_TEXT.CHAT}
                                    </Button>
                                  </div>
                                  <div>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="secondary"
                                      style={{
                                        display:
                                          job.Status ===
                                            JOB_STATUS.GIG_COMPLETED ||
                                          selectedApplication.currentJobStatus ==
                                            JOB_STATUS.GIG_COMPLETED ||
                                          selectedApplication.currentJobStatus ===
                                            JOB_STATUS.GIG_REJECTED
                                            ? "none"
                                            : "",
                                      }}
                                      disabled={
                                        selectedApplication.isJobRejected ||
                                        (selectedApplication.currentJobStatus ===
                                          JOB_STATUS.GIG_REJECTED &&
                                          selectedApplication.currentJobStatus &&
                                          selectedApplication.currentJobStatus.trim() !==
                                            "")
                                      }
                                      onClick={(e) =>
                                        handleRejectJob(
                                          e,
                                          selectedApplication.giggerId
                                        )
                                      }
                                    >
                                      {BUTTON_TEXT.REJECT}
                                    </Button>
                                    {selectedApplication.currentJobStatus ===
                                    JOB_STATUS.GIG_COMPLETED ? (
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={(e) =>
                                          handleOpenCommentDialogClick(
                                            e,
                                            gigger.Id,
                                            existingReview
                                          )
                                        }
                                      >
                                        {!existingReview
                                          ? "Review"
                                          : "Edit Review"}
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}
                  </div>
                  {currentId && (
                    <div style={{ margin: "16px" }}>
                      <Link
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "#832cff",
                        }}
                        onClick={() => handleReportGigClick()}
                      >
                        Report Gig
                      </Link>
                    </div>
                  )}

                  <Reviews job={job} giggerJob={giggerJob} props={props} />

                  <div className="btnDetails">
                    {!isJobPostedByCurrentUser && onApp && (
                      <>
                        <Button
                          name="action"
                          type="submit"
                          variant="contained"
                          style={{
                            width: "100%",
                            margin: "0 16px",
                            maxWidth: "552px",
                            backgroundColor: "#25D366",
                            color: "#ffffff",
                          }}
                          disabled={job.AcceptApplications === 0}
                          onClick={() =>
                            contactViaWhatsapp({
                              type: GIGGER_TYPE_CONST.GIGGER,
                              contactNumber: job.ContactNumber,
                            })
                          }
                        >
                          {BUTTON_TEXT.CONTACT_VIA_WHATSAPP}
                        </Button>
                      </>
                    )}
                    {!isJobPostedByCurrentUser ? (
                      <>
                        {((!giggerJob || (!giggerJob && hideCancel)) &&
                          !isApplied) ||
                        (giggerJob &&
                          giggerJob.Status !== JOB_STATUS.GIG_APPLIED &&
                          giggerJob.Status !== JOB_STATUS.GIG_CONFIRMED) ? (
                          <>
                            {onApp ? (
                              <>
                                <Button
                                  name="action"
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    width: "100%",
                                    margin: "0 16px",
                                  }}
                                  onClick={(e) => handleSaveChanges(e, false)}
                                  disabled={
                                    disableSave ||
                                    job.AcceptApplications === 0 ||
                                    job.Deadline < job.CurrentDate
                                  }
                                >
                                  {job.AcceptApplications === 0 ||
                                  job.Deadline < job.CurrentDate
                                    ? `${BUTTON_TEXT.NO_LONGER_ACCEPTING_APPLICATIONS}`
                                    : `${
                                        job.ApplyVia ===
                                        POST_GIG_FORM.APPLY_VIA.WHATSAPP
                                          ? BUTTON_TEXT.APPLY_VIA_WHATSAPP
                                          : BUTTON_TEXT.APPLY_VIA_FORM
                                      }`}
                                </Button>

                                {job.AcceptApplications === 1 &&
                                job.Deadline >= job.CurrentDate &&
                                job.ApplyVia ===
                                  POST_GIG_FORM.APPLY_VIA.WHATSAPP ? (
                                  <Button
                                    name="action"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      width: "100%",
                                      margin: "0 16px",
                                    }}
                                    onClick={(e) => handleSaveChanges(e, true)}
                                    disabled={
                                      disableSave ||
                                      job.AcceptApplications === 0 ||
                                      job.Deadline < job.CurrentDate
                                    }
                                  >
                                    {BUTTON_TEXT.APPLY}
                                  </Button>
                                ) : null}
                              </>
                            ) : (
                              <Button
                                name="action"
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="btnLonger"
                                style={{
                                  width: "100%",
                                  margin: "0 16px",
                                }}
                                onClick={handleSaveChanges}
                              >
                                {BUTTON_TEXT.DOWNLOAD_AND_APPLY}
                              </Button>
                            )}
                          </>
                        ) : null}
                        {(giggerJob || !hideCancel) &&
                        giggerJob &&
                        giggerJob.Status !== JOB_STATUS.GIG_REJECTED &&
                        giggerJob.Status !== JOB_STATUS.GIG_COMPLETED &&
                        onApp ? (
                          <Button
                            name="action"
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{
                              width: "100%",
                              margin: "0 16px",
                              maxWidth: "552px",
                            }}
                            onClick={handleCancelClick}
                          >
                            {BUTTON_TEXT.CANCEL}
                          </Button>
                        ) : null}
                      </>
                    ) : (
                      <Fragment>
                        <div className="btn3">
                          <div className="clm3">
                            <div style={{ marginRight: 16 }}>
                              <Button
                                name="action"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                style={{
                                  marginRight: 16,
                                }}
                                onClick={
                                  handleOpenStopAcceptingApplicationsDialog
                                }
                                disabled={
                                  job.AcceptApplications === 0 ||
                                  disableStopAcceptingApplications
                                }
                              >
                                {BUTTON_TEXT.STOP_ACCEPTING_APPLICATIONS}
                              </Button>
                            </div>
                            <div style={{ marginRight: 16 }}>
                              <Button
                                name="action"
                                type="submit"
                                color="secondary"
                                variant="contained"
                                disabled={
                                  job.Status === JOB_STATUS.GIG_COMPLETED
                                }
                                onClick={() => handleCompleteGigClick()}
                                style={{
                                  backgroundColor: "#25d366",
                                }}
                              >
                                {BUTTON_TEXT.COMPLETE_GIG}
                              </Button>
                            </div>
                            <div>
                              <Button
                                name="action"
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="edit"
                                onClick={duplicateGig}
                                // disabled={disableSave || !isConfirmOrRejectClicked}
                              >
                                {BUTTON_TEXT.EDIT_REPOST}
                              </Button>
                            </div>
                          </div>
                          <Button
                            name="action"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="savebtn"
                            style={{
                              width: "100%",
                              margin: "0 16px",
                            }}
                            onClick={handleSaveChanges}
                            disabled={disableSave || !isConfirmOrRejectClicked}
                          >
                            {BUTTON_TEXT.SAVE}
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </>
              ) : null}
            </>
          )}
          {openDialog && (
            <JobDetailsDialog
              openDialog={openDialog}
              hasError={hasError}
              isGigger={isGigger}
              successfullySaved={!hasError}
              handleCloseDialog={handleCloseDialog}
              isConfirmedCancel={isConfirmedCancel}
              refreshPage={refreshPage}
              jobTitle={job.Title}
              giggerJob={giggerJob}
            />
          )}

          {openCommentDialog ? (
            <DialogComment
              job={job}
              props={props}
              openCommentDialog={openCommentDialog}
              handleCloseCommentDialog={setOpenCommentDialog}
              isGigBossReviewGigger={true}
              revieweeId={reviewsData.revieweeId}
              reviewId={reviewsData.existingReviewId}
              setApplicantsKey={setApplicantsKey}
            />
          ) : null}

          {openStopAcceptingApplicationsDialog ? (
            <StopAcceptingApplicationsDialog
              openDialog={openStopAcceptingApplicationsDialog}
              handleCloseDialog={handleCloseStopAcceptingApplicationsDialog}
              handleStopAcceptingApplicationsClick={
                handleStopAcceptingApplicationsClick
              }
            />
          ) : null}

          {openReportGigConfirmDialog ? (
            <ReportGigConfirmDialog
              openDialog={openReportGigConfirmDialog}
              handleCloseDialog={handleCloseReportGigConfirmDialog}
              jobId={job.Id}
            />
          ) : null}

          {openCompleteGigConfirmDialog ? (
            <CompleteGigConfirmDialog
              openDialog={openCompleteGigConfirmDialog}
              handleCloseDialog={handleCloseCompleteGigDialog}
              applications={selectedApplications}
              jobId={job.Id}
              setSelectedApplicationsAfterUpdate={
                setSelectedApplicationsAfterUpdate
              }
              refreshPage={refreshPage}
            />
          ) : null}

          {openApplyNotificationDialog ? (
            <ApplyNotificationDialog
              openDialog={openApplyNotificationDialog}
              handleCloseDialog={handleCloseApplyNotificationDialog}
              refreshPage={refreshPage}
            />
          ) : null}

          {openMissingUserInfoDialog ? (
            <MissingUserInfoDialog
              openDialog={openMissingUserInfoDialog}
              handleCloseDialog={handleCloseMissingUserInfoDialog}
              isGigBossNotification={false}
            />
          ) : null}
        </Container>
      </div>
    </DarkMode>
  );
};

export default JobDetails;
