import React, { Fragment, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GigItem from "./Items/GigItem";
import {
  LOCAL_STORAGE_KEY,
  JOB_STATUS,
  API,
  WEBVIEW_ACTION,
  MY_GIGS_VALUE,
  BUTTON_TEXT
} from "../../config/config";
import axios from "axios";
import { sendMessage } from "../../ultils/ultils";
import InfiniteScroll from "react-infinite-scroll-component";
export default function CompletedGigs({ areas }) {
  const [giggerId, setGiggerId] = useState(
    localStorage[LOCAL_STORAGE_KEY.CURRENT_ID]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.CURRENT_ID])
      : null
  );
  const [completedGigs, setCompletedGigs] = useState([]);
  useEffect(() => {
    const getCompletedGigs = async () => {
      const result = await axios.post(API.GET_ALL_JOBS_BY_GIGGER_ID, {
        giggerId,
        pageSize: 5,
        startIndex: 0,
        filterCondition: JOB_STATUS.GIG_COMPLETED
      });
      // console.log({ completed: result.data });
      setCompletedGigs(result.data);
    };
    if (giggerId) {
      getCompletedGigs();
    }
  }, [giggerId]);
  const goToMyGigs = async () => {
    sendMessage({
      action: WEBVIEW_ACTION.GO_TO_MY_GIGS,
      value: MY_GIGS_VALUE.COMPLETED
    });
  };
  return (
    <Fragment>
      {completedGigs.length > 0 && (
        <Fragment>
          <h2>
            My Completed Gigs
            <Button size="small" color="primary" onClick={goToMyGigs}>
              {BUTTON_TEXT.VIEW_ALL}
            </Button>
          </h2>

          <div className="listJob listJobscroll">
            {completedGigs.map((item) => (
              <GigItem
                verticalList={false}
                item={item}
                area={areas.find((area) => area.Id === item.Area)}
              />
            ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
