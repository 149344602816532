import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect, Fragment } from "react";
import {
  DOMAIN,
  LOCAL_STORAGE_KEY,
  WEBVIEW_ACTION,
  CLIENT_DOMAIN,
} from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import FollowList from "../FollowsList";
import { FOLLOW_KEYWORD } from "../../config/keywords";
import FollowBadge from "./FollowBadge";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    medium: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginBottom: 7,
    },
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
);
export default function Follows({
  followers,
  followerTitle,
  followingTitle,
  username,
  following,
}) {
  //src={`${DOMAIN}profile/thumbnail-${follow.Image}`}
  const classes = useStyles();
  let currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const [isAllow, setIsAllow] = useState(true);
  const goToProfile = async ({ profileId, profileName }) => {
    //alert(profileId);
    if (parseInt(profileId) !== parseInt(currentId)) {
      sendMessage({
        action: WEBVIEW_ACTION.GO_TO_GIG_PROFILE,
        profileName: profileName,
      });
    }
  };
  const goToFollowPage = ({ keyword }) => {
    if (isAllow) {
      sendMessage({
        action: WEBVIEW_ACTION.GO_TO_FOLLOW_PAGE,
        url: `${CLIENT_DOMAIN}Follow/${username ? username : null}/${keyword}`,
        title: keyword,
      });
      setIsAllow(false);
    }
  };
  useEffect(() => {
    if (!isAllow) {
      setTimeout(() => {
        setIsAllow(true);
      }, 500);
    }
  }, [isAllow]);
  return (
    <div className="follow">
      <div className="group">
        <Fragment>
          {followers ? (
            <div className="content">
              <h4>
                {followerTitle} {`(${followers.length})`}
              </h4>
              {followers.length > 0 ? (
                <AvatarGroup className="AvatarGroup" max={10}>
                  {followers.length > 0 &&
                    followers.map((item, index) => (
                      <Avatar
                        key={index}
                        alt="user image"
                        src={`${DOMAIN}profile/${item.Image}`}
                      />
                    ))}
                </AvatarGroup>
              ) : null}
              {followers.length > 0 && (
                <div
                  onClick={() =>
                    goToFollowPage({ keyword: FOLLOW_KEYWORD.FOLLOWERS })
                  }
                  className="more"
                >
                  See All
                </div>
              )}
            </div>
          ) : null}
        </Fragment>
        {username ? null : (
          <Fragment>
            {following ? (
              <div className="content">
                <h4>
                  {followingTitle} {`(${following.length})`}
                </h4>
                {following.length > 0 ? (
                  <AvatarGroup className="AvatarGroup" max={3}>
                    {following.length > 0 &&
                      following.map((item, index) => (
                        <Avatar
                          key={index}
                          alt="user image"
                          src={`${DOMAIN}profile/${item.Image}`}
                        />
                      ))}
                  </AvatarGroup>
                ) : null}
                {following.length > 0 && (
                  <div
                    onClick={() =>
                      goToFollowPage({ keyword: FOLLOW_KEYWORD.FOLLOWING })
                    }
                    className="more"
                  >
                    See All
                  </div>
                )}
              </div>
            ) : null}
          </Fragment>
        )}
      </div>
    </div>
  );
}
