import moment from "moment";
import { getLocaleDateString } from "../ultils/ultils";
export const DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_SERVER
    : process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_ROOT_URL_DEV
    : process.env.REACT_APP_ROOT_URL_PRO;

export const CLIENT_DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_CLIENT
    : process.env.REACT_APP_NODE_ENV === "development"
    ? process.env.REACT_APP_ROOT_URL_DEV
    : process.env.REACT_APP_ROOT_URL_PRO;
export const GIGSOMETHING_SG_HOME_PAGE =
  "https://gigsomething.sg/home/#download";
export const GIGSOMETHING_SG = "https://gigsomething.sg/home";
export const ROUTES = {
  GET_ALL_JOB_CATEGORIES: "api/jobcategory/getAllJobCategories",
  LOGIN: "api/giger/login",
  REGISTER: "api/giger/register",
  UPDATE_PROFILE: "api/giger/updateProfile",
  FOLLOW_GIG: "api/giger/followGig",
  UN_FOLLOW_GIG: "api/giger/unFollowGig",
  POST_JOB: "api/job/insertJob",
  GET_ALL_SKILLS: "api/skill/getAllSkills",
  GET_ALL_AREAS: "api/area/getAllAreas",
  GET_ALL_JOBS: "api/job/getAllJobs",
  GET_JOB_BY_ID: "api/job/getJobById",
  GET_ALL_AREAS: "api/area/getAllAreas",
  GET_JOB_CATEGORY_BY_ID: "api/jobcategory/getJobCategoryById",
  GET_AREA_BY_ID: "api/area/getAreaById",
  INSERT_GIGGER_JOB: "api/giggerJob/insertGiggerJob",
  GET_GIGGER_JOB_BY_JOB_ID: "api/giggerJob/getGiggerJobByJobId",
  GET_GIGGERS_BY_ID: "api/giger/findGiggersById",
  GET_ALL_GIGGER_JOBS: "api/giggerJob/getAllGiggerJobs",
  GET_GIGGER_JOB_BY_JOB_ID_AND_GIGGER_ID:
    "api/giggerJob/getGiggerJobByJobIdAndGiggerId",
  UPDATE_GIGGER_JOB: "api/giggerJob/updateGiggerJob",
  GET_GIGER_INFO: "api/giger/gigerInfo",
  INSERT_GIGGER_FILTER: "api/giggerFilter/insertGiggerFilter",
  GET_GIGGER_FILTER_BY_GIGGER_ID: "api/giggerFilter/getGiggerFilterByGiggerId",
  UPDATE_GIGGER_FILTER: "api/giggerFilter/updateGiggerFilter",
  GET_ALL_JOBS_BY_GIGGER_ID: "api/giggerJob/getAllJobsByGiggerId",
  UPDATE_GIGGER_BY_TOKEN: "api/giger/updateGiggerByToken",
  GET_FOLLOW_BY_FOLLOWER_ID: "api/follow/getFollowByFollowerId",
  GET_JOBS_BY_GIG_BOSS_ID: "api/job/getJobsByGigBossId",
  GET_GIGGER_BY_TOKEN: "api/giger/getGiggerByToken",
  GET_SORTED_JOBS_BY_GIGGER_ID: "api/job/getSortedJobsByGiggerId",
  INSERT_GIGGER_REVIEW_RATING:
    "api/giggerReviewRating/insertGiggerReviewRating",
  UPDATE_GIGGER_REVIEW_RATING:
    "api/giggerReviewRating/updateGiggerReviewRating",
  GET_ALL_GIGGER_REVIEW_RATINGS_BY_JOB_ID:
    "api/giggerReviewRating/getAllGiggerReviewRatingsByJobId",
  GET_GIGGER_REVIEW_RATING_BY_ID:
    "api/giggerReviewRating/getGiggerReviewRatingById",
  GET_ALL_GIGGER_REVIEW_RATINGS_NO_LIMITATION_BY_JOB_ID:
    "api/giggerReviewRating/getAllGiggerReviewRatingsNoLimitationByJobId",
  GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID:
    "api/giggerReviewRating/getAllGiggerReviewRatingsByRevieweeId",
  GET_REVIEWS_AVERAGE_STARS: "api/giggerReviewRating/getReviewsAverageStars",
  GET_ALL_JOBS_BY_JOB_CATEGORY: "api/giggerJob/getAllJobsByJobCategory",
  IS_REVIEW_EXISTING_BY_JOB_ID_AND_REVIEWER_ID:
    "api/giggerReviewRating/isReviewExistingByJobIdAndReviewerId",
  GET_GIGGER_BY_ID: "api/giger/getGiggerById",
  GET_JOB_ID_BY_JOB_CODE: "api/job/getJobIdByJobCode",
  UPDATE_JOB: "api/job/updateJob",
  UPDATE_GIGGER_JOB_BY_CONDITIONS: "api/giggerJob/updateGiggerJobByConditions",
  DELETE_GIGGER_JOB_BY_ID: "api/giggerJob/deleteGiggerJobById",
  INSERT_REPORT_JOB: "api/reportJob/insertReport",
  INSERT_REPORT_GIGGER: "api/reportJob//insertGiggerReport",
  IS_REVIEWS_FOR_ALL_GIGGER_JOBS_EXISTING_BY_JOB_ID_AND_REVIEWER_ID:
    "api/giggerReviewRating/isReviewsForAllGiggerJobsExistingByJobIdAndReviewerId",
  GET_WELCOME_STATUS: "api/giger/getWelcomeStatus",
  UPDATE_WELCOME_STATUS: "api/giger/updateWelcomeStatus",
  UPDATE_DISMISS_STATUS: "api/giger/updateDismissStatus",
  GET_ALL_BAD_WORDS: "api/badWord/getAllBadWords",
  GET_ALL_SLIDERS: "api/slider/getAllSliders",
  GET_FOLLOW_DATA: "api/giger/getFollowData",
  CHECK_EMAIL_EXISTS: "api/giger/checkEmailExists",
  CLEAR_NOTIFICATION_BADGE: "api/notification/clearbadge",
  GET_ALL_NOTIFICATION_ALERT: "api/notification/getAllAlert",
  REMOVE_ALL_NOTIFICATION_ALERT: "api/notification/removeAllAlert",
  UPDATE_CHECK_ALERT: "api/notification/updateCheckAlert",
  UPDATE_LIKED_GIG: "api/notification/updateLikedGig",
  GET_LIKED_STATUS: "api/notification/getLikedStatus",
  GET_ALL_LIKED_GIG: "api/notification/getAllLikedGig",
  GET_ALL_UNRATED_GIGS: "api/giggerReviewRating/getAllUnratedGigs",
  UPDATE_RATED_GIG: "api/giggerReviewRating/updateRatedGig",
  CREATE_CLICK_LOG: "api/logs/createClicked",
};

export const compressedFile = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1024, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight
};
export const FORMAT = {
  DATE_TIME_SERVER: "YYYY-MM-DD",
  DATE_TIME_CLIENT: getLocaleDateString(),
  DOB_DATE: "MMM-YYYY",
};

export const REGEX = {
  URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
};
export const PATTERN = {
  HTTP: "http://",
  HTTPS: "https://",
};
export const API = {
  GET_ALL_JOB_CATEGORIES: DOMAIN + ROUTES.GET_ALL_JOB_CATEGORIES,
  LOGIN: DOMAIN + ROUTES.LOGIN,
  REGISTER: DOMAIN + ROUTES.REGISTER,
  UPDATE_PROFILE: DOMAIN + ROUTES.UPDATE_PROFILE,
  FOLLOW_GIG: DOMAIN + ROUTES.FOLLOW_GIG,
  UN_FOLLOW_GIG: DOMAIN + ROUTES.UN_FOLLOW_GIG,
  POST_JOB: DOMAIN + ROUTES.POST_JOB,
  GET_ALL_SKILLS: DOMAIN + ROUTES.GET_ALL_SKILLS,
  GET_ALL_JOBS: DOMAIN + ROUTES.GET_ALL_JOBS,
  GET_JOB_BY_ID: DOMAIN + ROUTES.GET_JOB_BY_ID,
  GET_ALL_AREAS: DOMAIN + ROUTES.GET_ALL_AREAS,
  GET_JOB_CATEGORY_BY_ID: DOMAIN + ROUTES.GET_JOB_CATEGORY_BY_ID,
  GET_AREA_BY_ID: DOMAIN + ROUTES.GET_AREA_BY_ID,
  INSERT_GIGGER_JOB: DOMAIN + ROUTES.INSERT_GIGGER_JOB,
  GET_GIGGER_JOB_BY_JOB_ID: DOMAIN + ROUTES.GET_GIGGER_JOB_BY_JOB_ID,
  GET_GIGGERS_BY_ID: DOMAIN + ROUTES.GET_GIGGERS_BY_ID,
  GET_ALL_GIGGER_JOBS: DOMAIN + ROUTES.GET_ALL_GIGGER_JOBS,
  GET_GIGGER_JOB_BY_JOB_ID_AND_GIGGER_ID:
    DOMAIN + ROUTES.GET_GIGGER_JOB_BY_JOB_ID_AND_GIGGER_ID,
  UPDATE_GIGGER_JOB: DOMAIN + ROUTES.UPDATE_GIGGER_JOB,
  GET_GIGER_INFO: DOMAIN + ROUTES.GET_GIGER_INFO,
  INSERT_GIGGER_FILTER: DOMAIN + ROUTES.INSERT_GIGGER_FILTER,
  GET_GIGGER_FILTER_BY_GIGGER_ID:
    DOMAIN + ROUTES.GET_GIGGER_FILTER_BY_GIGGER_ID,
  UPDATE_GIGGER_FILTER: DOMAIN + ROUTES.UPDATE_GIGGER_FILTER,
  GET_ALL_JOBS_BY_GIGGER_ID: DOMAIN + ROUTES.GET_ALL_JOBS_BY_GIGGER_ID,
  UPDATE_GIGGER_BY_TOKEN: DOMAIN + ROUTES.UPDATE_GIGGER_BY_TOKEN,
  GET_FOLLOW_BY_FOLLOWER_ID: DOMAIN + ROUTES.GET_FOLLOW_BY_FOLLOWER_ID,
  GET_JOBS_BY_GIG_BOSS_ID: DOMAIN + ROUTES.GET_JOBS_BY_GIG_BOSS_ID,
  GET_GIGGER_BY_TOKEN: DOMAIN + ROUTES.GET_GIGGER_BY_TOKEN,
  GET_SORTED_JOBS_BY_GIGGER_ID: DOMAIN + ROUTES.GET_SORTED_JOBS_BY_GIGGER_ID,
  INSERT_GIGGER_REVIEW_RATING: DOMAIN + ROUTES.INSERT_GIGGER_REVIEW_RATING,
  UPDATE_GIGGER_REVIEW_RATING: DOMAIN + ROUTES.UPDATE_GIGGER_REVIEW_RATING,
  GET_ALL_GIGGER_REVIEW_RATINGS_BY_JOB_ID:
    DOMAIN + ROUTES.GET_ALL_GIGGER_REVIEW_RATINGS_BY_JOB_ID,
  GET_GIGGER_REVIEW_RATING_BY_ID:
    DOMAIN + ROUTES.GET_GIGGER_REVIEW_RATING_BY_ID,
  GET_ALL_GIGGER_REVIEW_RATINGS_NO_LIMITATION_BY_JOB_ID:
    DOMAIN + ROUTES.GET_ALL_GIGGER_REVIEW_RATINGS_NO_LIMITATION_BY_JOB_ID,
  GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID:
    DOMAIN + ROUTES.GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID,
  GET_ALL_JOBS_BY_JOB_CATEGORY: DOMAIN + ROUTES.GET_ALL_JOBS_BY_JOB_CATEGORY,
  GET_REVIEWS_AVERAGE_STARS: DOMAIN + ROUTES.GET_REVIEWS_AVERAGE_STARS,
  IS_REVIEW_EXISTING_BY_JOB_ID_AND_REVIEWER_ID:
    DOMAIN + ROUTES.IS_REVIEW_EXISTING_BY_JOB_ID_AND_REVIEWER_ID,
  GET_GIGGER_BY_ID: DOMAIN + ROUTES.GET_GIGGER_BY_ID,
  GET_JOB_ID_BY_JOB_CODE: DOMAIN + ROUTES.GET_JOB_ID_BY_JOB_CODE,
  UPDATE_JOB: DOMAIN + ROUTES.UPDATE_JOB,
  UPDATE_GIGGER_JOB_BY_CONDITIONS:
    DOMAIN + ROUTES.UPDATE_GIGGER_JOB_BY_CONDITIONS,
  DELETE_GIGGER_JOB_BY_ID: DOMAIN + ROUTES.DELETE_GIGGER_JOB_BY_ID,
  INSERT_REPORT_JOB: DOMAIN + ROUTES.INSERT_REPORT_JOB,
  INSERT_REPORT_GIGGER: DOMAIN + ROUTES.INSERT_REPORT_GIGGER,
  IS_REVIEWS_FOR_ALL_GIGGER_JOBS_EXISTING_BY_JOB_ID_AND_REVIEWER_ID:
    DOMAIN +
    ROUTES.IS_REVIEWS_FOR_ALL_GIGGER_JOBS_EXISTING_BY_JOB_ID_AND_REVIEWER_ID,
  GET_WELCOME_STATUS: DOMAIN + ROUTES.GET_WELCOME_STATUS,
  UPDATE_WELCOME_STATUS: DOMAIN + ROUTES.UPDATE_WELCOME_STATUS,
  UPDATE_DISMISS_STATUS: DOMAIN + ROUTES.UPDATE_DISMISS_STATUS,
  GET_ALL_BAD_WORDS: DOMAIN + ROUTES.GET_ALL_BAD_WORDS,
  GET_ALL_SLIDERS: DOMAIN + ROUTES.GET_ALL_SLIDERS,
  GET_FOLLOW_DATA: DOMAIN + ROUTES.GET_FOLLOW_DATA,
  CHECK_EMAIL_EXISTS: DOMAIN + ROUTES.CHECK_EMAIL_EXISTS,
  CLEAR_NOTIFICATION_BADGE: DOMAIN + ROUTES.CLEAR_NOTIFICATION_BADGE,
  GET_ALL_NOTIFICATION_ALERT: DOMAIN + ROUTES.GET_ALL_NOTIFICATION_ALERT,
  REMOVE_ALL_NOTIFICATION_ALERT: DOMAIN + ROUTES.REMOVE_ALL_NOTIFICATION_ALERT,
  UPDATE_CHECK_ALERT: DOMAIN + ROUTES.UPDATE_CHECK_ALERT,
  UPDATE_LIKED_GIG: DOMAIN + ROUTES.UPDATE_LIKED_GIG,
  GET_LIKED_STATUS: DOMAIN + ROUTES.GET_LIKED_STATUS,
  GET_ALL_LIKED_GIG: DOMAIN + ROUTES.GET_ALL_LIKED_GIG,
  GET_ALL_UNRATED_GIGS: DOMAIN + ROUTES.GET_ALL_UNRATED_GIGS,
  UPDATE_RATED_GIG: DOMAIN + ROUTES.UPDATE_RATED_GIG,
  CREATE_CLICK_LOG: DOMAIN + ROUTES.CREATE_CLICK_LOG,
};

export const AMOUNTOFPAY = {
  HOURLY: "Hourly",
  //HOURLY_UP_TO: "Hourly up to",
  FLAT_FEE: "Flat fee",
  //FLAT_FEE_UP_TO: "Flat fee up to",
  DAILY: "Daily",
  //DAILY_UP_TO: "Daily up to",
  NOT_STATED: "Not stated",
  MONTHLY: "Monthly",
};

// export const USER_PROFILE_FORM = {
//   FirstName: "",
//   LastName: "",
//   DOB: `${moment().format("YYYY-MM-DD")}`,
//   Address: "",
//   PhoneNumber: "",
//   Country: "",
//   MartialStatus: "",
//   Company: "",
//   Gender: "",
//   Type: "",
//   Skill: [],
// };
export const PROFILE_KEYS = {
  FIRST_NAME: "FirstName",
  LAST_NAME: "LastName",
  DOB: "DOB",
  ADDRESS: "Address",
  PHONE_NUMBER: "PhoneNumber",
  COUNTRY: "Country",
  MARTIAL_STATUS: "MaritalStatus",
  COMPANY: "Company",
  GENDER: "Gender",
  TYPE: "Type",
  SKILLS: "SkillIds",
};
export const PROFILE = {
  MARTIAL_STATUS: [
    { value: "1", label: "Single" },
    { value: "2", label: "Married" },
  ],
  GIGER_TYPE: [
    { value: "1", label: "Gigger" },
    { value: "2", label: "Boss" },
    { value: "3", label: "Both" },
  ],
  GENDER: [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ],

  GENDER_VALUES: {
    1: "Male",
    2: "Female",
  },
  MARITAL_VALUES: {
    1: "Single",
    2: "Married",
  },
  COUNTRY: [
    { value: "SINGAPORE", label: "Singapore" },
    { value: "VIETNAM", label: "Viet Nam" },
    { value: "BRUNEI", label: "Brunei" },
    { value: "INDONESIA", label: "Indonesia" },
    { value: "MYANMAR", label: "Myanmar" },
    { value: "TIMOR-LESTE", label: "Timor-Leste" },
    { value: "MALAYSIA", label: "Malaysia" },
    { value: "THAILAND", label: "Thailand" },
    { value: "PHILIPINES", label: "Philipines" },
  ],
  TYPES: {},
};
export const LOCAL_STORAGE_KEY = {
  TOKEN: "token",
  CURRENT_ID: "currentId",
  DARK_MODE: "darkMode",
  DEVICE_OS: "deviceOS",
};
export const PLATFORM = {
  ANDROID: "android",
  IOS: "ios",
};
export const FORM_TYPES = {
  TEXT_FIELD: "TEXT_FIELD",
  AUTO_COMPLETE: "AUTO_COMPLETE",
  AUTO_COMPLETE_CHECK_BOX: "AUTO_COMPLETE_CHECK_BOX",
  DATE_PICKER: "DATE_PICKER",
  FILE: "FILE",
  PHOTOS: "Photos",
  PDF: "Pdf",
  PDF_EXT: ".pdf",
  PASSWORD: "PASSWORD",
  SOCIAL: "SOCIAL",
  DOB: "DOB",
  WHITE_SPACE: " ",
  MAX_PHOTOS_UPLOAD: 10,
  MAX_PDF_UPLOAD: 1,
  MAX_LINKS: 10,
  MAX_PDF_SIZE: 5000 * 1000,
  PORTFOLIO: "portfolio",
  YOUTUBE_LINK: "youtube.com",
  YOUTUBE_BE: "youtu.be",
  YOUTUBE: "Youtube",
};
export const PORTFOLIO_MESSAGE = {
  ERROR: {
    EXCEED_YOUTUBE_LINK: "Please add up to 10 YouTube links only.",
    EXCEED_PHOTOS: "Please add up to 10 photos only.",
    EXCEED_PDF: "The file size exceeds the allowable limit of 5MB",
    INVALID_YOUTUBE_LINK: "Invalid YouTube link.",
    TITLE_IS_REQUIRED: "Title is required",
  },
  DIALOG: {
    DELETE_ITEM: "Are you sure you want to delete this item?",
  },
};
export const APP_VERSION = "1.0";
// export const COUNTRY = [
//   { value: "Singapore", label: "Singapore" },
//   { value: "VietNam", label: "VietNam" },
//   { value: "Brunei", label: "Brunei" },
//   { value: "Indonesia", label: "Indonesia" },
//   { value: "Myanmar", label: "Myanmar" },
//   { value: "Timor-Leste", label: "Timor-Leste" },
//   { value: "Malaysia", label: "Malaysia" },
//   { value: "Thailand", label: "Thailand" },
//   { value: "Philipines", label: "Philipines" },
// ];

export const GA_PARAMS = {
  POST_GIG: "/postgig",
};

export const JOB_STATUS = {
  GIG_APPLIED: "Gig Applied",
  GIG_CONFIRMED: "Gig Confirmed",
  GIG_COMPLETED: "Gig Completed",
  GIG_REJECTED: "Gig Rejected",
  // GIG_APPLIED: "Gig Applied",
  // GIG_CONFIRMED: "Gig Confirmed",
  // GIG_COMPLETED: "Gig Completed",
  // GIG_REJECTED: "Gig Rejected",
};

export const MESSAGE = {
  SUCCESSFULLY_SENT: "successfully sent",
  INVALID_URL: "Website is invalid",
  CANCEL_ALERT: "Do you want to cancel your application to",
  PASSWORD_DO_NOT_MATCH: "Passwords do not match",
  STOP_ACCEPTING_APPLICATIONS: "Do you want to stop accepting applications?",
  REPORT_GIG_CONFIRM_MESSAGE: "Do you want to report this gig for review?",
  REPORT_USER_CONFIRM_MESSAGE: "Do you want to report this user for review?",
  REPORT_GIG_NOTIFICATION_MESSAGE:
    "Thank you for reporting the gig. We will review within 24 hours and take appropriate action.",
  REPORT_USER_NOTIFICATION_MESSAGE:
    "Thank you for reporting the user. We will review within 24 hours and take appropriate action.",
  REPORT_GIG_NOTIFICATION_ERROR_MESSAGE:
    "There was an error reporting your gig. Please try again.",
  REPORT_USER_NOTIFICATION_ERROR_MESSAGE:
    "There was an error reporting the user. Please try again.",
  COMPLETE_GIG_CONFIRM_MESSAGE:
    "Are you sure you want to mark this gig as completed? You will be able to exchange reviews after gig completion",
  APPLY_NOTIFICATION:
    "You have applied to this gig. You will be notified when the Gig Boss Approves or Rejects your application",
  WELCOME_MESSAGE: {
    TITLE:
      "Welcome to gigsomething, a free-to-use community platform that connects local freelancers to Gig opportunities islandwide.",
    BODY:
      "Disclaimer: While we do our best to moderate all gig listings, we rely heavily on user reviews & feedback to create a safe & inclusive community for all giggers/gigbosses. Gigsomething serves only as a community platform and is not affliated to any gigbosses/companies that have posted listings on our app and thus cannot be held responsible for non-payments or scams. Where in doubt, please contact our customer service team at",
    MAILTO_LINK: "mailto:hello@gigsomething.sg",
    EMAIL_TEXT: "hello@gigsomething.sg",
  },
  GIG_BOSS_MISSING_INFO_NOTIFICATION:
    "Please enter your company name and contact name in your Gigboss profile before posting a gig.",
  GIGGER_MISSING_INFO_NOTIFICATION:
    "Please enter your name and number in your profile before applying.",
};

export const BUTTON_TEXT = {
  CONTACT_VIA_WHATSAPP: "Contact via WhatsApp",
  APPLY_VIA_WHATSAPP: "APPLY VIA WHATSAPP",
  DOWNLOAD_AND_APPLY: "DOWNLOAD AND APPLY",
  NO_LONGER_ACCEPTING_APPLICATIONS: "NO LONGER ACCEPTING APPLICATIONS",
  CANCEL: "CANCEL",
  CHAT: "CHAT",
  REJECT: "REJECT",
  CONFIRM: "CONFIRM",
  STOP_ACCEPTING_APPLICATIONS: "STOP ACCEPTING APPLICATIONS",
  SAVE: "SAVE",
  APPLY_VIA_FORM: "APPLY VIA FORM",
  OK: "OK",
  VIEW_ALL: "View All",
  YES: "Yes",
  NO: "No",
  LOG_OUT: "Log Out",
  FOLLOW: "Follow",
  REPORT: "Report User",
  UNFOLLOW: "Unfollow",
  YES_COMPLETED: "Yes, completed",
  NO_GO_BACK: "No, go back",
  COMPLETE_GIG: "COMPLETE GIG",
  APPLY: "APPLY",
  SUBMIT: "SUBMIT",
  BACK: "BACK",
  PREVIEW: "PREVIEW",
  EDIT_REPOST: "EDIT & REPOST",
};
export const LABELS = {
  DEFAULT: "Your Rating:",
  TERRIBLE: "Terrible",
  POOR: "Poor",
  FAIR: "Fair",
  GOOD: "Good",
  EXCELLENT: "Excellent",
  VALUES: {
    ONE_STAR: "1",
    TWO_STARS: "2",
    THREE_STARS: "3",
    FOUR_STARS: "4",
    FIVE_STARS: "5",
  },
  LEAVE_A_REVIEW: "Leave a review",
};
export const WEBVIEW_ACTION = {
  APPLY_JOB: "APPLY_JOB",
  JOB_DETAIL: "JOB_DETAIL",
  GO_AUTH: "GO_AUTH",
  LOG_OUT: "LOG_OUT",
  GO_TO_GIG_PROFILE: "GO_TO_GIG_PROFILE",
  POST_JOB: "POST_JOB",
  RELOAD_PAGE: "RELOAD_PAGE",
  UPDATE_TITLE: "UPDATE_TITLE",
  TOGGLE_DARK_MODE: "TOGGLE_DARK_MODE",
  ON_SHARE: "ON_SHARE",
  GO_TO_MY_GIGS: "GO_TO_MY_GIGS",
  GO_TO_GIGS: "GO_TO_GIGS",
  GO_TO_GIGS_CATEGORY: "GO_TO_GIGS_CATEGORY",
  GO_TO_RATING_PAGE: "GO_TO_RATING_PAGE",
  GO_TO_WEBSITE: "GO_TO_WEBSITE",
  ON_UPDATE_PROFILE: "ON_UPDATE_PROFILE",
  RELOAD_MY_GIGS: "RELOAD_MY_GIGS",
  CONTACT_VIA_WHATSAPP: "CONTACT_VIA_WHATSAPP",
  GIG_BOSS_ACTION: "GIG_BOSS_ACTION",
  ON_FOREGROUND_FOLLOW: "ON_FOREGROUND_FOLLOW",
  OPEN_NEW_PAGE: "OPEN_NEW_PAGE",
  SET_ENABLE_REFRESH: "SET_ENABLE_REFRESH",
  OPEN_SLIDER_URL: "OPEN_SLIDER_URL",
  REQUIRE_USER_UPDATE_PROFILE: "REQUIRE_USER_UPDATE_PROFILE",
  GO_TO_FOLLOW_PAGE: "GO_TO_FOLLOW_PAGE",
  SET_UP_GIG_ALERT: "SET_UP_GIG_ALERT",
  DISABLED_USER: "DISABLED_USER",
  CHECK_CONNECTION: "CHECK_CONNECTION",
  DISABLE_REFRESH: "DISABLE_REFRESH",
  ON_UPDATE_FILTER: "ON_UPDATE_FILTER",
  ON_PREVIEW: "ON_PREVIEW",
  ON_REVIEW_GIG: "ON_REVIEW_GIG",
  ON_OPEN_MODAL_GIG: "ON_MODAL_GIG",
  ON_OPEN_MODAL_PROFILE: "ON_MODAL_PROFILE",
  ON_OPEN_MODAL_CATEGORY: "ON_MODAL_DETAIL",
  OPEN_PDF: "OPEN_PDF",
  ON_DUPLICATE_GIG: "ON_DUPLICATE_GIG",
};
export const RELOAD_PAGE_TYPE = {
  USER_PROFILE: "USER_PROFILE",
  SAVE_GIG: "SAVE_GIG",
};

export const SORT_JOB_BROWSING = {
  DEFAULT_VALUE: "Sort by",
  JOB_TYPE: "Gig Type",
  PAYOUT: "Pay",
  LOCATION: "Area",
  POSTED_DATE: "Posted Date",
  START_DATE: "Start Date",
  ODER_BY: {
    DESC: "DESC",
    ASC: "ASC",
  },
};

export const GIGGER_TYPE_CONST = {
  GIGGER: "Gigger",
  GIGBOSS: "Boss",
  BOTH: "Both",
};

export const MY_GIGS_VALUE = {
  APPLIED: 0,
  CONFIRMED: 1,
  COMPLETED: 2,
  REJECTED: 3,
};
export const JOB_FILTER_CONDITIONS = {
  GIG_BOSS_ACTIVE_JOBS: "GIG_BOSS_ACTIVE_JOBS",
  GIG_BOSS_EXPIRED_JOBS: "GIG_BOSS_EXPIRED_JOBS",
  GIGGER_APPLIED_JOBS: "GIGGER_APPLIED_JOBS",
};

const getExpiryDate = () => {
  var today = new Date();
  return today.setDate(today.getDate() + 30);
};
export const POST_GIG_FORM = {
  INITIAL_FORM: {
    gigBossId: 0,
    title: "",
    jobCategoryId: "",
    description: "",
    dateFrom: null,
    dateTo: null,
    hourFrom: "",
    hourTo: "",
    salary: "",
    amountOfPay: "Hourly",
    requirement: "",
    paymentTerm: "",
    noPerson: 1,
    skills: "",
    //jobScope: "",
    attire: "",
    contactNumber: "",
    contactEmail: "",
    areas: [],
    location: "",
    applyVia: "WhatsApp",
    applyFormUrl: "",
    deadline: moment(getExpiryDate()).format("YYYY-MM-DD"),
    jobCategories: [],
    paymentTermObj: null,
  },
  ERROR_MESSAGE: {
    TITLE: "Please enter Title",
    DESCRIPTION: "Please enter Description",
    GIG_TYPE: "Please select Gig Type",
    SALARY: "Please enter Salary",
    START_DATE: "Please enter Start Date",
    START_DATE_CURRENT_DATE:
      "Start Date should be same or later than current date",
    END_DATE: "End Date should be same or later than Start Date",
    INVALID_VALUE: "Please enter a valid value",
    AMOUNT_OF_PAY: "Please select Pay By",
    PAYMENT_TERM: "Please select Payment Term",
    NO_PERSON: "Please enter Pax",
    SKILLS: "Please enter Skill(s)",
    CONTACT_NUMBER: "Please enter Contact Number",
    EMAIL: "Please enter a valid Email",
    PHONE_NUMBER_MAX_LENGTH: "Phone number should be 8 digits",
    APPLY_VIA_FORM_URL: "Please enter Form URL",
    INVALID_FORM_URL: "Please enter valid Form URL",
    DATEFROM_DEADLINE: "Application Deadline should be later than today",
    DATETO_DEADLINE:
      "Application Deadline should be same or less than End Date",
    DEADLINE: "Please enter Application Deadline",
    DEADLINE_CURRENTDATE: "Application Deadline should be later than today",
    PASSWORD_DO_NOT_MATCH: "Passwords do not match",
    PASSWORD_IS_INVALID: "Passwords must have at least 6 characters",
  },
  DIALOG_MESSAGE: {
    SUCCESS: "Your gig has been posted!",
    ERROR: "There was an error posting your gig. Please try again.",
    SUCCESS_TITLE: "Successfully posted",
    ERROR_TITLE: "Post gig failed",
    PASSWORD_CHANGED: "Your Password has been changed",
    ACCOUNT_HAS_BEEN_DISABLED: "Your account is not valid.",
    POST_JOB_CONFIRM: "Are you sure to post gig?",
  },
  APPLY_VIA: {
    FORM: "Your own form/portal",
    WHATSAPP: "WhatsApp",
  },
};
export const SALARY_KEYS = {
  HOURLY: "hourlySalary",
  //HOURLY_UP_TO: "hourlyUpToSalary",
  FLAT_FREE: "flatFeeSalary",
  //FLAT_FREE_UP_TO: "flatFeeUpToSalary",
  DAILY: "dailySalary",
  //DAILY_UP_TO: "dailyUpToSalary",
  MONTHLY: "monthlySalary",
};
export const RATING_REVIEW_FORM = {
  SUCCESS_MESSAGE: {
    POSTED: "Thank you for the review!",
  },
  ERROR_MESSAGE: {
    ERROR: "There was an error posting your review. Please try again.",
  },
};
export const REVIEW_TYPE = {
  GIGER: "GIGER",
  GIGBOSS: "GIGBOSS",
};
export const GIG_ERROR_MESSAGE = {
  GIG_DOES_NOT_EXIST: "Gig does not exist",
};
export const APPLY_GIG = {
  DIALOG_MESSAGE: {
    SUCCESS: "Your application has been submitted 🤞",
    ERROR: "There was an error applying your gig. Please try again.",
    SUCCESS_TITLE: "Successfully submitted",
    ERROR_TITLE: "Apply gig failed",
  },
};
export const SAVE_GIG = {
  DIALOG_MESSAGE: {
    SUCCESS: "Your gig has been saved!",
    ERROR: "There was an error saving your gig. Please try again.",
    SUCCESS_TITLE: "Successfully saved",
    ERROR_TITLE: "Save gig failed",
  },
};

export const GIG_LINKS = {
  GIG_DETAILS: "Gigs/Details",
  USER_PROFILE: "",
};
export const PAYMENT_TERM_VALUES = [
  {
    value: "1 day",
    label: "1 day",
  },
  {
    value: "7 days",
    label: "7 days",
  },
  {
    value: "14 days",
    label: "14 days",
  },
  {
    value: "21 days",
    label: "21 days",
  },
  {
    value: "30 days",
    label: "30 days",
  },
];

export const JOB_LIST_PAGE_SIZE = 10;
export const REVIEW_RATING_PAGE_SIZE = 10;
export const GIG_FOR_YOU_PAGE_SIZE = 5;

export const DELETE_COMMENT_DIALOG_MESSAGE = {
  SUCCESSFULLY_DELETED: "Your review has been successfully deleted.",
  FAILED_DELETED: "There was an error deleting your review. Please try again.",
};
export const FILTER_MESSAGE = {
  MESSAGE: "Would you like to save this as your gig alert?",
};

export const COMMENT_FORM = {
  ERROR_MESSAGE: {
    RATING: "Please enter Rating",
    ERROR_EDIT: "There was an error editing your review. Please try again.",
    ERROR_POST: "There was an error posting your review. Please try again.",
    ERROR_INPUT_URL: "Invalid Review - No links allowed",
    ERROR_INPUT_BAD_WORDS: "Invalid review - No profanity allowed",
  },
  MESSAGE: {
    SUCCESSFULLY_EDITED: "Your review has been successfully edited",
    SUCCESSFULLY_POSTED: "Your review has been successfully posted",
  },
};
export const PROFILE_FORM = {
  ERROR_MESSAGE: {
    EMAIL_IS_ALREADY_EXIST: "Email already exists",
    EMAIL_IS_INVALID: "Email is invalid",
  },
};
export const LOGIN = {
  ERROR_MESSAGE: {
    INCORRECT_EMAIL_OR_PASSWORD: "Email or password is incorrect",
  },
};

export const REVIEW_MAX_LENGTH = 1000;
