import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";

export default function ViewComponent({
  label,
  value,
  lastComponent,
  onClick,
  subLabel,
}) {
  return (
    <Fragment>
      <div
        className="form-group"
        style={{ borderBottom: `${lastComponent && "none"}` }}
        onClick={onClick}
      >
        <label>
          {label}{" "}
          {subLabel && <span style={{ display: "block" }}>{subLabel}</span>}
        </label>
        <p>{value}</p>
      </div>
    </Fragment>
  );
}
