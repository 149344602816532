import * as actionTypes from "../actions/actionTypes";
import { LOCAL_STORAGE_KEY } from "../../config/config";

const initialState = {
  reduxStates: [{ id: 1, name: "Redux" }],
  checkRedux: false,
  token: null,
  darkMode: localStorage[LOCAL_STORAGE_KEY.DARK_MODE]
    ? localStorage[LOCAL_STORAGE_KEY.DARK_MODE]
    : false,
  areas: null,
  onSendMessage: false,
  followers: [],
  followings: [],
  onForeGroundStatus: null,
  arrowUp: null,
  reviewId: null,
};

const reduxReducer = (
  state = initialState,
  { type, payload, filter, loadNew }
) => {
  switch (type) {
    case actionTypes.CHECK_REDUX:
      return {
        ...state,
        checkRedux: payload,
      };
    case actionTypes.SAVE_TOKEN:
      console.log({ type, payload });
      return {
        ...state,
        token: payload,
      };
    case actionTypes.TOGGLE_DARKMODE:
      // localStorage.setItem(
      //   localStorage[LOCAL_STORAGE_KEY.DARK_MODE],
      //   !state.darkMode
      // );
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case actionTypes.FETCH_MY_GIGS:
      console.log({ newData: payload, filter: filter });
      if (loadNew) {
        return {
          ...state,
          [filter]: [...payload],
        };
      } else {
        return {
          ...state,
          [filter]:
            state[filter] && state[filter].length > 0
              ? [...state[filter], ...payload]
              : [...payload],
        };
      }

    case actionTypes.GET_AREAS:
      return {
        ...state,
        areas: payload,
      };
    case actionTypes.ON_SEND_MESSAGE:
      return {
        ...state,
        onSendMessage: !state.onSendMessage,
      };
    case actionTypes.ON_FOREGROUND_STATUS:
      return {
        ...state,
        onForeGroundStatus:
          state.onForeGroundStatus === null ? true : !state.onForeGroundStatus,
      };
    case actionTypes.CHANGE_ARROW_STATUS:
      return {
        ...state,
        arrowUp: state.arrowUp === null ? true : !state.arrowUp,
      };
    case actionTypes.ON_DELETE_REVIEW:
      return {
        ...state,
        reviewId: payload,
      };
    default:
      return state;
  }
};

export default reduxReducer;
