import axios from "axios";

export const postAxios = async (requestAPI, requestData) => {
  try {
    const result = await axios.post(requestAPI, { requestData });
  } catch (error) {
    console.log({ error });
  }
};

export const getAxios = async (requestAPI) => {
  try {
    const result = await axios.get(requestAPI, {});
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log({ error });
  }
};

