import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import { SORT_JOB_BROWSING } from "../../config/config";
import ArrowIcon from "./ArrowIcon";
import { useDispatch } from "react-redux";
import { CHANGE_ARROW_STATUS } from "../../store/actions/actionTypes";
function DropdownItem({
  index,
  handleMenuItemClick,
  option,
  selectedIndex,
  isUpward,
}) {
  //const [isUpward, setIsUpward] = useState(null);
  const dispatch = useDispatch();
  const [eventObj, setEventObj] = useState({});
  const [isClicked, setIsClicked] = useState();
  const handleClick = (event, index, option) => {
    // if (isUpward === null) {
    //   setIsUpward(true);
    // } else {
    //   setIsUpward(!isUpward);
    // }
    console.log("handleClick");
    setEventObj({ event, index, option, isUpward });
  };
  useEffect(() => {
    if (Object.keys(eventObj).length > 0) {
      const { event, index, option } = eventObj;
      let optionObj = JSON.stringify({
        sortBy: option,
        orderBy: isUpward
          ? SORT_JOB_BROWSING.ODER_BY.DESC
          : SORT_JOB_BROWSING.ODER_BY.ASC,
      });
      console.log({ sortBy: isUpward });
      handleMenuItemClick(event, index, optionObj, isUpward);
    }
  }, [eventObj]);

  return (
    <MenuItem
      key={option}
      //disabled={index === 0}
      selected={index === selectedIndex}
      onClick={(event) => handleClick(event, index, option, isUpward)}
    >
      {option}
      <Fragment>
        {option !== SORT_JOB_BROWSING.DEFAULT_VALUE &&
          index === selectedIndex && <ArrowIcon isUpward={!isUpward} />}
      </Fragment>
    </MenuItem>
  );
}

export default DropdownItem;
