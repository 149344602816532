import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import axios from "axios";
import React, { useState } from "react";
import { API } from "../../config/config";
import ReviewEditNotificationDialog from "./ReviewEditNotificationDialog";
import { useDispatch } from "react-redux";
import { ON_DELETE_REVIEW } from "../../store/actions/actionTypes";

const ReviewEdit = ({
  handleOpenCommentDialog,
  reviewRatingId,
  jobCode,
  props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteReview = () => {
    const deleteReview = async () => {
      try {
        const result = await axios.post(API.UPDATE_GIGGER_REVIEW_RATING, {
          reviewRatingId: reviewRatingId,
          deleteReview: true
        });
        const error = result.data.error;
        dispatch({ type: ON_DELETE_REVIEW, payload: reviewRatingId });
        setOpenDialog(true);
        setHasError(error ? true : false);
        // setOpenDialog(true);
      } catch (error) {}
    };

    deleteReview();
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e) => {
            if (handleOpenCommentDialog) {
              setAnchorEl(null);
              handleOpenCommentDialog(e, reviewRatingId);
            }
          }}
        >
          <EditIcon fontSize="small" />
          <span style={{ marginLeft: 10 }}>Edit</span>
        </MenuItem>
        <MenuItem onClick={handleDeleteReview}>
          <DeleteIcon fontSize="small" />
          <span style={{ marginLeft: 10 }}>Delete</span>
        </MenuItem>
      </Menu>
      {openDialog ? (
        <ReviewEditNotificationDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          props={props}
          hasError={hasError}
          jobCode={jobCode}
          reviewRatingId={reviewRatingId}
        />
      ) : null}
    </div>
  );
};

export default ReviewEdit;
