import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import { FAQ_CONTENTS } from "../../config/faq";
import CollapseItem from "./Collapse/CollapseItem";
const useStyles = makeStyles((theme) => ({}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  
  return (
    <ul className="question">
      {FAQ_CONTENTS.GIGGERS.map((item) => (
        <CollapseItem
          key={item.key}
          title={item.key}
          values={item.values}
          innerHTML={item.innerHTML}
        />
      ))}
    </ul>
  );
}
