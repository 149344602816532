import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import React, { useEffect, useState } from "react";
import { DOMAIN, WEBVIEW_ACTION, LOCAL_STORAGE_KEY } from "../../config/config";
import ReviewEdit from "../Reviews/ReviewEdit";
import { sendMessage } from "../../ultils/ultils";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  }
}));
const ReviewRatingCommentItem = ({
  reviewRating,
  canEdit,
  handleOpenCommentDialog,
  reviewerInformation,
  jobCode,
  props
}) => {
  //#region Initial
  const [isAllow, setIsAllow] = useState(true);
  const classes = useStyles();
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  //#endregion

  //#region useEffect

  useEffect(() => {}, [reviewRating, reviewerInformation]);

  //#endregion
  const viewProfile = () => {
    if (currentId != reviewerInformation.Id && isAllow) {
      setIsAllow(false);
      sendMessage({
        action: WEBVIEW_ACTION.GO_TO_GIG_PROFILE,
        profileName: reviewerInformation.UserName
      });
    }
  };
  useEffect(() => {
    if (!isAllow) {
      setTimeout(() => {
        setIsAllow(true);
      }, 1000);
    }
  }, [isAllow]);
  return (
    <>
      {reviewRating && reviewerInformation ? (
        <li>
          <Avatar
            onClick={viewProfile}
            alt="Remy Sharp"
            src={`${DOMAIN}profile/${reviewerInformation.Image}`}
            className={classes.small}
          />
          <div className="content">
            <h5>
              {reviewerInformation.FirstName &&
              reviewerInformation.FirstName.trim() !== ""
                ? `${reviewerInformation.FirstName}${
                    reviewerInformation.LastName &&
                    reviewerInformation.LastName.trim() !== ""
                      ? ` ${reviewerInformation.LastName}`
                      : ""
                  }`
                : `${reviewerInformation.UserName}`}
              <span className="time">
                {`${reviewRating.LastUpdated} ${
                  reviewRating.IsEdited ? " (edited)" : ""
                }`}
              </span>
              {canEdit ? (
                <small>
                  <div className="icon">
                    <ReviewEdit
                      reviewRatingId={reviewRating.Id}
                      handleOpenCommentDialog={handleOpenCommentDialog}
                      jobCode={jobCode}
                      props={props}
                    />
                  </div>
                </small>
              ) : null}
            </h5>
            <Rating
              name="half-rating-read"
              value={reviewRating.Rating}
              size="small"
              precision={0.1}
              readOnly
            />
            <p class="multiline">{reviewRating.Comment}</p>
          </div>
        </li>
      ) : null}
    </>
  );
};

export default ReviewRatingCommentItem;
