import React, { useEffect, useState } from "react";
import { getAxios } from "../../ultils/helper";
import { API, LOCAL_STORAGE_KEY, WEBVIEW_ACTION } from "../../config/config";
import FollowItem from "./FollowItem";
import List from "@material-ui/core/List";
import axios from "axios";
import { sendMessage } from "../../ultils/ultils";
export default function FollowPage(props) {
  const { username, keyword } = props.match.params;
  console.log({ username });
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const [data, setData] = useState([]);
  const [isAllow, setIsAllow] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const result = await axios.post(
        API.GET_FOLLOW_DATA,
        {
          username,
          keyword,
          currentId:
            process.env.REACT_APP_NODE_ENV === "development" ? 1 : currentId,
        },
        {}
      );
      if (result.data) {
        setData(result.data);
      }
    };
    getData();
  }, {});
  const goToProfile = async ({ profileId, profileName }) => {
    //alert(profileId);
    if (isAllow) {
      console.log({ profileId });
      if (parseInt(profileId) !== parseInt(currentId)) {
        sendMessage({
          action: WEBVIEW_ACTION.GO_TO_GIG_PROFILE,
          profileName: profileName,
        });
      }
      setIsAllow(false);
    }
  };
  useEffect(() => {
    if (!isAllow) {
      setTimeout(() => {
        setIsAllow(true);
      }, 500);
    }
  }, [isAllow]);
  return (
    <div>
      <List>
        {data.length > 0 &&
          data.map((item) => (
            <FollowItem item={item} handleClick={goToProfile} />
          ))}
      </List>
    </div>
  );
}
