import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import axios from "axios";
import React from "react";
import {
  API,
  FILTER_MESSAGE,
  LOCAL_STORAGE_KEY,
  BUTTON_TEXT,
  WEBVIEW_ACTION,
} from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
// import Loading from "../../layout/Loading";
// import JobBrowsingFilterErrorDialog from "./JobBrowsingFilterErrorDialog";

const JobBrowsingFilterDialog = (props) => {
  const { openDialog, closeDialog, closeJobBrowsingDialog, filterData } = props;
  // const [spinnerOn, setSpinnerOn] = useState(false);
  // const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const handleClose = () => {
    closeDialog();
  };

  // const handleCloseErrorDialog = () => {
  //   setOpenErrorDialog(false);
  // };

  const handleClick = async (e, isYesClick) => {
    try {
      // const result = await axios.post(API.UPDATE_GIGGER_BY_TOKEN, {
      //   token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
      //   isReceivedJobNotification: isYesClick,
      // });

      const getGiggerFilterByGiggerId = await axios.post(
        API.GET_GIGGER_FILTER_BY_GIGGER_ID,
        {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        }
      );

      let updatedFilterData = isYesClick
        ? filterData
        : {
            area: [],
            jobCategories: [],
            dateFrom: null,
            dateTo: null,
          };

      if (isYesClick) {
        const result = await axios.post(API.UPDATE_GIGGER_BY_TOKEN, {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
          isReceivedJobNotification: isYesClick,
        });
        if (
          Object.keys(getGiggerFilterByGiggerId.data.filterValue).length === 0
        ) {
          await axios.post(API.INSERT_GIGGER_FILTER, {
            filterData: updatedFilterData,
            token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
          });
        } else {
          await axios.post(API.UPDATE_GIGGER_FILTER, {
            filterData: updatedFilterData,
            token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
          });
        }
        sendMessage({ action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW });
      }

      closeDialog();
      closeJobBrowsingDialog();
    } catch (error) {}
    // const error = result.data.error;
    // setSpinnerOn(false);
    // setOpenErrorDialog(true);
    // if (error) {
    //   setOpenErrorDialog(true);
    // } else {
    //   setOpenErrorDialog(false);
    // }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="dialog">
          {FILTER_MESSAGE.MESSAGE}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to save this as your job alert?
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button
            onClick={(e) => handleClick(e, true)}
            color="primary"
            autoFocus
          >
            {BUTTON_TEXT.YES}
          </Button>
          <Button onClick={(e) => handleClick(e, false)} autoFocus>
            {BUTTON_TEXT.NO}
          </Button>
        </DialogActions>
      </Dialog>
      {/* {spinnerOn ? <Loading /> : null}
      {openErrorDialog ? (
        <JobBrowsingFilterErrorDialog
          openErrorDialog={openErrorDialog}
          handleCloseErrorDialog={handleCloseErrorDialog}
        />
      ) : null} */}
    </div>
  );
};

export default JobBrowsingFilterDialog;
