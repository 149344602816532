import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Avatar, Container, Grid, TextField } from "@material-ui/core";
import FAQGiggers from "./FAQGiggers";
import FAQGigboss from "./FAQGigboss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Container maxWidth="sm" className="tabs tabFAQ">
      <div className="fixed">
        <h2>FAQ</h2>

        <Tabs
          indicatorColor="transparent"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          className="_tabs"
        >
          <Tab className="txtTabs" label="Giggers" {...a11yProps(0)} />
          <Tab className="txtTabs" label="Gigboss" {...a11yProps(1)} />
        </Tabs>
      </div>
      <TabPanel className="TabPanel" value={value} index={0}>
        <FAQGiggers />
      </TabPanel>
      <TabPanel className="TabPanel" value={value} index={1}>
        <FAQGigboss />
      </TabPanel>
    </Container>
  );
}
