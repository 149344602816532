const GIGGERS_CONTENT = [
  {
    key: "What is the maximum number of gigs I can apply for?",
    values: [
      "You may apply for as many jobs as you like but please ensure you can commit to them all once confirmed.",
    ],
  },
  {
    key: "How long is the processing time for each gig application?",
    values: [
      "Processing time depends on each individual Gigboss. You will be notified if you have been confirmed or rejected for each gig.",
      "Alternatively, you may also check-in directly with the Gigboss by tapping on “Contact via WhatsApp” in the gig listing.",
    ],
  },
  {
    key:
      "Gigboss has not paid me even though the gig is completed. What can I do?",
    values: [
      "Report the gig and provide us with details of the incident so that we can reach out to the Gigboss in question for investigation. You may also contact the Ministry of Manpower to obtain assistance.",
    ],
  },
  {
    key: "Are leaving reviews compulsory?",
    values: [
      "No, it is not compulsory but we strongly encourage you to. This helps our community maintain professional authenticity.",
    ],
  },
  {
    key: "Will my account be banned if my ratings/reviews are bad?",
    values: [
      "As long as you have not violated any of our community standards, you may continue to apply for gigs. However, we strongly encourage you to perform your due diligence to obtain better reviews and improve your overall score.",
    ],
  },
  {
    key: "What constitutes an account ban/removal?",
    values: [
      "Any violation of the gigsomething community standards will be reviewed for further action including but not amounting to, a total and permanent ban from the gigsomething community. Please report users or listings for review if you feel they have violated community guidelines in any way.",
    ],
  },
  {
    key:
      "I am confirmed for a gig, but I have an emergency. Can I ask a friend or someone else to take my place?",
    values: [
      " Please contact the Gigboss directly via WhatsApp to explain the circumstances and come to a mutually agreed upon solution. ",
    ],
  },
  {
    key:
      "Who should I write in to if I have questions that are not listed in the FAQ / report a bug / to provide feedback & suggestions for gigsomething?",
    values: [
      `Please write in to <a href="mailto:hello@gigsomething.sg">hello@gigsomething.sg</a> with further information and we’ll get back to you.`,
    ],
    innerHTML: true,
  },
  {
    key: "Gigboss left me an unfair/untrue/abusive review.",
    values: [
      "You may report the gig review and our team will investigate the case and respond accordingly.",
    ],
  },
  {
    key:
      "Gigboss claims they have paid but I have not received any payment yet.",
    values: [
      "Please request for proof of payment, otherwise, you may report the gig for review.",
    ],
  },
  {
    key: "Gigboss cancelled the gig at the last minute.",
    values: [
      "We understand some circumstances can be beyond your control. Please communicate with the Gigboss on rescheduling gig dates or coming to a mutually agreed upon solution.",
    ],
  },
  {
    key:
      "I am a foreign domestic worker residing in Singapore. Can I use gigsomething as well?",
    values: [
      `Unfortunately, it is not permissible for you to be employed by
      another employer. This is in compliance with the foreign domestic
      workers’
      <a
        target="_blank"
        href="https://www.mom.gov.sg/passes-and-permits/work-permit-for-foreign-domestic-worker/employers-guide/employment-rules#:~:text=A%20foreign%20domestic%20worker%20(%20FDW,or%20perform%20non%2Ddomestic%20chores.&text=Cannot%20take%20on%20any%20part%2Dtime%20work."
      >
        work permit conditions
      </a>
      .`,
    ],
    innerHTML: true,
  },
  {
    key: "Are there any charges/hidden charges in using gigsomething?",
    values: ["No, it is absolutely free to use gigsomething!"],
  },
  {
    key: "Can I join gigsomething?",
    values: [
      ` Yes, definitely! Everyone is welcome to join our free gigsomething
    community :)`,
    ],
  },
];
const GIGBOSS_CONTENT = [
  {
    key: "How many gig listings can I post?",
    values: ["You may post as many gig listings as you like for free!"],
  },
  {
    key: "Do gig applicants get notified when I confirm/reject them?",
    values: [
      " Yes, they will receive a notification when you accept or reject them.",
    ],
  },
  {
    key:
      "What if the gig I listed is no longer applicable? (I changed my mind/decided not to hire)",
    values: [
      "You may select the option to “Stop Accepting Applicants” and complete the gig so the listing would no longer be live.",
    ],
  },
  {
    key:
      "Can I export the data of the applicants in my current job posting for future reference?",
    values: [
      "No. However, you may opt to receive applications via a form link of your own (eg. Google forms).",
    ],
  },
  {
    key: "Gigger did not turn up for my confirmed gig. What can I do?",
    values: [
      "You may mark the gig as completed to exchange reviews and indicate that the Gigger did not turn up.",
    ],
  },
  {
    key: "I have errors on my gig listing and wish to edit it.",
    values: [
      "To maintain authenticity, users are unable to edit gig listings once they are published.",
    ],
  },
  {
    key: "Gigger I hired was unqualified for the position.",
    values: [
      "Please communicate all gig requirements clearly with the Gigger to ascertain the candidates’ suitability for the role before confirming them.",
      "However, if a Gigger has been dishonest with his/her qualifications please reflect this in their review. ",
    ],
  },
  {
    key: "I’d like to join/contribute to the gigsomething team.",
    values: [
      `Say hello & send in your resumes to <a href="mailto:hello@gigsomething.sg">hello@gigsomething.sg </a>`,
    ],
    innerHTML: true,
  },
  {
    key: "Gigger left me an unfair/untrue/abusive review.",
    values: [
      " You may report the gig review and our team will investigate the case and respond accordingly.",
    ],
  },
  {
    key:
      "I have made payment to the gigger for completed work but he/she claims to have not received it.",
    values: [
      "Please provide proof of payment/receipt to the gigger. Otherwise, you may also report the Gigger for review.",
    ],
  },
  {
    key: "Gigger cancelled the gig at the last minute.",
    values: [
      " We understand some circumstances can be beyond your control. Please communicate with the Gigger on rescheduling gig dates or coming to a mutually agreed upon solution.",
    ],
  },
  {
    key: "Can I hire foreigners?",
    values: [
      `No foreigners are allowed to work part-time. Foreign students, however, are allowed to work part-time - provided that they meet the criterias stated by MOM. Please find out more 
    <a target="_blank" href="https://www.mom.gov.sg/passes-and-permits/work-pass-exemption-for-foreign-students">here</a>.`,
    ],
    innerHTML: true,
  },
  {
    key: "Are there any charges/hidden charges in using gigsomething?",
    values: ["No, it is absolutely free to use gigsomething!"],
  },
  {
    key: "Can I join gigsomething?",
    values: [
      " Yes, definitely! Everyone is welcome to join our free gigsomething community :)",
    ],
  },
];
export const FAQ_CONTENTS = {
  GIGGERS: GIGGERS_CONTENT,
  GIGBOSS: GIGBOSS_CONTENT,
};
