import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { LOCAL_STORAGE_KEY } from "../../config/config";
export default function DarkMode(props) {
  const [darkState, setDarkState] = useState(
    props.darkState
      ? props.darkState
      : localStorage[LOCAL_STORAGE_KEY.DARK_MODE]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.DARK_MODE])
      : false
  );
  
  // Toggle Dark Mode
  useEffect(() => {
    if (props.darkState !== null) {
      toggleDarkMode(props.darkState);
    }
  }, [props.darkState]);
  // Dark Mode Config
  useEffect(() => {
    if (darkState !== null) {
      darkState
        ? document.body.classList.add("darkmode")
        : document.body.classList.remove("darkmode");
    }
  }, []);

  const palletType = darkState ? "dark" : "light";
  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
      background: {
        default: darkState ? "#121212" : "#f5f5f5",
      },
      primary: {
        main: "#832CFF",
      },
      secondary: {
        main: "#f44336",
      },
    },
    typography: {
      fontFamily: "Montserrat, Arial",
      fontWeightRegular: 500,
      fontSize: 13.5,
    },
  });
  const toggleDarkMode = (darkState) => {
    darkState
      ? document.body.classList.add("darkmode")
      : document.body.classList.remove("darkmode");
    setDarkState(darkState);
  };
  return (
    <Fragment>
      <ThemeProvider theme={darkTheme}> {props.children}</ThemeProvider>
    </Fragment>
  );
}
