import { Container, Grid, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Business from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import {
  API,
  GA_PARAMS,
  JOB_FILTER_CONDITIONS,
  JOB_STATUS,
  LOCAL_STORAGE_KEY,
  WEBVIEW_ACTION,
} from "../../config/config";
import {
  GET_AREAS,
  ON_FOREGROUND_STATUS,
} from "../../store/actions/actionTypes";
import DarkMode from "../DarkMode/DarkMode";
import JobsList from "../Jobs/JobsList";
import Sticky from "react-sticky-el";
import { getNewAreas, sendMessage } from "../../ultils/ultils";
import { Helmet } from "react-helmet";
import { initGA } from "../Tracking/GATracking";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const initialValue = 0;
export default function MyGigs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(initialValue);
  const [profileId, setProfileId] = useState(null);
  const [gigAdsValue, setGigAdsValue] = React.useState(initialValue);
  const [gigApplicationValue, setGigApplicationValue] = useState(initialValue);
  const areas = useSelector((state) => state.reduxReducer.areas);
  const dispatch = useDispatch();
  const [darkState, setDarkState] = useState(null);
  const [haveToken, setHaveToken] = useState(false);
  const [onForeGround, setOnForeground] = useState(true);
  const [onTop, setOnTop] = useState(true);
  window.onscroll = function () {
    // @var int totalPageHeight
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop === 0) {
      setOnTop(true);
    } else {
      setOnTop(false);
    }
  };
  useEffect(() => {
    sendMessage({ action: WEBVIEW_ACTION.SET_ENABLE_REFRESH, isEnable: onTop });
  }, [onTop]);
  const handleChangeGigAdsTab = (event, newValue) => {
    setGigAdsValue(newValue);
  };
  const handleChangeGigApplicationTab = (event, newValue) => {
    console.log({ newValue });
    setGigApplicationValue(newValue);
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const _onMessage = async (data) => {
    if (data.token) {
      //alert("ON_MY_GIGS");
      setProfileId(data.currentId);
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, data.token);
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_ID, data.currentId);
      //alert(localStorage.token);
      setHaveToken(true);
    }
  };
  const _onToggle = async (data) => {
    setDarkState(data.darkMode);
  };
  const _onChangeValue = async (data) => {
    setValue(initialValue);
    setGigApplicationValue(data.value);
  };
  const _onForeGround = async () => {
    setOnForeground(true);
    dispatch({ type: ON_FOREGROUND_STATUS });
  };
  const event = new Event("WebViewBridge");
  window.dispatchEvent(event);
  useEffect(() => {
    window.WebViewBridge = {
      on_my_gigs: _onMessage,
      toggle_darkmode: _onToggle,
      change_my_gigs: _onChangeValue,
      on_foreground: _onForeGround,
    };
  }, []);
  useEffect(() => {
    const fetchAllAreas = async () => {
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      let areaData = getAllAreas.data;
      const newAreas = getNewAreas(areaData);
      dispatch({ type: GET_AREAS, payload: newAreas });
      //setAreas(getAllAreas.data);
    };
    if (onForeGround) {
      setOnForeground(false);
      if (!areas) {
        fetchAllAreas();
      }
    }
  }, [onForeGround]);
  return (
    <Fragment>
      <Helmet>{initGA()}</Helmet>
      {haveToken && !onForeGround && (
        <DarkMode darkState={darkState}>
          <Container maxWidth="sm" className="tabs">
            <div className={classes.root}>
              <div className="tabsMygigs">
                <div className="width">
                  <Tabs
                    indicatorColor="primary"
                    variant="fullWidth"
                    value={value}
                    onChange={handleChangeTab}
                    aria-label="nav tabs example"
                    className="_tabs"
                  >
                    <Tab
                      className="txtTabs"
                      icon={<PersonIcon />}
                      label="Gigger"
                      {...a11yProps(0)}
                    />

                    <Tab
                      className="txtTabs"
                      icon={<Business />}
                      onClick={() => console.log("Clicked")}
                      label="Gig Boss"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>
              </div>
              <TabPanel className="TabPanel" value={value} index={0}>
                <Grid className="gigChild">
                  <div className="stickytabs">
                    <div className="width">
                      <h6>Gig Applications</h6>
                      {/* <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Search"
                        className="txtSearch"
                        style={{ marginBottom: 10, width: "100%" }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon style={{ color: "#a2a2a2" }} />
                          ),
                        }}
                      /> */}
                      <Tabs
                        indicatorColor="primary"
                        variant="fullWidth"
                        value={gigApplicationValue}
                        onChange={handleChangeGigApplicationTab}
                        aria-label="nav tabs example"
                        className="tabschild"
                      >
                        <Tab label="Applied" {...a11yProps(0)} />
                        <Tab label="Confirmed" {...a11yProps(1)} />
                        <Tab label="Completed" {...a11yProps(2)} />
                        <Tab label="Rejected" {...a11yProps(3)} />
                      </Tabs>
                    </div>
                  </div>
                  <TabPanel
                    className="box mt"
                    value={gigApplicationValue}
                    index={0}
                  >
                    <JobsList
                      filterCondition={JOB_STATUS.GIG_APPLIED}
                      giggerId={profileId}
                      areas={areas}
                    />
                  </TabPanel>
                  <TabPanel
                    className="box mt"
                    value={gigApplicationValue}
                    index={1}
                  >
                    <JobsList
                      filterCondition={JOB_STATUS.GIG_CONFIRMED}
                      giggerId={profileId}
                      areas={areas}
                    />
                  </TabPanel>
                  <TabPanel
                    className="box mt"
                    value={gigApplicationValue}
                    index={2}
                  >
                    <JobsList
                      filterCondition={JOB_STATUS.GIG_COMPLETED}
                      giggerId={profileId}
                      isMyCompletedGigs={true}
                    />
                  </TabPanel>
                  <TabPanel
                    className="box mt"
                    value={gigApplicationValue}
                    index={3}
                  >
                    <JobsList
                      filterCondition={JOB_STATUS.GIG_REJECTED}
                      giggerId={profileId}
                    />
                  </TabPanel>
                </Grid>
              </TabPanel>

              <TabPanel className="TabPanel" value={value} index={1}>
                <Grid className="gigChild">
                  <div className="stickytabs">
                    <div className="width">
                      <h6>Gig Ads</h6>
                      {/* <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Search"
                        className="txtSearch"
                        style={{ marginBottom: 10, width: "100%" }}
                        InputProps={{
                          startAdornment: (
                            <SearchIcon style={{ color: "#a2a2a2" }} />
                          ),
                        }}
                      /> */}
                      <Tabs
                        indicatorColor="primary"
                        variant="fullWidth"
                        value={gigAdsValue}
                        onChange={handleChangeGigAdsTab}
                        aria-label="nav tabs example"
                        className="tabschild"
                      >
                        <Tab label="Active" {...a11yProps(0)} />
                        <Tab label="Expired" {...a11yProps(1)} />
                      </Tabs>
                    </div>
                  </div>
                  <TabPanel className="box mt" value={gigAdsValue} index={0}>
                    <JobsList
                      showApplications={true}
                      filterCondition={
                        JOB_FILTER_CONDITIONS.GIG_BOSS_ACTIVE_JOBS
                      }
                      giggerId={profileId}
                      
                    />
                  </TabPanel>
                  <TabPanel className="box mt" value={gigAdsValue} index={1}>
                    <JobsList
                      showApplications={true}
                      filterCondition={
                        JOB_FILTER_CONDITIONS.GIG_BOSS_EXPIRED_JOBS
                      }
                      giggerId={profileId}
                      isGigBossExpiredGigs={true}
                      
                    />
                  </TabPanel>
                </Grid>
              </TabPanel>
            </div>
          </Container>
        </DarkMode>
      )}
    </Fragment>
  );
}
