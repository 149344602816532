import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import { BUTTON_TEXT, COMMENT_FORM } from "../../config/config";

const NotificationPostedCommentDialog = ({
  openDialog,
  closeDialog,
  closeCommentDialog,
  isSuccessfullyPostedComment,
  jobCode,
  props,
  isEditComment,
  setReviewRatingPercentKey,
  newReviewId
}) => {
  //#region onClick

  const handleClose = () => {
    closeDialog();
  };

  const handleClick = () => {
    if (isSuccessfullyPostedComment) {
      closeDialog();
      closeCommentDialog();

      // Set new key for ReviewRatingPercent component to reload the component
      if (setReviewRatingPercentKey) {
        const newKey = Math.floor(Math.random() * 90000) + 10000;
        setReviewRatingPercentKey(newKey);
      }

      // Send new review id to ReviewRatingComments component to add to reviews list in Job Details
      if (newReviewId > 0) {
        props.history.push({
          pathname: `/gigs/details/${jobCode}`,
          state: {
            newReviewId: newReviewId
          }
        });
      }

      // Reload after edit review
      if (isEditComment) {
        props.history.push({
          pathname: `/gigs/details/${jobCode}`,
          state: {
            isReloadAfterDeleteOrEdit: true
          }
        });
      }
    } else {
      closeCommentDialog();
    }
  };

  //#endregion

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isSuccessfullyPostedComment ? (
          <DialogTitle id="alert-dialog-title" className="dialog">
            {isEditComment
              ? COMMENT_FORM.MESSAGE.SUCCESSFULLY_EDITED
              : COMMENT_FORM.MESSAGE.SUCCESSFULLY_POSTED}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title" className="dialog">
            {isEditComment
              ? COMMENT_FORM.ERROR_MESSAGE.ERROR_EDIT
              : COMMENT_FORM.ERROR_MESSAGE.ERROR_POST}
          </DialogTitle>
        )}

        <DialogActions>
          <Button onClick={handleClick} color="primary" autoFocus>
            {BUTTON_TEXT.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationPostedCommentDialog;
