import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState } from "react";
import axios from "axios";
import {
  MESSAGE,
  BUTTON_TEXT,
  API,
  LOCAL_STORAGE_KEY
} from "../../config/config";
import { Button } from "@material-ui/core";
import ReportGigNotificationDialog from "./ReportGigNotificationDialog";

const ReportGigConfirmDialog = (props) => {
  const { openDialog, handleCloseDialog, jobId } = props;

  const [
    openReportGigNotificationDialog,
    setOpenReportGigNotificationDialog
  ] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleYesClick = () => {
    const yesClick = async () => {
      try {
        const result = await axios.post(API.INSERT_REPORT_JOB, {
          jobId: jobId,
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN]
        });

        const error = result.data.error;
        if (error) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      } catch (error) {}
      setOpenReportGigNotificationDialog(true);
    };

    yesClick();
  };

  const handleCloseReportGigNotificationDialog = () => {
    setOpenReportGigNotificationDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="alert-dialog-title">
          <div style={{ fontSize: 15 }}>
            {MESSAGE.REPORT_GIG_CONFIRM_MESSAGE}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYesClick} color="primary" autoFocus>
            {BUTTON_TEXT.YES}
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            {BUTTON_TEXT.NO}
          </Button>
        </DialogActions>
      </Dialog>

      {openReportGigNotificationDialog ? (
        <ReportGigNotificationDialog
          openDialog={openReportGigNotificationDialog}
          handleCloseDialog={handleCloseReportGigNotificationDialog}
          handleCloseConfirmDialog={handleCloseDialog}
          hasError={hasError}
        />
      ) : null}
    </div>
  );
};

export default ReportGigConfirmDialog;
