import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import {
  DELETE_COMMENT_DIALOG_MESSAGE,
  BUTTON_TEXT
} from "../../config/config";

const ReviewEditNotificationDialog = ({
  openDialog,
  handleCloseDialog,
  jobCode,
  props,
  hasError
}) => {
  const handleOKClick = () => {
    handleCloseDialog();

    if (!hasError) {
      setTimeout(() => {
        props.history.push({
          pathname: `/gigs/details/${jobCode}`,
          state: { isReloadAfterDeleteOrEdit: true }
        });
      }, 500);
    }
    // let urlReload = `${CLIENT_DOMAIN}gigs/details/${jobCode}`;
    // window.location.href = urlReload;
  };
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {hasError ? (
          <DialogTitle id="alert-dialog-title" className="dialog">
            {DELETE_COMMENT_DIALOG_MESSAGE.FAILED_DELETED}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title" className="dialog">
            {DELETE_COMMENT_DIALOG_MESSAGE.SUCCESSFULLY_DELETED}
          </DialogTitle>
        )}
        <DialogActions>
          <Button onClick={handleOKClick} color="primary" autoFocus>
            {BUTTON_TEXT.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReviewEditNotificationDialog;
