import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@material-ui/core/Button";
import { sendMessage } from "../../ultils/ultils";
import { WEBVIEW_ACTION } from "../../config/config";
export default function NotificationAlert({ handleDismiss }) {
  const handleClick = () => {
    sendMessage({ action: WEBVIEW_ACTION.SET_UP_GIG_ALERT });
  };

  return (
    <div className="alert">
      <p>
        <span>
          <InfoOutlinedIcon style={{ fontSize: 32, color: "#2196f3" }} />
        </span>{" "}
        You have not set up a gig alert yet. Would you like to set it up so you
        will receive a notification whenever a new gig that interests you is
        posted?
      </p>
      <div className="button">
        <Button
          onClick={handleDismiss}
          size="small"
          variant="outlined"
          color="primary"
        >
          DISMISS
        </Button>
        <Button
          onClick={handleClick}
          style={{ width: " 100%" }}
          variant="contained"
          color="primary"
          size="small"
        >
          Set up gig alert
        </Button>
      </div>
    </div>
  );
}
