import React from "react";
import { WEBVIEW_ACTION, LOCAL_STORAGE_KEY, DOMAIN } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Avatar } from "@material-ui/core";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
export default function FollowItem({ item, handleClick }) {
  const classes = useStyles();
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  return (
    <div
      onClick={() =>
        handleClick({
          profileId: item.profileId,
          profileName: item.UserName,
        })
      }
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar
            alt="user image"
            src={`${DOMAIN}profile/${item.Image}`}
            className={classes.medium}
            // style={{ margin: "0 auto 5px" }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            item.FirstName && item.FirstName.trim() !== ""
              ? `${item.FirstName}${
                  item.LastName && item.LastName.trim() !== ""
                    ? ` ${item.LastName}`
                    : ""
                }`
              : `${item.UserName}`
          }
        />
        {/* <ListItemSecondaryAction>
          <IconButton
            onClick={() =>
              handleClick({
                profileId: item.profileId,
                profileName: item.UserName,
              })
            }
            edge="end"
            aria-label="profile"
          >
            <AccountCircleIcon />
          </IconButton>
        </ListItemSecondaryAction> */}
      </ListItem>
    </div>
  );
}
