import React from "react";
import { Avatar, Container, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import YouTubeIcon from "@material-ui/icons/YouTube";
import CancelIcon from "@material-ui/icons/Cancel";
import { YoutubeLink } from "./RenderLists/YoutubeLink";
import { FORM_TYPES, PORTFOLIO_MESSAGE } from "../config/config";
import { JSONParseObj } from "../ultils/ultils";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogYoutubeLink({
  profile,
  removeYoutubeLink,
  updateYoutubeLink,
  inputLink,
  handleChangeLink,
  refValues,
  previewLinks,
  clearPreviewLinks,
  updateProfileYoutubeLinks,
  handleDelete,
  youtubeLinkError,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearPreviewLinks();
    setOpen(false);
  };
  const handleSave = () => {
    if (!youtubeLinkError) {
      setOpen(false);
      updateProfileYoutubeLinks();
    }
  };
  return (
    <>
      <div className="image" onClick={handleClickOpen}>
        <YouTubeIcon style={{ color: "#f4666c" }} fontSize="large" />
      </div>
      YouTube <span>Max 10 links </span>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Youtube
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className="youtubeDialog">
          {/* <input
            type="text"
            placeholder="Copy YouTube link here"
            className="TextField"
            value={inputLink || ""}
            onChange={(e) => handleChangeLink(e)}
          /> */}
          <TextField
            inputProps={{
              autoCapitalize: "none",
              // inputMode: "url",
            }}
            id="linkTitle"
            placeholder="Title"
            type="text"
            inputRef={refValues["linkTitleRef"]}
            className="TextField"
            style={{ marginBottom: 16 }}
          />
          <TextField
            inputProps={{
              autoCapitalize: "none",
              inputMode: "url",
            }}
            id="youtubelink"
            placeholder="Copy YouTube link here"
            type="text"
            inputRef={refValues["linkRef"]}
            className="TextField"
          />
          {youtubeLinkError && (
            <span className="error">{youtubeLinkError}</span>
          )}

          <div style={{ textAlign: "center", margin: "15px 0" }}>
            <Button
              onClick={() => updateYoutubeLink()}
              color="primary"
              variant="contained"
            >
              Ok
            </Button>
          </div>
          <div className="uploadComplete">
            <ul>
              {previewLinks && JSONParseObj(previewLinks).length > 0
                ? JSONParseObj(previewLinks).map((item, index) => (
                    <li>
                      <YoutubeLink link={item} title={item.title} />
                      <CancelIcon
                        onClick={() => removeYoutubeLink(index, true)}
                        //onClick={() => console.log("HERE")}
                        className="cancel"
                      />
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      </Dialog>
    </>
  );
}
