import Avatar from "@material-ui/core/Avatar";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { DOMAIN, LOCAL_STORAGE_KEY, WEBVIEW_ACTION } from "../config/config";
import { sendMessage } from "../ultils/ultils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    medium: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginBottom: 7,
    },
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
);
export default function FollowProfile({ follows, title, username }) {
  //src={`${DOMAIN}profile/thumbnail-${follow.Image}`}
  const classes = useStyles();
  let currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const goToProfile = async ({ profileId, profileName }) => {
    //alert(profileId);
    if (parseInt(profileId) !== parseInt(currentId)) {
      sendMessage({
        action: WEBVIEW_ACTION.GO_TO_GIG_PROFILE,
        profileName: profileName,
      });
    }
  };
  return (
    <div className="follow">
      {/* <FollowsList />
      <div className="group">
        <div className="content">
          <h4>
            {title} {follows.length > 0 && `(${follows.length})`}
          </h4>
          <AvatarGroup className="AvatarGroup" max={3}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
          </AvatarGroup>
          <div className="more">See All</div>
        </div>
        <div className="content">
          <h4>
            {title} {follows.length > 0 && `(${follows.length})`}
          </h4>
          <AvatarGroup className="AvatarGroup" max={3}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
          </AvatarGroup>
          <div className="more">See All</div>
        </div>
      </div> */}
      <h5>
        {title} {follows.length > 0 && `(${follows.length})`}
      </h5>
      <div className="box">
        {follows.map((follow) => (
          <div className="grid">
            <Avatar
              alt="Remy Sharp"
              src={`${DOMAIN}profile/${follow.Image}`}
              className={classes.medium}
              style={{ margin: "0 auto 5px" }}
              onClick={() =>
                goToProfile({
                  profileId: follow.profileId,
                  profileName: follow.UserName,
                })
              }
            />
            {follow.FirstName && follow.FirstName.trim() !== ""
              ? `${follow.FirstName}${
                  follow.LastName && follow.LastName.trim() !== ""
                    ? ` ${follow.LastName}`
                    : ""
                }`
              : `${follow.UserName}`}
          </div>
        ))}
      </div>
    </div>
  );
}
