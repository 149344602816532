import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../config/config";
import DialogComment from "../DialogComment";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },

  bar: {
    borderRadius: 5,
    backgroundColor: "#832cff"
  }
}))(LinearProgress);

const ReviewRatingPercent = ({
  isPostComment,
  currentReviewRatingsPercent,
  job,
  giggerJob,
  props,
  currentTotalReviewRatings,
  setReviewRatingPercentKey
}) => {
  //#region initial

  const [reviewRatingsPercent, setReviewRatingsPercent] = useState([]);
  const [totalReviewRatings, setTotalReviewRatings] = useState(0);
  const [isReloadAfterDeleteOrEdit, setIsReloadAfterDeleteOrEdit] = useState(
    false
  );

  //#endregion

  //#region useEffect

  useEffect(() => {
    if (props && props.location.state) {
      setIsReloadAfterDeleteOrEdit(
        props.location.state.isReloadAfterDeleteOrEdit
          ? props.location.state.isReloadAfterDeleteOrEdit
          : false
      );
    }
  }, [props]);

  useEffect(() => {
    const fetchData = async () => {
      if (job || isReloadAfterDeleteOrEdit) {
        const getAllReviewRatingsByGigBossId = await axios.post(
          API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID,
          {
            revieweeId: job.GigBossId,
            isReviewsGiggerClick: false
          }
        );

        if (getAllReviewRatingsByGigBossId.data) {
          setReviewRatingsPercent(
            getAllReviewRatingsByGigBossId.data.ReviewRatingsPercent
          );
          setTotalReviewRatings(
            getAllReviewRatingsByGigBossId.data.TotalReviewRatings
          );
        } else {
          setReviewRatingsPercent([]);
          setTotalReviewRatings(0);
        }
      }

      if (currentReviewRatingsPercent) {
        setReviewRatingsPercent(currentReviewRatingsPercent);
        setTotalReviewRatings(currentTotalReviewRatings);
      }
    };
    if (isReloadAfterDeleteOrEdit) {
      fetchData();
      setIsReloadAfterDeleteOrEdit(false);
    } else {
      fetchData();
    }
  }, [currentReviewRatingsPercent, isReloadAfterDeleteOrEdit]);

  //#endregion

  return (
    <>
      <div className="rateout">
        {reviewRatingsPercent.length > 0 ? (
          <p>
            {
              reviewRatingsPercent.find((item) => item.key === "averageStars")
                .value
            }{" "}
            <span>out of 5</span>
          </p>
        ) : (
          <span>No ratings</span>
        )}

        <Rating
          name="half-rating-read"
          value={
            reviewRatingsPercent.length > 0
              ? reviewRatingsPercent.find((item) => item.key === "averageStars")
                  .value
              : 0
          }
          precision={0.1}
          readOnly
        />
      </div>

      <ul className="star-progress-bar">
        <li>
          <p>
            <label>5</label>{" "}
            <StarIcon fontSize="small" style={{ color: "#ffb400" }} />
          </p>{" "}
          <div>
            {" "}
            <BorderLinearProgress
              variant="determinate"
              value={
                reviewRatingsPercent.length > 0
                  ? reviewRatingsPercent.find(
                      (item) => item.key === "fiveStars"
                    ).value
                  : 0
              }
            />
          </div>
          <span>
            {reviewRatingsPercent.length > 0
              ? reviewRatingsPercent.find((item) => item.key === "fiveStars")
                  .value
              : 0}
            %
          </span>
        </li>
        <li>
          <p>
            <label>4</label>{" "}
            <StarIcon fontSize="small" style={{ color: "#ffb400" }} />{" "}
          </p>
          <div>
            <BorderLinearProgress
              variant="determinate"
              value={
                reviewRatingsPercent.length > 0
                  ? reviewRatingsPercent.find(
                      (item) => item.key === "fourStars"
                    ).value
                  : 0
              }
            />
          </div>
          <span>
            {reviewRatingsPercent.length > 0
              ? reviewRatingsPercent.find((item) => item.key === "fourStars")
                  .value
              : 0}
            %
          </span>
        </li>
        <li>
          <p>
            <label>3</label>
            <StarIcon fontSize="small" style={{ color: "#ffb400" }} />
          </p>{" "}
          <div>
            <BorderLinearProgress
              variant="determinate"
              value={
                reviewRatingsPercent.length > 0
                  ? reviewRatingsPercent.find(
                      (item) => item.key === "threeStars"
                    ).value
                  : 0
              }
            />
          </div>
          <span>
            {reviewRatingsPercent.length > 0
              ? reviewRatingsPercent.find((item) => item.key === "threeStars")
                  .value
              : 0}
            %
          </span>
        </li>
        <li>
          <p>
            <label>2</label>{" "}
            <StarIcon fontSize="small" style={{ color: "#ffb400" }} />
          </p>{" "}
          <div>
            <BorderLinearProgress
              variant="determinate"
              value={
                reviewRatingsPercent.length > 0
                  ? reviewRatingsPercent.find((item) => item.key === "twoStars")
                      .value
                  : 0
              }
            />
          </div>{" "}
          <span>
            {reviewRatingsPercent.length > 0
              ? reviewRatingsPercent.find((item) => item.key === "twoStars")
                  .value
              : 0}
            %
          </span>
        </li>
        <li>
          <p>
            <label>1</label>{" "}
            <StarIcon fontSize="small" style={{ color: "#ffb400" }} />
          </p>{" "}
          <div>
            <BorderLinearProgress
              variant="determinate"
              value={
                reviewRatingsPercent.length > 0
                  ? reviewRatingsPercent.find((item) => item.key === "oneStar")
                      .value
                  : 0
              }
            />
          </div>
          <span>
            {reviewRatingsPercent.length > 0
              ? reviewRatingsPercent.find((item) => item.key === "oneStar")
                  .value
              : 0}
            %
          </span>
        </li>
      </ul>

      <div className="btnReview">
        {`${totalReviewRatings} ${
          totalReviewRatings === 1 ? "review" : "reviews"
        }`}
        {isPostComment ? (
          <DialogComment
            job={job}
            giggerJob={giggerJob}
            props={props}
            setReviewRatingPercentKey={setReviewRatingPercentKey}
          />
        ) : null}
      </div>
    </>
  );
};

export default ReviewRatingPercent;
