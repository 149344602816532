import React from "react";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
export const YoutubeLink = ({ openLink, link, title }) => {
  return (
    <div className="link" onClick={() => openLink(link)}>
      <YouTubeIcon style={{ color: "#f4666c" }} />
      <p>{title}</p>
    </div>
  );
};
