import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { Fragment, useState } from "react";
import { SORT_JOB_BROWSING } from "../config/config";
import ArrowIcon from "./Components/ArrowIcon";
import DropdownItem from "./Components/DropdownItem";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    padding: 0,
  },
}));

const listJobOptions = [
  SORT_JOB_BROWSING.POSTED_DATE,
  //SORT_JOB_BROWSING.JOB_TYPE,
  SORT_JOB_BROWSING.PAYOUT,
  SORT_JOB_BROWSING.LOCATION,
  //SORT_JOB_BROWSING.POSTED_DATE,
  SORT_JOB_BROWSING.START_DATE,
];

const gigCategoryOptions = [
  SORT_JOB_BROWSING.DEFAULT_VALUE,
  SORT_JOB_BROWSING.PAYOUT,
  SORT_JOB_BROWSING.LOCATION,
  SORT_JOB_BROWSING.POSTED_DATE,
  SORT_JOB_BROWSING.START_DATE,
];

const Sort = (props) => {
  const { sortByField, isListJob } = props;
  const [isUpward, setIsUpward] = useState(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, option) => {
    setSelectedIndex(index);
    sortByField(option);
    setAnchorEl(null);
    if (isUpward === null) {
      setIsUpward(true);
    } else {
      setIsUpward(!isUpward);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <List component="nav" style={{ padding: 0 }}>
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
        >
          <Fragment>
            <ListItemText
              secondary={
                isListJob
                  ? listJobOptions[selectedIndex]
                  : gigCategoryOptions[selectedIndex]
              }
            />
            {listJobOptions[selectedIndex] !==
              SORT_JOB_BROWSING.DEFAULT_VALUE && (
              <ArrowIcon isUpward={isUpward} />
            )}
          </Fragment>
          <ExpandMoreIcon style={{ marginLeft: 7, opacity: 0.6 }} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ padding: 0 }}
      >
        {isListJob
          ? listJobOptions.map((option, index) => (
              <DropdownItem
                key={option}
                option={option}
                index={index}
                selectedIndex={selectedIndex}
                isUpward={isUpward}
                handleMenuItemClick={handleMenuItemClick}
              />
            ))
          : null}
        {/* gigCategoryOptions.map((option, index) => (
              <DropdownItem
                key={option}
                option={option}
                index={index}
                selectedIndex={selectedIndex}
                isUpward={isUpward}
                handleMenuItemClick={handleMenuItemClick}
              />
            ))} */}
      </Menu>
    </div>
  );
};

export default Sort;
