import React, { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { POST_GIG_FORM } from "../../config/config";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
export default function DialogComponent({
  title,
  message,
  openState,
  handleClose,
  showEdit,
}) {
  return (
    <Fragment>
      <Dialog
        open={openState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {showEdit ? (
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Click <EditRoundedIcon style={{ top: 6, position: 'relative' }} /> To Edit
              </DialogContentText>
            </DialogContent>
          </Fragment>
        ) : (
            <Fragment>
              <DialogTitle id="alert-dialog-title">{title && title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message && message}
                </DialogContentText>
              </DialogContent>
            </Fragment>
          )}

        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
