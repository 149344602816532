import { Container, TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Sticky from "react-sticky-el";
import {
  API,
  FORMAT,
  GIGGER_TYPE_CONST,
  LOCAL_STORAGE_KEY,
  PROFILE,
  WEBVIEW_ACTION,
} from "../config/config";
import ListJob from "../containers/ListJob";
import { sendMessage, setAxiosToken } from "../ultils/ultils";
import DarkMode from "./DarkMode/DarkMode";
import JobBrowsingDialog from "./JobBrowsing/JobBrowsingDialog";
import Sort from "./Sort";
import { GATracking, initGA } from "./Tracking/GATracking";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const JobBrowsing = (props) => {
  const classes = useStyles();
  const [filterData, setFilterData] = useState(null);
  const [areas, setAreas] = useState([]);
  const [open, setOpen] = useState(false);
  const [jobCategories, setJobCategories] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isGigger, setIsGigger] = useState(false);
  const [haveToken, setHaveToken] = useState(false);
  const [onForeGround, setOnForeground] = useState(true);
  const [updateFilter, setUpdateFilter] = useState(false);
  // Toggle Dark Mode
  const [darkState, setDarkState] = useState(null);
  const [onTop, setOnTop] = useState(true);
  window.onscroll = function () {
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop === 0) {
      setOnTop(true);
    } else {
      setOnTop(false);
    }
  };
  useEffect(() => {
    if (open) {
      sendMessage({
        action: WEBVIEW_ACTION.DISABLE_REFRESH,
      });
    } else {
      sendMessage({
        action: WEBVIEW_ACTION.SET_ENABLE_REFRESH,
        isEnable: onTop,
      });
    }
  }, [onTop, open]);
  const _onMessage = (data) => {
    if (data.token) {
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, data.token);
      localStorage.setItem(LOCAL_STORAGE_KEY.CURRENT_ID, data.currentId);
      setHaveToken(true);
    }
  };
  const _onToggle = (data) => {
    setDarkState(data.darkMode);
  };
  const _onForeGround = (data) => {
    setOnForeground(true);
  };
  const _onUpdateFilter = () => {
    //alert("_onUpdateFilter");
    setUpdateFilter(true);
  };
  const _onCloseModalGigs = () => {
    handleClose();
  };
  const event = new Event("WebViewBridge");
  window.dispatchEvent(event);
  useEffect(() => {
    window.WebViewBridge = {
      on_gigs: _onMessage,
      toggle_darkmode: _onToggle,
      on_foreground: _onForeGround,
      on_update_filter: _onUpdateFilter,
      on_close_modal_gigs: _onCloseModalGigs,
    };
    //alert(JSON.stringify(event));
  }, []);
  useEffect(() => {
    //alert(localStorage.token);
    const fetchMasterFile = async () => {
      setAxiosToken(localStorage[LOCAL_STORAGE_KEY.TOKEN]);
      const getGigger = await axios.post(API.GET_GIGGER_BY_TOKEN, {
        token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
      });
      if (getGigger.data) {
        let giggerValue = parseInt(
          PROFILE.GIGER_TYPE.find(
            (type) =>
              type.label.toLowerCase() ===
              GIGGER_TYPE_CONST.GIGGER.toLowerCase()
          )?.value
        );
        setIsGigger(giggerValue === getGigger.data.Type);
      }
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      setAreas(getAllAreas.data);
      const getAllJobCategories = await axios.get(API.GET_ALL_JOB_CATEGORIES);
      setJobCategories(getAllJobCategories.data);
    };
    const fetchNewFilter = async () => {
      const getGiggerFilterByGiggerIdResult = await axios.post(
        API.GET_GIGGER_FILTER_BY_GIGGER_ID,
        {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        }
      );
      if (
        getGiggerFilterByGiggerIdResult.data &&
        Object.keys(getGiggerFilterByGiggerIdResult.data.filterValue).length > 0
      ) {
        let filterValue = getGiggerFilterByGiggerIdResult.data.filterValue;
        setFilterData({
          areas: filterValue.areas,
          jobCategories: filterValue.jobCategories,
          dateFrom: filterValue.dateFrom,
          dateTo: filterValue.dateTo,
        });
      }
    };
    if (onForeGround) {
      fetchMasterFile();
      setOnForeground(false);
    }
    if (updateFilter) {
      fetchNewFilter();
      setUpdateFilter(false);
    }
  }, [onForeGround, updateFilter]);

  const handleDelete = async (e, filterDataToDelete, idToDelete) => {
    console.info("You clicked the delete icon.");

    let updatedFilterData = {
      areas: filterData.areas,
      jobCategories: filterData.jobCategories,
      dateFrom: filterData.dateFrom,
      dateTo: filterData.dateTo,
    };
    if (filterDataToDelete === "area") {
      let indexOfArea = filterData.areas.indexOf(idToDelete);
      let newAreasFilter = filterData.areas;
      newAreasFilter.splice(indexOfArea, 1);
      updatedFilterData.areas = newAreasFilter;
    }

    if (filterDataToDelete === "jobCategory") {
      let indexOfJobCategories = filterData.jobCategories.indexOf(idToDelete);
      let newJobCategoriesFilter = filterData.jobCategories;
      newJobCategoriesFilter.splice(indexOfJobCategories, 1);
      updatedFilterData.jobCategories = newJobCategoriesFilter;
    }

    if (filterDataToDelete === "date") {
      updatedFilterData.dateFrom = null;
      updatedFilterData.dateTo = null;
    }

    // try {
    //   if (localStorage[LOCAL_STORAGE_KEY.TOKEN]) {
    //     const result = await axios.post(API.UPDATE_GIGGER_FILTER, {
    //       filterData: updatedFilterData,
    //       token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
    //     });
    //   }
    // } catch (error) {}
    setFilterData(updatedFilterData);
  };

  const handleClick = (e) => {
    console.info("You clicked the Chip.");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterJobsApply = (data) => {
    //alert(JSON.stringify(data));
    setFilterData(data);
    console.log("test filter");
  };

  const handleSortByField = (sortByField) => {
    console.log(sortByField);
    setSortBy(sortByField);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <DarkMode darkState={darkState}>
      <Helmet>{initGA()}</Helmet>
      <Sticky>
        <div className="stickysearch">
          <div className="seachInput">
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search"
              onChange={(e) => handleSearchTextChange(e)}
              className="txtSearch"
              InputProps={{
                startAdornment: <SearchIcon style={{ color: "#a2a2a2" }} />,
              }}
            />
          </div>
          <div className="sort">
            <div style={{ display: "flex", alignItems: "center" }}>
              Sort by:{" "}
              <Sort
                isListJob={true}
                sortByField={handleSortByField}
                style={{ padding: 0 }}
              />
            </div>
            <div className="icon">
              <IconButton
                color="primary"
                component="span"
                onClick={handleClickOpen}
              >
                <TuneIcon />
              </IconButton>
            </div>
          </div>
          {filterData ? (
            <div className="chip">
              <div className={classes.root}>
                {filterData.areas && filterData.areas.length > 0
                  ? filterData.areas.map((area) => {
                      let areaDescription = areas.find(
                        (item) => item.Id === area
                      )?.Name;
                      return (
                        <Chip
                          size="small"
                          color="primary"
                          icon={<LocationOnIcon />}
                          label={`${areaDescription}`}
                          onDelete={(e) => handleDelete(e, "area", area)}
                        />
                      );
                    })
                  : null}
                {filterData.jobCategories && filterData.jobCategories.length > 0
                  ? filterData.jobCategories.map((jobCategory) => {
                      let jobCategoryDescription = jobCategories.find(
                        (item) => item.Id === jobCategory
                      )?.Name;
                      return (
                        <Chip
                          size="small"
                          color="primary"
                          icon={<LocalOfferIcon />}
                          label={`${jobCategoryDescription}`}
                          onDelete={(e) =>
                            handleDelete(e, "jobCategory", jobCategory)
                          }
                        />
                      );
                    })
                  : null}
                {filterData.dateFrom && !filterData.dateTo ? (
                  <Chip
                    size="small"
                    color="primary"
                    icon={<EventIcon />}
                    label={`${moment(filterData.dateFrom).format(
                      FORMAT.DATE_TIME_CLIENT
                    )}`}
                    onDelete={(e) => handleDelete(e, "date")}
                  />
                ) : null}
                {filterData.dateFrom && filterData.dateTo ? (
                  <Chip
                    size="small"
                    color="primary"
                    icon={<EventIcon />}
                    label={`${moment(filterData.dateFrom).format(
                      FORMAT.DATE_TIME_CLIENT
                    )} to ${moment(filterData.dateTo).format(
                      FORMAT.DATE_TIME_CLIENT
                    )}`}
                    onDelete={(e) => handleDelete(e, "date")}
                  />
                ) : null}
                {!filterData.dateFrom && filterData.dateTo ? (
                  <Chip
                    size="small"
                    color="primary"
                    icon={<EventIcon />}
                    label={`${moment(filterData.dateTo).format(
                      FORMAT.DATE_TIME_CLIENT
                    )}`}
                    onDelete={(e) => handleDelete(e, "date")}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </Sticky>

      <Container maxWidth="sm" className="formPostjob">
        <ListJob
          searchText={searchText}
          sortBy={sortBy}
          filterData={filterData}
          onForeGround={onForeGround}
        />
        {/* {localStorage.token && isGigger ? (
          <>
            <h6 style={{ marginTop: 20 }}>Followed Jobs</h6>
            <ListJob
              searchText={searchText}
              sortBy={sortBy}
              history={props.history}
              isListFollowedJobs={true}
            />
          </>
        ) : null} */}
      </Container>

      <JobBrowsingDialog
        openDialog={open}
        handleDialogClose={handleClose}
        onFilterJobsApply={handleFilterJobsApply}
        existingFilterData={filterData}
      />
    </DarkMode>
  );
};

export default JobBrowsing;
