import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Rating from "react-rating";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import { Fragment } from "react";
import {
  API,
  CLIENT_DOMAIN,
  DOMAIN,
  LOCAL_STORAGE_KEY,
} from "../../config/config";
import axios from "axios";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function ReviewsGigs({ data }) {
  const classes = useStyles();
  let resetValue = 0;
  const [open, setOpen] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [index, setIndex] = useState(0);
  const [ratingValue, setRatingValue] = useState(0);
  const [showAlert, setShowAlert] = useState(0);
  const [comment, setComment] = useState("");
  const token = localStorage[LOCAL_STORAGE_KEY.TOKEN];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const updateRatedStatus = async (Id) => {
    try {
      console.log({ completedId: Id });
      await axios.post(API.UPDATE_RATED_GIG, { completedId: Id });
    } catch (error) {
      console.log({ updateRatedStatusError: error });
    }
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
    if (index > data.length - 1) {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (open && !showAlert && index > data.length - 1) {
      console.log("handleClose Update");
      setOpen(false);
      setTimeout(() => {
        setIndex(resetValue);
      }, 100);
      resetReviewContent();
    }
  }, [index]);
  const handleClose = async () => {
    await updateRatedStatus(data[index].completedId);
    console.log({ completedId: data[index].completedId });
    nextIndex();
  };
  const nextIndex = () => {
    let newIndex = index + 1;
    setIndex(newIndex);
  };

  const resetReviewContent = () => {
    setRatingValue(resetValue);
    setComment("");
  };
  const handleSubmit = async () => {
    try {
      if (index <= data.length - 1 && open) {
        await updateRatedStatus(data[index].completedId);
        // Call API
        if (ratingValue > 0) {
          console.log({ ratingValue, comment });
          // const testToken =
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjA0MzkzNDY1LCJleHAiOjUyMDQzOTM0NjV9.y_l4dePK_KPQSdluWrGcnRp4vrUJb2gvav2_2pRSEFE";
          await axios.post(API.INSERT_GIGGER_REVIEW_RATING, {
            jobId: data[index].jobId,
            revieweeId: data[index].GigBossId,
            token: token,
            comment: comment,
            rating: ratingValue,
            isGigBossReviewGigger: false,
          });
          setShowAlert(true);
          resetReviewContent();
        }
        nextIndex();
      }
    } catch (error) {
      console.log({ ReviewsGigsError: error });
    }
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
    <React.Fragment>
      <Fragment>
        {open && (
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            className={"dialogReviewGigs"}
            onClose={showAlert ? handleCloseAlert : handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            {/* "/static/images/avatar/1.jpg" */}
            {showAlert ? (
              <div className="thanks">
                <Alert className="success" severity="success"></Alert>
                <p>Thank you for reviewing!</p>
                <div>
                  <Button onClick={handleCloseAlert} color="primary">
                    Ok
                  </Button>
                </div>
              </div>
            ) : (
              data[index] && (
                <Fragment>
                  <DialogContent>
                    <Avatar
                      src={`${DOMAIN}/profile/${
                        data[index].CompanyImage
                          ? data[index].CompanyImage
                          : data[index].Image
                      }`}
                      className="avatar"
                    />
                    <p>{data[index].Title}</p>
                    <Rating
                      onChange={(value) => {
                        setRatingValue(value);
                      }}
                      emptySymbol={
                        <StarIcon
                          fontSize="large"
                          style={{ color: "#bdbdbd" }}
                        />
                      }
                      fullSymbol={
                        <StarIcon
                          fontSize="large"
                          style={{ color: "#ffb400" }}
                        />
                      }
                      initialRating={ratingValue}
                    />
                    <div className="type">
                      <TextField
                        name="description"
                        aria-label="minimum height"
                        label="Review (optional)"
                        multiline
                        onChange={(e) => setComment(e.target.value)}
                        variant="outlined"
                        className="textarea"
                      />
                    </div>
                  </DialogContent>
                  <DialogActions style={{ marginRight: 10 }}>
                    <Button onClick={handleClose} style={{ opacity: 0.5 }}>
                      Cancel
                    </Button>
                    <Button
                      disabled={!ratingValue > 0}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Fragment>
              )
            )}
          </Dialog>
        )}
      </Fragment>
    </React.Fragment>
  );
}
