import { AMOUNTOFPAY } from "../config/config";

const getPayByDescription = (payBy, salary) => {
  let payByDescription = "";
  if (payBy === AMOUNTOFPAY.HOURLY) {
    payByDescription = `Hourly $${salary} per hour`;
  } else if (payBy === AMOUNTOFPAY.FLAT_FEE) {
    payByDescription = `Flat fee $${salary}`;
  } else if (payBy === AMOUNTOFPAY.DAILY) {
    payByDescription = `Daily $${salary} per day`;
  } else if (payBy === AMOUNTOFPAY.MONTHLY) {
    payByDescription = `Monthly $${salary} per month`;
  } else {
    payByDescription = "";
  }
  return payByDescription;
};

export { getPayByDescription };
