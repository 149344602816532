import React, { useState, Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { SliderPhotos } from "./SliderPhotos";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(2, 3, 3),
  },
}));
const alertClick = () => {
  //alert("Clickedd");
};
export default function ModalSliderPhotos({ open, handleClose, photos }) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogPhotos"
      >
        <SliderPhotos photos={photos} />
      
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className="close"
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
      </Dialog>
    </div>
  );
}

// import React from "react";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import { SliderPhotos } from "./SliderPhotos";
// import CloseIcon from "@material-ui/icons/Close";
// import IconButton from "@material-ui/core/IconButton";

// export default function AlertDialog(photos) {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//         Open alert dialog
//       </Button>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//         className="dialogPhotos"
//       >
//         <SliderPhotos photos={photos} />
//         <Button onClick={handleClose} color="primary" autoFocus></Button>
//         <IconButton
//           edge="start"
//           color="inherit"
//           onClick={handleClose}
//           aria-label="close"
//           className="close"
//         >
//           <CloseIcon style={{ color: "#fff" }} />
//         </IconButton>
//       </Dialog>
//     </div>
//   );
// }
