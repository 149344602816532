import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  List,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { withStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  API,
  LOCAL_STORAGE_KEY,
  FORMAT,
  BUTTON_TEXT,
  WEBVIEW_ACTION,
} from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import JobBrowsingFilterDialog from "./JobBrowsingFilterDialog";

const ExpansionPanel = withStyles({
  root: {
    marginBottom: -1,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 36,
    "&$expanded": {
      minHeight: 36,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const JobBrowsingDialog = (props) => {
  const {
    isFilterByJobCategoryId,
    openDialog,
    existingFilterData,
    handleDialogClose,
    onFilterJobsApply,
    fromProfile,
  } = props;

  const [areas, setAreas] = useState([]);
  const [areaExamples, setAreaExamples] = useState([]);
  const [areaValues, setAreaValues] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobCategoryValues, setJobCategoryValues] = useState([]);
  const [formData, setFormData] = useState({
    areas: [],
    jobCategories: [],
    dateFrom: null,
    dateTo: null,
  });
  if (openDialog) {
  }
  const [areaInputValue, setAreaInputValue] = useState("");
  const [jobCategoryInputValue, setJobCategoryInputValue] = useState("");
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [keyReset, setKeyReset] = useState(0);

  //#region useEffect

  useEffect(() => {
    const fetchData = async () => {
      console.log(localStorage);

      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      getAllAreas.data.forEach((area) => {
        setAreas((oldArr) => [
          ...oldArr,
          {
            value: area.Id,
            label: area.Name,
          },
        ]);
      });
      getAllAreas.data.forEach((area) => {
        setAreaValues((oldArr) => [...oldArr, area.Id]);
      });

      const getAllJobCategoriesResults = await axios.get(
        API.GET_ALL_JOB_CATEGORIES
      );
      getAllJobCategoriesResults.data.forEach((jobCategory) => {
        setJobCategories((oldArr) => [
          ...oldArr,
          {
            value: jobCategory.Id,
            label: jobCategory.Name,
          },
        ]);
      });
      getAllJobCategoriesResults.data.forEach((jobCategory) => {
        setJobCategoryValues((oldArr) => [...oldArr, jobCategory.Id]);
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (openDialog) {
      if (fromProfile) {
        sendMessage({ action: WEBVIEW_ACTION.ON_OPEN_MODAL_PROFILE });
      } else if (isFilterByJobCategoryId) {
        sendMessage({ action: WEBVIEW_ACTION.ON_OPEN_MODAL_CATEGORY });
      } else {
        sendMessage({ action: WEBVIEW_ACTION.ON_OPEN_MODAL_GIG });
      }
    }
  }, [openDialog]);
  useEffect(() => {
    if (existingFilterData) {
      setFormData({
        areas: existingFilterData.areas,
        jobCategories: existingFilterData.jobCategories,
        dateFrom: existingFilterData.dateFrom,
        dateTo: existingFilterData.dateTo,
      });
    }
  }, [existingFilterData]);

  //#endregion

  //#region Actions

  const handleClose = () => {
    // Send message to App
    if (fromProfile) {
      sendMessage({
        action: WEBVIEW_ACTION.ON_OPEN_MODAL_PROFILE,
        closeModal: true,
      });
    } else if (isFilterByJobCategoryId) {
      sendMessage({
        action: WEBVIEW_ACTION.ON_OPEN_MODAL_CATEGORY,
        closeModal: true,
      });
    } else {
      sendMessage({
        action: WEBVIEW_ACTION.ON_OPEN_MODAL_GIG,
        closeModal: true,
      });
    }
    handleDialogClose();
  };
  const resetKey = () => {
    let newKey = keyReset + 1;
    setKeyReset(newKey);
  };
  const handleSave = async () => {
    const save = async () => {
      if (!isFilterByJobCategoryId && localStorage[LOCAL_STORAGE_KEY.TOKEN]) {
        if (fromProfile) {
          saveGigAlertFromProfile();
        } else {
          setOpenFilterDialog(true);
        }
      } else {
        handleDialogClose();
      }
      onFilterJobsApply(formData);
    };

    save();
  };
  const saveGigAlertFromProfile = async () => {
    try {
      //console.log(formData);
      const result = await axios.post(API.UPDATE_GIGGER_BY_TOKEN, {
        token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        isReceivedJobNotification: 1,
      });

      const getGiggerFilterByGiggerId = await axios.post(
        API.GET_GIGGER_FILTER_BY_GIGGER_ID,
        {
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        }
      );
      if (
        Object.keys(getGiggerFilterByGiggerId.data.filterValue).length === 0
      ) {
        await axios.post(API.INSERT_GIGGER_FILTER, {
          filterData: formData,
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        });
      } else {
        await axios.post(API.UPDATE_GIGGER_FILTER, {
          filterData: formData,
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        });
      }
      sendMessage({ action: WEBVIEW_ACTION.ON_UPDATE_FILTER });
      setTimeout(() => {
        sendMessage({ action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW });
      }, 200);
      handleDialogClose();
    } catch (error) {}
  };
  const handleAreaChange = (event, newValue) => {
    if (newValue) {
      setFormData({
        ...formData,
        areas: newValue,
      });
      //setSelectedAreas(newValue);
    }
  };

  const handleJobCategoryChange = (event, newValue) => {
    if (newValue) {
      setFormData({
        ...formData,
        jobCategories: newValue,
      });
    }
  };

  const handleDateFromPickerChange = (e) => {
    // let isValidDate = moment(selectedDate).isValid();

    // if (isValidDate) {
    //   setFormData({ ...formData, dateFrom: selectedDate.toISOString() });
    // }
    setFormData({ ...formData, dateFrom: e.target.value });
  };

  const handleDateToPickerChange = (e) => {
    // let isValidDate = moment(selectedDate).isValid();

    // if (isValidDate) {
    //   setFormData({ ...formData, dateTo: selectedDate.toISOString() });
    // }
    setFormData({ ...formData, dateTo: e.target.value });
  };

  const handleClearAllClick = () => {
    setFormData({
      areas: [],
      jobCategories: [],
      dateFrom: null,
      dateTo: null,
      areaDescription: [],
      jobCategoryDescription: [],
    });
    resetKey();
  };

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
  };

  //#endregion

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ maxWidth: 600, margin: "auto" }}
    >
      <AppBar className="appBar" style={{ maxWidth: 600, right: "auto" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className="title">
            Filter
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            {BUTTON_TEXT.SAVE}
          </Button>
        </Toolbar>
      </AppBar>

      <List className="listFilter" style={{ marginTop: 48 }}>
        <ExpansionPanel defaultExpanded className="ExpansionPanel">
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="cardHeader"
          >
            <Typography className="fwbold">Area</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="cardcontent">
            <Grid item xs={12}>
              <Autocomplete
                id="Area"
                multiple
                limitTags={2}
                options={areaValues}
                style={{ width: "100%" }}
                onChange={handleAreaChange}
                clearOnBlur={true}
                disableClearable={true}
                getOptionLabel={(option) => {
                  var description = areas.find((area) => area.value === option)
                    ?.label;
                  if (description && description !== "") {
                    return description;
                  }
                  return "";
                }}
                disableCloseOnSelect={true}
                value={formData.areas}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {areas.find((area) => area.value === option)?.label}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Area" variant="outlined" />
                )}
                inputValue={areaInputValue}
                onInputChange={(event, newInputValue) => {
                  setAreaInputValue(newInputValue);
                  if (newInputValue === "") {
                    setFormData({
                      ...formData,
                      areas: formData.areas,
                    });
                  }
                }}
              />
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {!isFilterByJobCategoryId ? (
          <ExpansionPanel defaultExpanded className="ExpansionPanel">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className="cardHeader"
            >
              <Typography className="fwbold">Gig Type</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="cardcontent">
              <Autocomplete
                id="JobType"
                multiple
                limitTags={2}
                options={jobCategoryValues}
                style={{ width: "100%" }}
                onChange={handleJobCategoryChange}
                clearOnBlur={true}
                disableClearable={true}
                getOptionLabel={(option) => {
                  var description = jobCategories.find(
                    (jobCategory) => jobCategory.value === option
                  )?.label;
                  if (description && description !== "") {
                    return description;
                  }
                  return "";
                }}
                disableCloseOnSelect={true}
                value={formData.jobCategories}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {
                      jobCategories.find(
                        (jobCategory) => jobCategory.value === option
                      )?.label
                    }
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Gig Type" variant="outlined" />
                )}
                inputValue={jobCategoryInputValue}
                onInputChange={(event, newInputValue) => {
                  setJobCategoryInputValue(newInputValue);
                  if (newInputValue === "") {
                    setFormData({
                      ...formData,
                      jobCategories: formData.jobCategories,
                    });
                  }
                }}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : null}
        <ExpansionPanel defaultExpanded className="ExpansionPanel">
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="cardHeader"
          >
            <Typography className="fwbold">Date Range</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="cardcontent">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <label>Date From</label>
                {/* <KeyboardDatePicker
                  style={{ width: "100%" }}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format={FORMAT.DATE_TIME_CLIENT}
                  margin="normal"
                  id="dateFrom"
                  value={formData.dateFrom}
                  autoOk={true}
                  placeholder={FORMAT.DATE_TIME_CLIENT}
                  onChange={(date) => handleDateFromPickerChange(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{ readOnly: true }}
                /> */}
                <TextField
                  id="date"
                  // label="Date"
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  variant="outlined"
                  name="Date"
                  value={
                    formData.dateFrom &&
                    moment(formData.dateFrom).format(FORMAT.DATE_TIME_SERVER)
                  }
                  // placeholder={FORMAT.DATE_TIME_CLIENT}
                  onChange={(e) => handleDateFromPickerChange(e)}
                  className="TextField"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  key={keyReset}
                  // key={keyReset}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <label>Date To</label>
                {/* <KeyboardDatePicker
                  style={{ width: "100%" }}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format={FORMAT.DATE_TIME_CLIENT}
                  margin="normal"
                  id="dateTo"
                  value={formData.dateTo}
                  autoOk={true}
                  placeholder={FORMAT.DATE_TIME_CLIENT}
                  onChange={(date) => handleDateToPickerChange(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{ readOnly: true }}
                /> */}
                <TextField
                  id="date"
                  // label="Date"
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  variant="outlined"
                  name="Date"
                  value={
                    formData.dateTo &&
                    moment(formData.dateTo).format(FORMAT.DATE_TIME_SERVER)
                  }
                  // placeholder={FORMAT.DATE_TIME_CLIENT}
                  onChange={(e) => handleDateToPickerChange(e)}
                  className="TextField"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  key={keyReset}
                  // key={keyReset}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div className="btnClear">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearAllClick}
          >
            Clear all
          </Button>
        </div>
      </List>

      <JobBrowsingFilterDialog
        openDialog={openFilterDialog}
        closeDialog={handleCloseFilterDialog}
        closeJobBrowsingDialog={handleClose}
        filterData={{
          areas: formData.areas,
          jobCategories: formData.jobCategories,
          dateFrom: formData.dateFrom,
          dateTo: formData.dateTo,
        }}
      />
    </Dialog>
  );
};

export default JobBrowsingDialog;
