import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState } from "react";
import axios from "axios";
import {
  MESSAGE,
  BUTTON_TEXT,
  API,
  LOCAL_STORAGE_KEY,
} from "../../config/config";
import { Button } from "@material-ui/core";
import NotificationDialog from "./NotificationDialog";

export default function ReportModal({ openDialog, toggleModal, reportGigger }) {
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleClickYes = async () => {
    const isSuccess = await reportGigger();
    if (isSuccess) {
      setShowNotificationDialog(true);
    } else {
      setIsError(true);
    }
  };
  const toggleNotificationDialog = () => {
    toggleModal();
    setShowNotificationDialog(!showNotificationDialog);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="alert-dialog-title">
          <div style={{ fontSize: 15 }}>
            {MESSAGE.REPORT_USER_CONFIRM_MESSAGE}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickYes} color="primary" autoFocus>
            {BUTTON_TEXT.YES}
          </Button>
          <Button onClick={toggleModal} color="primary">
            {BUTTON_TEXT.NO}
          </Button>
        </DialogActions>
      </Dialog>

      {showNotificationDialog && (
        <NotificationDialog
          openDialog={showNotificationDialog}
          toggleNotificationDialog={toggleNotificationDialog}
          isError={isError}
          notificationMessage={
            isError
              ? MESSAGE.REPORT_USER_NOTIFICATION_ERROR_MESSAGE
              : MESSAGE.REPORT_USER_NOTIFICATION_MESSAGE
          }
        />
      )}
    </div>
  );
}
