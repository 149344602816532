import React, { useState, useEffect } from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
export default function SocialComponent({ handleClickSocial, profile }) {
  const instagramDomain = "https://instagram.com/";
  const facebookDomain = "https://facebook.com/";
  const twitterDomain = "https://twitter.com/";
  const replaceHTTP = (url) => {
    let http = "http://";
    let https = "https://";
    if (url.includes(http)) {
      url = url.replace(http, "");
    } else if (url.includes(https)) {
      url = url.replace(https, "");
    }
    return url;
  };
  const getSocialName = (socialLink, socialDomain) => {
    if (socialLink.includes(socialDomain)) {
      socialLink = socialLink.replace(socialDomain, "");
    } else {
      socialLink = replaceHTTP(socialLink);
    }
    return socialLink;
  };
  const getSocialLink = (profileSocial, socialDomain) => {
    console.log(`${socialDomain}${profileSocial}`);
    return `${socialDomain}${profileSocial}`;
  };
  return (
    <div className="form-group form-flex">
      {/*onClick={isReadOnly ? handleOpenEditModal : null} */}
      <label>Social Media/Website</label>
      <div className="social">
        {profile.InstagramLink && (
          <p
            onClick={() =>
              handleClickSocial(
                getSocialLink(profile.InstagramLink, instagramDomain)
              )
            }
          >
            <img src={require("../../images/instagram.svg")} />
            <span>{getSocialName(profile.InstagramLink, instagramDomain)}</span>
          </p>
        )}
        {profile.TwitterLink && (
          <p
            onClick={() =>
              handleClickSocial(
                getSocialLink(profile.TwitterLink, twitterDomain)
              )
            }
          >
            <TwitterIcon style={{ color: "#1DA1F2" }} />{" "}
            <span>{getSocialName(profile.TwitterLink, twitterDomain)}</span>
          </p>
        )}
        {profile.FacebookLink && (
          <p
            onClick={() =>
              handleClickSocial(
                getSocialLink(profile.FacebookLink, facebookDomain)
              )
            }
          >
            <FacebookIcon style={{ color: "#4267B2" }} />{" "}
            <span>{getSocialName(profile.FacebookLink, facebookDomain)}</span>
          </p>
        )}
        {profile.WebsiteLink && (
          <p onClick={() => handleClickSocial(profile.WebsiteLink)}>
            <LanguageIcon /> <span>Web</span>
          </p>
        )}
      </div>
    </div>
  );
}
