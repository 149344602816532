import React, { Fragment, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GigItem from "./Items/GigItem";
import { useEffect } from "react";
import axios from "axios";
import {
  LOCAL_STORAGE_KEY,
  API,
  JOB_STATUS,
  WEBVIEW_ACTION,
  MY_GIGS_VALUE,
  BUTTON_TEXT,
} from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
export default function UpComingGigs({ areas }) {
  const [giggerId, setGiggerId] = useState(
    localStorage[LOCAL_STORAGE_KEY.CURRENT_ID]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.CURRENT_ID])
      : null
  );
  const [upComingGigs, setUpComingGigs] = useState([]);
  useEffect(() => {
    const getUpcomingGigs = async () => {
      const result = await axios.post(API.GET_ALL_JOBS_BY_GIGGER_ID, {
        giggerId,
        pageSize: 5,
        startIndex: 0,
        filterCondition: JOB_STATUS.GIG_CONFIRMED,
      });
      console.log({ upComing: result.data });
      setUpComingGigs(result.data);
    };
    if (giggerId) {
      getUpcomingGigs();
    }
  }, [giggerId]);
  const goToMyGigs = async () => {
    sendMessage({
      action: WEBVIEW_ACTION.GO_TO_MY_GIGS,
      value: MY_GIGS_VALUE.CONFIRMED,
    });
  };
  return (
    <Fragment>
      {upComingGigs.length > 0 && (
        <Fragment>
          <h2>
            My Upcoming Gigs
            <Button onClick={goToMyGigs} size="small" color="primary">
              {BUTTON_TEXT.VIEW_ALL}
            </Button>
          </h2>

          <ul className="listJob listJobscroll">
            {upComingGigs.map((item) => (
              <GigItem
                verticalList={false}
                area={areas.find((area) => area.Id === item.Area)}
                item={item}
              />
            ))}
          </ul>
        </Fragment>
      )}
    </Fragment>
  );
}
