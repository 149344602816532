import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import { BUTTON_TEXT, COMMENT_FORM } from "../../config/config";

const ReviewErrorDialog = ({ openDialog, handleClose, isContainLink }) => {
  //#region onClick

  const handleClick = () => {
    if (handleClose) {
      handleClose();
    }
  };

  //#endregion

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="dialog">
          {isContainLink
            ? COMMENT_FORM.ERROR_MESSAGE.ERROR_INPUT_URL
            : COMMENT_FORM.ERROR_MESSAGE.ERROR_INPUT_BAD_WORDS}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClick} color="primary" autoFocus>
            {BUTTON_TEXT.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReviewErrorDialog;
