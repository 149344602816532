import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import axios from "axios";
import {
  MESSAGE,
  BUTTON_TEXT,
  API,
  LOCAL_STORAGE_KEY,
  JOB_STATUS,
  WEBVIEW_ACTION,
} from "../../config/config";
import { Button } from "@material-ui/core";
import { sendMessage } from "../../ultils/ultils";

const CompleteGigConfirmDialog = (props) => {
  const {
    openDialog,
    handleCloseDialog,
    jobId,
    applications,
    setSelectedApplicationsAfterUpdate,
    refreshPage,
  } = props;

  const handleYesClick = () => {
    const yesClick = async () => {
      try {
        let data = {
          jobId: jobId,
          dataToUpdate: {
            Status: JOB_STATUS.GIG_COMPLETED,
            AcceptApplications: 0,
          },
          token: localStorage[LOCAL_STORAGE_KEY.TOKEN],
        };

        const jobResult = await axios.post(API.UPDATE_JOB, data);
        const jobError = jobResult.data.error;

        let applicationsToUpdate = [];
        applications.map((application) => {
          if (
            application.currentJobStatus === JOB_STATUS.GIG_COMPLETED ||
            application.currentJobStatus === JOB_STATUS.GIG_REJECTED
          )
            return application;

          let status = "";
          switch (application.currentJobStatus) {
            case JOB_STATUS.GIG_APPLIED:
              status = JOB_STATUS.GIG_REJECTED;
              break;
            case JOB_STATUS.GIG_CONFIRMED:
              status = JOB_STATUS.GIG_COMPLETED;
              break;
            // case JOB_STATUS.GIG_COMPLETED:
            //   status = JOB_STATUS.GIG_COMPLETED;
            //   break;
            // case JOB_STATUS.GIG_REJECTED:
            //   status = JOB_STATUS.GIG_REJECTED;
            //   break;
            default:
              break;
          }
          applicationsToUpdate.push({
            giggerId: application.giggerId,
            jobId: jobId,
            status: status,
          });
        });

        const giggerJobResult = await axios.post(API.UPDATE_GIGGER_JOB, {
          applications: applicationsToUpdate,
        });
        // FETCH DATA AGAIN ON MY GIGS TAB
        sendMessage({
          action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
        });
        const giggerJobError = giggerJobResult.data.error;
        if (!giggerJobError && !jobError) {
          if (setSelectedApplicationsAfterUpdate) {
            if (giggerJobResult.data.giggerIds.length > 0) {
              setSelectedApplicationsAfterUpdate(
                giggerJobResult.data.giggerIds
              );
            }
          }

          if (handleCloseDialog) {
            handleCloseDialog();
          }

          if (refreshPage) {
            refreshPage();
          }
        }
      } catch (error) {}
    };

    yesClick();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="alert-dialog-title">
          <div style={{ fontSize: 15 }}>
            {MESSAGE.COMPLETE_GIG_CONFIRM_MESSAGE}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYesClick} color="primary" autoFocus>
            {BUTTON_TEXT.YES_COMPLETED}
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            {BUTTON_TEXT.NO_GO_BACK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompleteGigConfirmDialog;
