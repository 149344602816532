import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { MESSAGE, BUTTON_TEXT } from "../../config/config";
import { Button } from "@material-ui/core";

const NotificationDialog = (props) => {
  const {
    openDialog,
    toggleNotificationDialog,
    notificationMessage,
    haveTitle,
    title,
  } = props;

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={toggleNotificationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {haveTitle && (
          <DialogTitle id="alert-dialog-title" className="dialog">
            {title}
          </DialogTitle>
        )}
        <DialogContent id="alert-dialog-title">
          <div style={{ fontSize: 15 }}>{notificationMessage}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleNotificationDialog} color="primary" autoFocus>
            {BUTTON_TEXT.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationDialog;
