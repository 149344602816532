export const CHECK_REDUX = "CHECK_REDUX";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const TOGGLE_DARKMODE = "TOGGLE_DARKMODE";
export const FETCH_MY_GIGS = "FETCH_MY_GIGS";
export const GET_AREAS = "GET_AREAS";
export const ON_SEND_MESSAGE = "ON_SEND_MESSAGE";
export const ADD_FOLLOWER = "ADD_FOLLOWER";
export const REMOVE_FOLLOWER = "REMOVE_FOLLOWER";
export const ON_FOREGROUND_STATUS = "ON_FOREGROUND_STATUS";
export const CHANGE_ARROW_STATUS = "CHANGE_ARROW_STATUS";
export const ON_DELETE_REVIEW = "ON_DELETE_REVIEW";
