import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { APP_VERSION, WEBVIEW_ACTION } from "../config/config";
import { sendMessage } from "../ultils/ultils";
import DarkMode from "./DarkMode/DarkMode";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({ onApp }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // Toggle Dark Mode
  const [darkState, setDarkState] = useState(null);
  // const handleOpenAbout = () => {
  //   if (onApp) {
  //     sendMessage({
  //       action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
  //       url: `${DOMAIN}About`,
  //       open: true,
  //     });
  //   } else {
  //     setOpen(true);
  //   }
  // };

  const handleCloseAbout = () => {
    sendMessage({
      action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
      open: false,
    });
  };

  return (
    <DarkMode darkState={darkState}>
      <div>
        {/* <Dialog
        className="About"
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      > */}
        <div className="About">
          <div className="content">
            <div className="close">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseAbout}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="box">
              <img src={require("../images/logo.svg")} />
              <p>Contact Us</p>
              <a className="email" href="mailto:hello@gigsomething.sg">
                hello@gigsomething.sg
              </a>
              {/* <div className="email" mailto="">
              hello@gigsomething.sg
            </div> */}
            </div>
            <div style={{ opacity: 0.6, paddingBottom: 20 }}>
              Version {APP_VERSION}{" "}
            </div>
          </div>
        </div>
        {/* </Dialog> */}
      </div>
    </DarkMode>
  );
}
