import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import NotificationsIcon from "@material-ui/icons/Notifications";
import WorkIcon from "@material-ui/icons/Work";
import { NOTIFICATION_TYPES } from "../../config/notificationTypes";
import { sendMessage } from "../../ultils/ultils";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import {
  API,
  CLIENT_DOMAIN,
  GIG_LINKS,
  LOCAL_STORAGE_KEY,
  WEBVIEW_ACTION,
} from "../../config/config";
import GigItem from "../DashboardItem/Items/GigItem";
import axios from "axios";
import DarkMode from "../DarkMode/DarkMode";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResults from "../Components/NoResults";
import { MESSAGES_CONSTANT } from "../../config/keywords";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const likedGigsTestData = [
  {
    Id: 100,
    Code: "93806",
    GigBossId: 67,
    Title: "MINIMART WAREHOUSE PACKER ",
    Location: "Kallang",
    Area: "5",
    CompanyImage: "photo-company-1599705869633.jpeg",
    Image: "photo-1599705794874.jpeg",
  },
  {
    Id: 99,
    Code: "43412",
    GigBossId: 176,
    Title: "Temp 1-2 months Admin Assistant",
    Location: "Kallang",
    Area: "5",
    CompanyImage: "photo-company-1600244917211.jpeg",
    Image: "photo-1600244795113.jpeg",
  },
  {
    Id: 98,
    Code: "3976",
    GigBossId: 217,
    Title: "Call Center Agent (Tuition Center) ",
    Location: "United square",
    Area: "1,2",
    CompanyImage: null,
    Image: "photo-1600930129431.jpeg",
  },
  {
    Id: 97,
    Code: "3976",
    GigBossId: 217,
    Title: "Call Center Agent (Tuition Center) ",
    Location: "United square",
    Area: "1,2",
    CompanyImage: null,
    Image: "photo-1600930129431.jpeg",
  },
  {
    Id: 96,
    Code: "3976",
    GigBossId: 217,
    Title: "Call Center Agent (Tuition Center) ",
    Location: "United square",
    Area: "1,2",
    CompanyImage: null,
    Image: "photo-1600930129431.jpeg",
  },
  {
    Id: 95,
    Code: "3976",
    GigBossId: 217,
    Title: "Call Center Agent (Tuition Center) ",
    Location: "United square",
    Area: "1,2",
    CompanyImage: null,
    Image: "photo-1600930129431.jpeg",
  },
  {
    Id: 94,
    Code: "3976",
    GigBossId: 217,
    Title: "Call Center Agent (Tuition Center) ",
    Location: "United square",
    Area: "1,2",
    CompanyImage: null,
    Image: "photo-1600930129431.jpeg",
  },
  {
    Id: 93,
    Code: "3976",
    GigBossId: 217,
    Title: "Call Center Agent (Tuition Center) 3 ",
    Location: "United square",
    Area: "1,2",
    CompanyImage: null,
    Image: "photo-1600930129431.jpeg",
  },
];

export default function LikedGigsComponent() {
  const classes = useStyles();
  const [areas, setAreas] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const [hasMore, setHasMore] = useState(true);
  const [darkState, setDarkState] = useState(null);
  const pageSize = 10;

  useEffect(() => {
    const fetchAreasData = async () => {
      const getAllAreas = await axios.get(API.GET_ALL_AREAS);
      console.log({ getAllAreas });
      setAreas(getAllAreas.data);
    };
    fetchAreasData();
  }, []);
  const getAllLikedGig = async () => {
    try {
      const result = await axios.post(API.GET_ALL_LIKED_GIG, {
        UserId: currentId,
        limit: pageSize,
        offset: 0,
      });
      console.log(result);
      setRenderData(result.data);
      if (result.data.length < pageSize) {
        setHasMore(false);
      }
    } catch (error) {
      console.log({ getAllLikedGigError: error });
    }
  };
  useEffect(() => {
    const fetchAllLikedGig = async () => {
      await getAllLikedGig();
    };
    fetchAllLikedGig();
  }, []);
  // toggle_liked_gig
  const _onToggleLikedGig = async () => {
    try {
      //alert("FETCH NEW GIGS");
      await getAllLikedGig();
    } catch (error) {}
  };
  useEffect(() => {
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    window.WebViewBridge = {
      toggle_liked_gig: _onToggleLikedGig,
    };
  }, []);
  const fetchMoreData = async () => {
    try {
      const result = await axios.post(API.GET_ALL_LIKED_GIG, {
        UserId: currentId,
        limit: pageSize,
        offset: renderData.length,
      });
      let newData = renderData.concat(result.data);
      setRenderData(newData);
      if (result.data < pageSize) {
        setHasMore(false);
      }
    } catch (error) {}
  };
  const handleClose = () => {
    sendMessage({
      action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
      open: false,
    });
  };
  return (
    <DarkMode darkState={darkState}>
      <div className="closeLike">
        <IconButton style={{ visibility: "hidden" }}>
          <CloseIcon />
        </IconButton>
        <h2 style={{ textAlign: "center" }}> Liked Gigs</h2>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className="listJob" style={{ marginTop: 44 }}>
        {renderData.length > 0 && areas.length > 0 ? (
          <List className="bgList">
            <InfiniteScroll
              dataLength={renderData.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<div class="loader"></div>}
            >
              {renderData.map((item) => {
                let currentAreas =
                  item.Area.length > 0 ? item.Area.split(",") : [];
                let areasName = [];
                if (currentAreas.length > 0) {
                  currentAreas.map((currentArea) => {
                    let areaName = areas.find(
                      (area) => area.Id === parseInt(currentArea)
                    )?.Name;
                    areasName.push(areaName);
                  });
                }
                return (
                  <GigItem
                    key={item.Id}
                    verticalList={false}
                    areasName={areasName.length > 0 ? areasName.join(", ") : ""}
                    item={item}
                  />
                );
              })}
            </InfiniteScroll>
          </List>
        ) : (
          <Fragment>
            <div className="empty">
              <img src={require("../../images/empty.svg")} />
              <p style={{ display: "flex", justifyContent: "center" }}>
                Click on <FavoriteBorderIcon style={{ margin: "-2px 3px 0" }} />{" "}
                to add to this list
              </p>
            </div>
          </Fragment>
        )}
      </div>
    </DarkMode>
  );
}
