import { List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  API,
  LOCAL_STORAGE_KEY,
  REVIEW_RATING_PAGE_SIZE
} from "../../config/config";
import DialogComment from "../DialogComment";
import ReviewRatingCommentItem from "./ReviewRatingCommentItem";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  }
}));

const ReviewRatingComments = ({ job, props }) => {
  //#region initial

  const classes = useStyles();
  const [reviewersInformation, setReviewersInformation] = useState([]);
  const [currentLoginUser, setCurrentLoginUser] = useState(null);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [editableCommentId, setEditableCommentId] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [reviewsToRender, setReviewsToRender] = useState([]);
  const [newReviewId, setNewReviewId] = useState(0);
  const [isReloadAfterDeleteOrEdit, setIsReloadAfterDeleteOrEdit] = useState(
    false
  );

  //#endregion

  //#region useEffect

  useEffect(() => {
    if (props && props.location.state) {
      if (props.location.state.newReviewId) {
        setNewReviewId(props.location.state.newReviewId);
      }

      if (props.location.state.isReloadAfterDeleteOrEdit) {
        setIsReloadAfterDeleteOrEdit(
          props.location.state.isReloadAfterDeleteOrEdit
        );
      }
    }
  }, [props]);

  useEffect(() => {
    const fetchData = async () => {
      const getGiggerByToken = await axios.post(API.GET_GIGGER_BY_TOKEN, {
        token: localStorage[LOCAL_STORAGE_KEY.TOKEN]
      });

      console.log(getGiggerByToken.data);
      if (getGiggerByToken.data) {
        setCurrentLoginUser(getGiggerByToken.data.Id);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const getAllReviewRatingsByGigBossId = await axios.post(
        API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID,
        {
          revieweeId: job.GigBossId,
          pageSize: REVIEW_RATING_PAGE_SIZE,
          startIndex: 0,
          isReviewsGiggerClick: false
        }
      );
      if (getAllReviewRatingsByGigBossId.data) {
        setReviewsToRender(getAllReviewRatingsByGigBossId.data.ReviewRatings);
        let reviewerIdsArr = [];
        getAllReviewRatingsByGigBossId.data.ReviewRatings.map((review) => {
          reviewerIdsArr.push(review.ReviewerId);
        });

        const getGiggersByIds = await axios.post(API.GET_GIGGERS_BY_ID, {
          giggerIds: reviewerIdsArr
        });
        setReviewersInformation(getGiggersByIds.data);

        if (
          getAllReviewRatingsByGigBossId.data.ReviewRatings.length <
          REVIEW_RATING_PAGE_SIZE
        ) {
          setHasMore(false);
        }
      } else {
        // setReviewsToRender([]);
        // setReviewersInformation([]);
        setHasMore(false);
      }
    };
    if (isReloadAfterDeleteOrEdit) {
      // alert("FETCH DATA");
      fetchData();
      setIsReloadAfterDeleteOrEdit(false);
    } else {
      if (job) {
        fetchData();
      }
    }
  }, [isReloadAfterDeleteOrEdit]);

  useEffect(() => {}, [reviewsToRender]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(API.GET_GIGGER_REVIEW_RATING_BY_ID, {
        reviewId: newReviewId
      });

      if (result.data.length > 0) {
        if (result.data[0].Type === 1) return;

        let dataToAdd = result.data[0];
        let newReviewsToRender = [...reviewsToRender];

        newReviewsToRender.splice(0, 0, dataToAdd);
        setReviewsToRender(newReviewsToRender);

        const reviewerInfo = await axios.post(API.GET_GIGGER_BY_ID, {
          giggerId: dataToAdd.ReviewerId
        });
        if (reviewerInfo.data) {
          setReviewersInformation([...reviewersInformation, reviewerInfo.data]);
        }
      }
    };

    if (newReviewId > 0) {
      fetchData();
      setNewReviewId(0);
    }
  }, [newReviewId]);

  //#endregion

  //#region onClick

  const handleOpenCommentDialog = (e, reviewId) => {
    setOpenCommentDialog(true);
    setEditableCommentId(reviewId);
  };

  //#endregion

  //#region infinite scroll

  const fetchMoreData = () => {
    const getMoreData = async () => {
      const getAllReviewRatingsByGigBossId = await axios.post(
        API.GET_ALL_GIGGER_REVIEW_RATINGS_BY_REVIEWEE_ID,
        {
          revieweeId: job.GigBossId,
          pageSize: REVIEW_RATING_PAGE_SIZE,
          startIndex: reviewsToRender.length,
          isReviewsGiggerClick: false
        }
      );

      if (getAllReviewRatingsByGigBossId.data) {
        setReviewsToRender(
          reviewsToRender.concat(
            getAllReviewRatingsByGigBossId.data.ReviewRatings
          )
        );

        let reviewerIdsArr = [];
        getAllReviewRatingsByGigBossId.data.ReviewRatings.map((review) => {
          reviewerIdsArr.push(review.ReviewerId);
        });

        const getGiggersByIds = await axios.post(API.GET_GIGGERS_BY_ID, {
          giggerIds: reviewerIdsArr
        });
        setReviewersInformation(
          reviewersInformation.concat(getGiggersByIds.data)
        );

        if (
          getAllReviewRatingsByGigBossId.data.ReviewRatings.length <
          REVIEW_RATING_PAGE_SIZE
        ) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    };

    getMoreData();
  };

  //#endregion

  return (
    <>
      {reviewsToRender.length > 0 && reviewersInformation.length > 0 ? (
        <List className="comment">
          <InfiniteScroll
            dataLength={reviewsToRender.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div style={{ textAlign: "center" }}>
                <div class="loader"></div>
              </div>
            }
          >
            {reviewsToRender.map((reviewRating) => {
              let canEdit = reviewRating.ReviewerId === currentLoginUser;
              let reviewerInformation = reviewersInformation.find(
                (reviewer) => reviewer.Id === reviewRating.ReviewerId
              );
              return (
                <ReviewRatingCommentItem
                  reviewRating={reviewRating}
                  canEdit={canEdit}
                  reviewerInformation={reviewerInformation}
                  handleOpenCommentDialog={handleOpenCommentDialog}
                  jobCode={job.Code}
                  props={props}
                />
              );
            })}
          </InfiniteScroll>
        </List>
      ) : null}
      {openCommentDialog ? (
        <DialogComment
          job={job}
          props={props}
          openCommentDialog={openCommentDialog}
          handleCloseCommentDialog={setOpenCommentDialog}
          reviewId={editableCommentId}
        />
      ) : null}
    </>
  );
};

export default ReviewRatingComments;
