import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import { MESSAGE, BUTTON_TEXT } from "../../config/config";
import { Button } from "@material-ui/core";

const ApplyNotificationDialog = (props) => {
  const { openDialog, handleCloseDialog, refreshPage } = props;

  const handleOkClick = () => {
    if (handleCloseDialog && refreshPage) {
      handleCloseDialog();
      refreshPage();
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="alert-dialog-title">
          <div style={{ fontSize: 15 }}>{MESSAGE.APPLY_NOTIFICATION}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkClick} color="primary" autoFocus>
            {BUTTON_TEXT.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApplyNotificationDialog;
