import { Container, Grid, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import React, { useState } from "react";
import {
  API,
  LOCAL_STORAGE_KEY,
  POST_GIG_FORM,
  WEBVIEW_ACTION,
} from "../config/config";
import { sendMessage, setAxiosToken, trimValue } from "../ultils/ultils";
import DialogComponent from "./Components/DialogComponent";
import DarkMode from "./DarkMode/DarkMode";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [darkState, setDarkState] = useState(null);
  const [passwordObj, setPasswordObj] = useState({});
  const [errorForm, setErrorForm] = useState(false);
  const [textError, setTextError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const token = localStorage[LOCAL_STORAGE_KEY.TOKEN];
  const handleCloseAbout = () => {
    sendMessage({
      action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
      open: false,
    });
  };
  const handlePasswordChange = (e) => {
    setPasswordObj({
      ...passwordObj,
      [e.target.name]: trimValue(e.target.value),
    });
  };
  const validateForm = (form) => {
    let isValid = false;
    if (
      form.Password.length >= 6 &&
      passwordObj.Password === passwordObj.ConfirmPassword
    ) {
      isValid = true;
    } else {
      setErrorForm(true);
      if (passwordObj.Password !== passwordObj.ConfirmPassword) {
        setTextError(POST_GIG_FORM.ERROR_MESSAGE.PASSWORD_DO_NOT_MATCH);
      } else {
        setTextError(POST_GIG_FORM.ERROR_MESSAGE.PASSWORD_IS_INVALID);
      }
    }
    return isValid;
  };
  const onSubmit = async () => {
    const isValidForm = validateForm(passwordObj);
    let data = new FormData();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    if (isValidForm) {
      setAxiosToken(token);
      data.append("Password", passwordObj.Password);
      const result = await axios.post(API.UPDATE_PROFILE, data, config);
      if (result.data) {
        clearField();
        setOpenDialog(true);
      }
    }
  };
  const clearField = () => {
    setPasswordObj({});
    setErrorForm(false);
    setTextError("");
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <DarkMode darkState={darkState}>
      <Container maxWidth="sm" className="tabs">
        <div className="ChangePassword">
          <div className="content">
            <div className="close">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseAbout}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="box">
              <img src={require("../images/logo2.svg")} />
              <img
                src={require("../images/logogigst.svg")}
                style={{ width: 310 }}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    id="filled-password-input"
                    name="Password"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={(e) => handlePasswordChange(e)}
                    className="TextField"
                    value={passwordObj.Password || ""}
                    error={errorForm}
                    helperText={errorForm && textError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="filled-password-input"
                    name="ConfirmPassword"
                    label="Confirm Password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => handlePasswordChange(e)}
                    value={passwordObj.ConfirmPassword || ""}
                    variant="outlined"
                    className="TextField"
                    error={errorForm}
                    helperText={errorForm && textError}
                  />
                </Grid>
                <div className="button">
                  <Button
                    disabled={Object.keys(passwordObj).length !== 2}
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </div>
          </div>
        </div>
        <DialogComponent
          message={POST_GIG_FORM.DIALOG_MESSAGE.PASSWORD_CHANGED}
          openState={openDialog}
          handleClose={handleClose}
        />
      </Container>
    </DarkMode>
  );
}
