import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import SaveIcon from "@material-ui/icons/Save";
import ShareIcon from "@material-ui/icons/Share";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import loadImage from "blueimp-load-image";
import moment from "moment";
import React, { Fragment, useEffect, useState, createRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Sticky from "react-sticky-el";
import Notifications from "./Notifications";
import ModalSliderPhotos from "./Sliders/Photos/ModalSliderPhotos";
import {
  API,
  BUTTON_TEXT,
  CLIENT_DOMAIN,
  compressedFile,
  DOMAIN,
  FORM_TYPES,
  LOCAL_STORAGE_KEY,
  PORTFOLIO_MESSAGE,
  PROFILE_FORM,
  PROFILE_KEYS,
  REGEX,
  REVIEW_TYPE,
  WEBVIEW_ACTION,
} from "../config/config";
import Loading from "../layout/Loading";
import { ON_FOREGROUND_STATUS } from "../store/actions/actionTypes";
import ImageTools from "../ultils/ImageTools/ImageTools.js";
import {
  openUrl,
  removePdfExt,
  sendMessage,
  setAxiosToken,
  trimValue,
  JSONParseObj,
  JSONStringifyObj,
} from "../ultils/ultils";
import DialogComponent from "./Components/DialogComponent";
import ImageComponent from "./Components/ImageComponent";
import ReportModal from "./Components/ReportModal";
import Follows from "./FollowPages/Follows";
import Gigs from "./Gigs";
import { FOLLOW_KEYWORD } from "../config/keywords";
import FollowProfile from "./FollowProfile";
import JobBrowsingDialog from "./JobBrowsing/JobBrowsingDialog";
import JobBrowsing from "./JobBrowsing";
import GigCategoriesList from "./GigCategoriesList";
import GigAlert from "./JobBrowsing/GigAlert";
import LikedGigsComponent from "./LikedGigs/LikedGigsComponent";
import ReviewsGigs from "./Reviews/ReviewsGigs";
import { Helmet } from "react-helmet";
import { initGA } from "./Tracking/GATracking";
import { DeleteDialog } from "./Components/DeleteDialog";
import { YoutubeDialog } from "./Components/YoutubeDialog";
import { Alert } from "@material-ui/lab";
export default function UserProfile(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const dispatch = useDispatch();
  const { username } = props.match.params;
  const [open, setOpen] = useState(false);
  const [openCompanyImage, setOpenCompanyImage] = useState(false);
  const [openPhoto, setOpenPhoto] = useState(false);
  const [photoLink, setPhotoLink] = useState(null);
  const currentId = localStorage[LOCAL_STORAGE_KEY.CURRENT_ID];
  const [profile, setProfile] = useState({});
  const [tempToken, setTempToken] = useState(null);
  const [profileEdit, setProfileEdit] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isFollow, setIsFollow] = useState(false);
  const [openGigFilter, setOpenGigFilter] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputLink, setInputLink] = useState("");
  const [previewPhoto, setPreviewPhoto] = useState({});
  const [previewPhotosList, setPreviewPhotoList] = useState([]);
  const [previewLinks, setPreviewLinks] = useState("");
  const [blobList, setBlobList] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [onDeletePhoto, setOnDeletePhoto] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);
  const [linkYoutube, setLinkYoutube] = useState(null);
  const [exceedPhotos, setExceedPhotos] = useState(false);
  const [exceedPdfSize, setExceedPdfSize] = useState(false);
  const [defaultSkills, setDefaultSkills] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    type: null,
    item: null,
    onModal: false,
  });
  const [loginToken, setLoginToken] = useState(false);
  const [os, setOS] = useState(null);
  const [image, setImage] = useState(null);
  const [companyImage, setCompanyImage] = useState(null);
  const [oldEmail, setOldEmail] = useState("");
  const [haveToken, setHaveToken] = useState(
    process.env.REACT_APP_TOKEN_STATUS === "development" ? true : false
  );
  const [onForeground, setOnForeground] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [onApp, setOnApp] = useState(null);
  const [onUpdate, setOnUpdate] = useState(false);
  const [phoneNumberErr, setPhoneNumberError] = useState(false);
  const [inValidUrl, setInValidUrl] = useState(false);
  const [refreshProfile, setRefreshProfile] = useState(false);
  const [reviewsGiggerAverageStars, setReviewsGiggerAverageStars] = useState(0);
  const [reviewsGigBossAverageStars, setReviewsGigBossAverageStars] = useState(
    0
  );
  const [errorObj, setErrorObj] = useState({});
  const [youtubeLinkError, setYoutubeLinkError] = useState(null);
  const [onTop, setOnTop] = useState(true);
  const [refValues, setRefValues] = useState({});
  const [alertData, setAlertData] = useState([]);
  window.onscroll = function () {
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop === 0) {
      setOnTop(true);
    } else {
      setOnTop(false);
    }
  };
  useEffect(() => {
    sendMessage({ action: WEBVIEW_ACTION.SET_ENABLE_REFRESH, isEnable: onTop });
  }, [onTop]);
  const getGigerInfo = async () => {
    try {
      let token = tempToken ? tempToken : localStorage[LOCAL_STORAGE_KEY.TOKEN];
      await setAxiosToken(token);
      const result = await axios.post(API.GET_GIGER_INFO, { username }, {});
      //console.log({ result });
      if (username && result.data) {
        sendMessage({
          action: WEBVIEW_ACTION.UPDATE_TITLE,
          title: result.data.UserName,
        });
      }
      const {
        followers,
        followings,
        SkillIds,
        Image,
        FirstName,
        Email,
        CompanyImage,
        Photos,
      } = result.data;
      setImage(Image);
      setOldEmail(Email);
      setCompanyImage(CompanyImage);
      let oldPhotosList = (Photos && Photos.split(",")) || [];
      setPreviewPhotoList([...oldPhotosList]);
      const followerList = followers.reduce((acc, cur) => {
        acc.push(cur.profileId);
        return acc;
      }, []);
      //console.log(followerList);
      const isFollower = followerList.indexOf(parseInt(currentId)) !== -1;
      setIsFollow(isFollower);

      setProfile(result.data);
    } catch (error) {
      if (error.response.status === 400) {
        if (currentId) {
          window.location.href = `${CLIENT_DOMAIN}PageNotFound/${true}`;
        } else {
          window.location.href = `${CLIENT_DOMAIN}PageNotFound`;
        }
      }
    }
  };

  // WEBVIEW MESSAGE
  const _onMessage = async (data) => {
    if (data.token && data.onApp) {
      //alert("PROFILE");
      //alert(data.onApp);
      setOS(data.os);
      setAxiosToken(data.token);
      localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, data.token);
      setTempToken(data.token);
      setOnApp(true);
      setLoginToken(true);
      setHaveToken(true);
    }
  };
  useEffect(() => {
    if (refreshProfile && isReadOnly) {
      getGigerInfo();
      setRefreshProfile(false);
    } else {
      // if (isReadOnly) {
      //   getGigerInfo();
      // }
    }
  }, [refreshProfile, isReadOnly]);

  const _onUpdateMessage = () => {
    setOnUpdate(true);
  };
  const _onForeGround = (data) => {
    if (!isReadOnly) {
      dispatch({ type: ON_FOREGROUND_STATUS });
      setOnForeground(true);
    } else {
      if (!username) {
        setRefreshProfile(true);
      }
    }
  };
  useEffect(() => {
    if (openGigFilter) {
      sendMessage({
        action: WEBVIEW_ACTION.DISABLE_REFRESH,
      });
    } else {
      sendMessage({
        action: WEBVIEW_ACTION.SET_ENABLE_REFRESH,
        isEnable: onTop,
      });
    }
  }, [onTop, openGigFilter]);
  const _onSetUpGigsAlert = () => {
    //alert("HERE");
    openGigAlert();
  };
  const _onCloseModalProfile = () => {
    closeNotificationModal();
    closeGigAlert();
  };
  const event = new Event("WebViewBridge");
  window.dispatchEvent(event);
  useEffect(() => {
    window.WebViewBridge = {
      on_profile: _onMessage,
      on_detail: _onMessage,
      on_update_profile: _onUpdateMessage,
      on_foreground: _onForeGround,
      gig_alert: _onSetUpGigsAlert,
      on_close_modal_profile: _onCloseModalProfile,
    };
  }, []);
  const getAllAlert = async () => {
    try {
      if (!username && currentId) {
        const result = await axios.post(
          API.GET_ALL_NOTIFICATION_ALERT,
          {
            UserId: currentId,
          },
          {}
        );
        setAlertData(result.data);
      }
      //testGetAlert();
    } catch (error) {
      console.log({ getAllAlertError: error });
    }
  };
  const updateCheckAlert = async (Id) => {
    try {
      const result = await axios.post(API.UPDATE_CHECK_ALERT, { Id }, {});
      setAlertData(result.data);
    } catch (error) {
      console.log({ updateCheckAlertError: error });
    }
  };
  const testGetAlert = async () => {
    try {
      const result = await axios.post(
        API.GET_ALL_NOTIFICATION_ALERT,
        {
          UserId: 1,
        },
        {}
      );
      setAlertData(result.data);
    } catch (error) {}
  };
  const testRemoveAlert = async () => {
    try {
      const result = await axios.post(
        API.REMOVE_ALL_NOTIFICATION_ALERT,
        {
          UserId: 1,
        },
        {}
      );
      setAlertData(result.data);
    } catch (error) {}
  };
  const removeAllAlert = async () => {
    try {
      if (!username && currentId) {
        const result = await axios.post(
          API.REMOVE_ALL_NOTIFICATION_ALERT,
          {
            UserId: currentId,
          },
          {}
        );
        setAlertData(result.data);
      }
      //testRemoveAlert();
    } catch (error) {
      console.log({ removeAllAlertError: error });
    }
  };

  useEffect(() => {
    if (onForeground) {
      if (haveToken || username) {
        getGigerInfo();
        // Fetch new Notification
        //getAllAlert();
        setOnForeground(false);
      }
    }
  }, [onForeground, haveToken, username]);
  // Update Profile
  useEffect(() => {
    if (onUpdate) {
      updateProfile();
    }
  }, [onUpdate]);
  // Get data for reviews
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (profile) {
          let giggerId = profile.Id;

          const getReviewsGiggerAverageStars = await axios.post(
            API.GET_REVIEWS_AVERAGE_STARS,
            { revieweeId: giggerId, isReviewsGigger: true }
          );

          if (getReviewsGiggerAverageStars.data) {
            setReviewsGiggerAverageStars(getReviewsGiggerAverageStars.data);
          }

          const getReviewsGigBossAverageStars = await axios.post(
            API.GET_REVIEWS_AVERAGE_STARS,
            { revieweeId: giggerId, isReviewsGigger: false }
          );

          if (getReviewsGigBossAverageStars.data) {
            setReviewsGigBossAverageStars(getReviewsGigBossAverageStars.data);
          }
        }
      } catch (error) {}
    };

    fetchData();
  }, [profile]);

  const setDefaultRef = () => {
    let firstNameRef = createRef();
    let lastNameRef = createRef();
    let instagramRef = createRef();
    let twitterRef = createRef();
    let facebookRef = createRef();
    let giggerWebsiteRef = createRef();
    let companyNameRef = createRef();
    let gigBossWebsiteRef = createRef();
    let contactNameRef = createRef();
    let addressRef = createRef();
    let phoneNumberRef = createRef();
    let emailRef = createRef();
    let bioRef = createRef();
    let nameRef = createRef();
    let linkRef = createRef();
    let linkTitleRef = createRef();
    setRefValues({
      FirstName: firstNameRef,
      LastName: lastNameRef,
      InstagramLink: instagramRef,
      TwitterLink: twitterRef,
      FacebookLink: facebookRef,
      WebsiteLink: giggerWebsiteRef,
      CompanyName: companyNameRef,
      Website: gigBossWebsiteRef,
      ContactName: contactNameRef,
      Address: addressRef,
      PhoneNumber: phoneNumberRef,
      Email: emailRef,
      Bio: bioRef,
      Name: nameRef,
      linkRef: linkRef,
      linkTitleRef: linkTitleRef,
    });
  };
  const handleChangeLink = (e) => {
    setInputLink(e.target.value);
  };
  useEffect(() => {
    setDefaultRef();
  }, []);

  //
  const reportGigger = async () => {
    try {
      //setIsLoading(true);
      const result = await axios.post(API.INSERT_REPORT_GIGGER, {
        currentId: parseInt(currentId) ? parseInt(currentId) : null,
        username: username,
      });
      if (result.data) {
        return result.data;
      }
      //setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const followGig = async () => {
    try {
      if (currentId) {
        let token = tempToken
          ? tempToken
          : localStorage[LOCAL_STORAGE_KEY.TOKEN];
        await setAxiosToken(token);
        const result = await axios.post(API.FOLLOW_GIG, { username: username });
        setProfile(() => ({ ...profile, followers: [...result.data] }));
        sendMessage({
          action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
        });
        if (result) {
          setIsFollow(true);
        }
      } else {
        sendMessage({ action: WEBVIEW_ACTION.LOG_OUT });
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const unfollowGig = async () => {
    try {
      let token = tempToken ? tempToken : localStorage[LOCAL_STORAGE_KEY.TOKEN];
      await setAxiosToken(token);
      const result = await axios.post(API.UN_FOLLOW_GIG, {
        username: username,
      });
      setProfile(() => ({ ...profile, followers: [...result.data] }));
      sendMessage({
        action: WEBVIEW_ACTION.ON_FOREGROUND_FOLLOW,
      });
      if (result.data) {
        setIsFollow(false);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  const handleOpen = () => {
    if (profile.Image && isReadOnly) {
      setOpen(true);
    }
  };
  const handleOpenPhoto = (link) => {
    setOpenPhoto(true);
    setPhotoLink(link);
  };
  const handleOpenDeleteDialog = (type, item, onModal = false) => {
    setSelectedItem({ type: type, item: item, onModal: onModal });
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setSelectedItem({ type: null, item: null, onModal: false });
    setOpenDeleteDialog(false);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenCompanyImage(false);
    setOpenPhoto(false);
    setPhotoLink(null);
  };
  const allowEdit = () => {
    setIsReadOnly(!isReadOnly);
    //console.log(profile);
  };
  useEffect(() => {
    sendMessage({
      action: WEBVIEW_ACTION.ON_UPDATE_PROFILE,
      isCompleted: isReadOnly,
    });
  }, [isReadOnly]);
  const handleOpenYoutubeDialog = (link) => {
    setLinkYoutube(link);
    setOpenYoutubeDialog(true);
  };
  const handleCloseYoutubeDialog = () => {
    setLinkYoutube(null);
    setOpenYoutubeDialog(false);
  };
  const updateProfile = async () => {
    let data = new FormData();
    if (profileEdit.Image) {
      data.append("Image", profileEdit.Image);
    }
    //console.log({ profileEdit });
    if (profileEdit.CompanyImage) {
      data.append("CompanyImage", profileEdit.CompanyImage);
    }

    if (profileEdit.Photos && profileEdit.Photos.length > 0) {
      let originalPhotos = [];
      if (profile.Photos && profile.Photos.length > 0) {
        originalPhotos = profile.Photos.split(",");
      }
      let totalFileUpload = originalPhotos.length + profileEdit.Photos.length;
      if (
        !exceedPhotos &&
        profileEdit.Photos &&
        profileEdit.Photos.length > 0
      ) {
        profileEdit.Photos.forEach((photo, index) =>
          data.append(`portfolio`, photo)
        );
      } else {
        //console.log(originalPhotos);
      }
    }
    if (profileEdit.Pdf) {
      data.append("pdf", profileEdit.Pdf);
    } else {
      if (profile.Pdf === "") {
        data.append("Pdf", "");
      }
    }
    Object.keys(refValues).forEach((key) => {
      if (refValues[key].current) {
        profileEdit[key] = refValues[key].current.value;
        profile[key] = refValues[key].current.value;
      }
    });

    Object.keys(profileEdit).map((key) => {
      if (key === PROFILE_KEYS.SKILLS) {
        data.append(key, JSON.stringify(profileEdit[key]));
      } else {
        if (
          key !== "Image" &&
          key !== "CompanyImage" &&
          key !== "ConfirmPassword" &&
          key !== "Photos" &&
          key !== "Pdf" &&
          key !== "oldPhotos"
        ) {
          //console.log({ value: profileEdit[key] });
          data.append(`${key}`, profileEdit[key]);
        } else {
          if (key === "oldPhotos") {
            let profileOldPhotos =
              previewPhotosList.length > 0
                ? previewPhotosList
                    .filter((item) => item.includes(FORM_TYPES.PORTFOLIO))
                    .join(",")
                : "";
            data.append(`${key}`, profileOldPhotos);
          }
        }
      }
    });
    console.log({ data });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      let dataIsValid = false;
      if (Object.keys(profileEdit).length > 0) {
        dataIsValid = await validateProfileUpdate(profileEdit);
      } else {
        dataIsValid = true;
      }
      if (!dataIsValid || exceedPdfSize || exceedPhotos) {
        setInValidUrl(true);
      } else {
        console.log("Loading...");
        if (exceedPhotos) {
          setExceedPhotos(false);
        }
        setIsLoading(true);
        if (profile.PhoneNumber.length === 8) {
          setInValidUrl(false);
          const result = await axios.post(API.UPDATE_PROFILE, data, config);
          setIsReadOnly(!isReadOnly);
          setErrorObj({});
          if (result.status === 200) {
            //alert(JSONStringifyObj(result.data));
            updateProfilePicture(result.data);
            let tempEmail = profileEdit.Email;

            setBlobList([]);
            clearPreviewLinks();

            let previewList = [];
            if (result.data.Photos && result.data.Photos.length > 0) {
              previewList = result.data.Photos.split(",");
              setPreviewPhotoList(previewList);
            }
            setOnUpdate(false);

            setDefaultRef();
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (isLoading && profileEdit.SkillIds) {
      let newSkills = profileEdit.SkillIds.reduce((acc, cur) => {
        acc.push(cur.Name);
        return acc;
      }, []);
      setProfile({ ...profile, Skills: newSkills });
    }
  }, [isLoading]);
  const validatePassword = (passwordObj) => {
    return passwordObj.Password === passwordObj.ConfirmPassword ? true : false;
  };
  const updateProfilePicture = (profileUpdate) => {
    // console.log({ profileUpdate, profile });
    let listKeys = Object.keys(profileUpdate);
    // if (listKeys && listKeys.length > 0) {
    //   listKeys.forEach((key) => {
    //     setProfile({ ...profile, [key]: profileUpdate[key] });
    //   });
    // }
    if (profileUpdate.Image) {
      setProfile({ ...profile, Image: profileUpdate.Image });
    }
    if (profileUpdate.CompanyImage) {
      setProfile({
        ...profile,
        CompanyImage: profileUpdate.CompanyImage,
      });
    }
    if (profileUpdate.Pdf) {
      setProfile({ ...profile, Pdf: profileUpdate.Pdf });
    } else {
      setProfile({ ...profile, Pdf: null });
    }

    if (profileUpdate.Photos) {
      setProfile({ ...profile, Photos: profileUpdate.Photos });
    } else {
      let originalPhotos = profile.Photos;
      let previewPhotos = previewPhotosList.join(",");
      if (onDeletePhoto) {
        //alert("HERE");
        setProfile({ ...profile, Photos: previewPhotos });
        setOnDeletePhoto(false);
      } else {
        //alert("THERE");
        setProfile({ ...profile, Photos: originalPhotos });
      }
    }

    // if (profileUpdate.YoutubeLinks) {
    //   setProfile({ ...profile, YoutubeLinks: profileUpdate.YoutubeLinks });
    // } else {
    //   setProfile({ ...profile, YoutubeLinks: profileUpdate.YoutubeLinks });
    // }
    setProfileEdit({});
  };
  const checkValidateUrlData = (url) => {
    let urlIsValid = true;
    if (url && url.length > 0) {
      if (url.includes(FORM_TYPES.WHITE_SPACE)) {
        urlIsValid = false;
      } else {
        urlIsValid = validateUrl(url);
      }
    }
    return urlIsValid;
  };

  const checkValidateEmail = async (Email) => {
    try {
      let emailValidObj = { isValid: true };
      let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = await regex.test(Email);

      if (isValidEmail) {
        // check exist
        const result = await axios.post(API.CHECK_EMAIL_EXISTS, { Email }, {});
        if (result.data.isExist) {
          emailValidObj.isValid = false;
          emailValidObj.message =
            PROFILE_FORM.ERROR_MESSAGE.EMAIL_IS_ALREADY_EXIST;
        }
      } else {
        emailValidObj.isValid = false;
        emailValidObj.message = PROFILE_FORM.ERROR_MESSAGE.EMAIL_IS_INVALID;
      }
      return emailValidObj;
    } catch (error) {
      console.log({ error });
    }
  };
  const validateProfileUpdate = async (profileUpdate) => {
    let dataIsValid = true;
    let error = {};

    if (profileUpdate.Email && profileUpdate.Email !== oldEmail) {
      let emailValidObj = await checkValidateEmail(profileUpdate.Email);
      dataIsValid = emailValidObj.isValid;
      console.log({ dataIsValid });
      error.invalidEmail = dataIsValid === false;
      error.message = emailValidObj.message;
    }
    if (profileUpdate.Website) {
      dataIsValid = checkValidateUrlData(profileUpdate.Website);
      error.invalidUrl = dataIsValid === false;
    }
    if (profileUpdate.WebsiteLink) {
      dataIsValid = checkValidateUrlData(profileUpdate.WebsiteLink);
      error.invalidUrl = dataIsValid === false;
    }
    if (profileUpdate.Password || profileUpdate.ConfirmPassword) {
      dataIsValid = validatePassword(profileUpdate);
      error.invalidPassword = dataIsValid === false;
    }
    setErrorObj(error);
    return dataIsValid;
  };
  const handleChange = async (e, type, name) => {
    const imageTool = new ImageTools();
    if (e.target.name === FORM_TYPES.DOB) {
      setProfile({
        ...profile,
        [e.target.name]: e.target.value,
      });
    } else {
      setProfile({
        ...profile,
        [e.target.name]:
          type == FORM_TYPES.FILE ? e.target.files[0] : e.target.value,
      });
    }
    setProfileEdit({
      ...profileEdit,
      [e.target.name]:
        type == FORM_TYPES.PHOTOS
          ? e.target.files
          : type == FORM_TYPES.FILE
          ? e.target.files[0]
          : type == FORM_TYPES.PASSWORD
          ? trimValue(e.target.value)
          : e.target.value,
    });
    if (e.target.files) {
      if (e.target.name === FORM_TYPES.PHOTOS) {
        console.log({ files: e.target.files });
        let filesUpload = Object.keys(e.target.files);
        let previewBlobs;
        let existedPhotos = profile.Photos ? profile.Photos.split(",") : [];
        let preview = previewPhotosList;
        console.log({ preview, filesUpload });
        let totalPhotos = previewPhotosList.length + filesUpload.length;
        if (totalPhotos <= FORM_TYPES.MAX_PHOTOS_UPLOAD) {
          setExceedPhotos(false);
          //console.log({ files: filesUpload });
          filesUpload.map((key) => {
            loadImage(e.target.files[key], {
              maxWidth: compressedFile.maxWidthOrHeight,
              maxHeight: compressedFile.maxWidthOrHeight,
              orientation: true,
              canvas: true,
            })
              .then(async (data) => {
                const image = imageTool.canvastoBlob(data.image, "image/png");
                let listImagePreview = previewPhotosList;
                let listImageBlob = blobList;
                let profilePhotos = profile.Photos
                  ? profile.Photos.split(",")
                  : [];
                //console.log({ profilePhotos });
                let editedPhotos = profileEdit.oldPhotos || [];
                let photosList = [
                  ...new Set([...editedPhotos, ...profilePhotos]),
                ];
                let oldPhotos = photosList.join(",");
                listImagePreview.push(image.data);
                listImageBlob.push(image.blob);
                console.log({ listImageBlob });

                //console.log({ oldPhotosChange: oldPhotos });
                let previewList = [...new Set([...listImagePreview])];
                setBlobList([...listImageBlob]);
                setPreviewPhotoList(listImagePreview);
                setProfileEdit({
                  ...profileEdit,
                  Photos: [...listImageBlob],
                  oldPhotos: oldPhotos,
                });
                // if (
                //   listImageBlob.length + photosList.length <=
                //   FORM_TYPES.MAX_PHOTOS_UPLOAD
                // ) {
                //   setExceedPhotos(false);
                // } else {
                //   setExceedPhotos(true);
                // }
              })
              .catch((err) => {
                console.log(err);
              });
          });
        } else {
          // Photos upload Error
          //alert("Error");
          setExceedPhotos(true);
        }
      } else if (e.target.name === FORM_TYPES.PDF) {
        if (e.target.files[0].size <= FORM_TYPES.MAX_PDF_SIZE) {
          console.log({ pdf: e.target.files[0] });
          setExceedPdfSize(false);
          setProfileEdit({ ...profileEdit, Pdf: e.target.files[0] });
          setProfile({ ...profile, Pdf: e.target.files[0].name });
        } else {
          setExceedPdfSize(true);
        }
      } else {
        loadImage(e.target.files[0], {
          maxWidth: compressedFile.maxWidthOrHeight,
          maxHeight: compressedFile.maxWidthOrHeight,
          orientation: true,
          canvas: true,
        })
          .then(async (data) => {
            const image = imageTool.canvastoBlob(data.image, "image/png");
            setPreviewPhoto({ ...previewPhoto, [name]: image.data });
            setProfile({
              ...profile,
              [name]: image.blob,
            });
            setProfileEdit({
              ...profileEdit,
              [name]: image.blob,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const removePhotosItem = (removeItem) => {
    if (exceedPhotos) {
      setExceedPhotos(false);
    }
    let removeItemIndex = previewPhotosList.indexOf(removeItem);
    let oldPhotos = previewPhotosList;
    let editedPhotos = profileEdit.Photos;
    //console.log({ oldPhotos, editedPhotos });
    let newList = previewPhotosList.filter((item) => item !== removeItem);
    let remainOriginalPhotos = [];
    if (oldPhotos && oldPhotos.length > 0) {
      remainOriginalPhotos = oldPhotos.filter(
        (item, index) =>
          index !== removeItemIndex && item.includes(FORM_TYPES.PORTFOLIO)
      );
    }
    // console.log({
    //   remainOriginalPhotos: remainOriginalPhotos,
    //   oldPhotos,
    // });
    let removePhotoIndex = 0;
    let uploadPhotos = [];
    // console.log({ newList, removeItemIndex });
    if (
      editedPhotos &&
      editedPhotos.length > 0 &&
      !removeItem.includes(FORM_TYPES.PORTFOLIO)
    ) {
      //console.log({ removeItem });
      removePhotoIndex =
        removeItemIndex > newList.length ? removeItemIndex - newList.length : 0;
      uploadPhotos = editedPhotos.filter(
        (item, index) => index !== removePhotoIndex
      );
    } else {
      if (editedPhotos) {
        uploadPhotos = editedPhotos;
      }
    }
    //console.log({ removePhotoIndex, uploadPhotos });
    setOnDeletePhoto(true);
    setPreviewPhotoList([...newList]);
    let oldPhotosUpdate = remainOriginalPhotos.join(",");
    setProfileEdit({
      ...profileEdit,
      oldPhotos: oldPhotosUpdate,
      Photos: uploadPhotos,
    });
  };
  const deleteSelectedItem = () => {
    const { type, item, onModal } = selectedItem;
    if (type && (item || item === 0)) {
      console.log({ type });
      switch (type) {
        case FORM_TYPES.PDF:
          removePdfItem(item);
          break;
        case FORM_TYPES.PHOTOS:
          removePhotosItem(item);
          break;
        case FORM_TYPES.YOUTUBE:
          console.log({ selectedItem });
          removeYoutubeLink(item, onModal);
          break;
        default:
          break;
      }
    }
    handleCloseDeleteDialog();
  };
  const removePdfItem = (removeItem) => {
    console.log("Remove");
    let newPdf = "";
    if (exceedPdfSize) {
      setExceedPdfSize(false);
    }
    setProfileEdit({ ...profileEdit, Pdf: newPdf });
    setProfile({ ...profile, Pdf: "" });
  };
  const removeYoutubeLink = (index, onModal = false) => {
    let oldLinks = [];
    if (previewLinks) {
      oldLinks = JSONParseObj(previewLinks);
    } else {
      if (profile.YoutubeLinks) {
        oldLinks = JSONParseObj(profile.YoutubeLinks);
      }
    }

    let newLinks = JSONStringifyObj(oldLinks.filter((item, i) => i !== index));
    console.log({ newLinks });
    if (onModal) {
      setPreviewLinks(newLinks);
      // setProfileEdit({ ...profileEdit, YoutubeLinks: newLinks });
      // setProfile({ ...profile, YoutubeLinks: newLinks });
    } else {
      setProfileEdit({ ...profileEdit, YoutubeLinks: newLinks });
      setProfile({ ...profile, YoutubeLinks: newLinks });
      setPreviewLinks("");
    }
  };

  const clearPreviewLinks = () => {
    setPreviewLinks("");
    setYoutubeLinkError(null);
  };
  const updateProfileYoutubeLinks = () => {
    console.log({ previewLinks });
    //alert(JSONStringifyObj(previewLinks));
    if (previewLinks) {
      let profileYoutubeLinks = profile.YoutubeLinks
        ? JSONParseObj(profile.YoutubeLinks)
        : [];
      let newYoutubeLinks = JSONParseObj(previewLinks);
      let updatedLinks = JSONStringifyObj([
        ...profileYoutubeLinks,
        ...newYoutubeLinks,
      ]);
      setProfileEdit({ ...profileEdit, YoutubeLinks: updatedLinks });
      setProfile({ ...profile, YoutubeLinks: updatedLinks });
      setPreviewLinks("");
    }
  };
  const updateYoutubeLink = (link) => {
    let oldLinks = previewLinks ? JSONParseObj(previewLinks) : [];
    let existedLinks = profile.YoutubeLinks
      ? JSONParseObj(profile.YoutubeLinks)
      : [];
    if (
      refValues["linkRef"].current &&
      refValues["linkRef"].current.value &&
      refValues["linkTitleRef"].current &&
      refValues["linkTitleRef"].current.value
    ) {
      let link = refValues["linkRef"].current.value;
      let title = refValues["linkTitleRef"].current.value;
      let totalLinks = existedLinks.length + oldLinks.length;
      if (
        (link.toLowerCase().includes(FORM_TYPES.YOUTUBE_LINK) ||
          link.toLowerCase().includes(FORM_TYPES.YOUTUBE_BE)) &&
        totalLinks <= FORM_TYPES.MAX_LINKS
      ) {
        let linkObj = { title: title, link: link };
        console.log({ linkObj, oldLinks });
        setYoutubeLinkError(null);
        oldLinks.push(linkObj);
        let newLinks = JSON.stringify(oldLinks);
        setPreviewLinks(newLinks);
      } else {
        if (totalLinks > FORM_TYPES.MAX_LINKS) {
          setYoutubeLinkError(PORTFOLIO_MESSAGE.ERROR.EXCEED_YOUTUBE_LINK);
        } else {
          setYoutubeLinkError(PORTFOLIO_MESSAGE.ERROR.INVALID_YOUTUBE_LINK);
        }
      }
      refValues["linkRef"].current.value = "";
      refValues["linkTitleRef"].current.value = "";
    } else {
      setYoutubeLinkError(PORTFOLIO_MESSAGE.ERROR.TITLE_IS_REQUIRED);
    }
  };
  const handleSelected = (type, value) => {
    setProfile({ ...profile, [type]: value });
    setProfileEdit({ ...profileEdit, [type]: value });
  };
  const logOut = async () => {
    const newDarkState = !darkState;
    localStorage.removeItem("token");
    localStorage.removeItem(LOCAL_STORAGE_KEY.DARK_MODE);
    localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_ID);
    setTimeout(() => {
      sendMessage({
        action: WEBVIEW_ACTION.LOG_OUT,
        darkMode: newDarkState,
      });
    }, 200);
  };
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        "& > *": {
          margin: theme.spacing(1),
        },
      },
      medium: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginBottom: 7,
      },
    })
  );
  const classes = useStyles();
  const [value, setValue] = React.useState(2);

  // Dark Mode
  const [darkState, setDarkState] = useState(
    localStorage[LOCAL_STORAGE_KEY.DARK_MODE]
      ? JSON.parse(localStorage[LOCAL_STORAGE_KEY.DARK_MODE])
      : false
  );
  const palletType = darkState ? "dark" : "light";
  // set default Dark Mode
  useEffect(() => {
    //console.log("DARK STATE RUNNN");
    if (darkState !== null) {
      darkState
        ? document.body.classList.add("darkmode")
        : document.body.classList.remove("darkmode");
    }
  }, [darkState]);
  const darkTheme = createMuiTheme({
    palette: {
      type: darkState ? "dark" : "light",
      background: {
        default: darkState ? "#121212" : "#f5f5f5",
      },
      primary: {
        main: "#832CFF",
      },
      secondary: {
        main: "#f44336",
      },
    },
    typography: {
      fontFamily: "Montserrat, Arial",
      fontWeightRegular: 500,
      fontSize: 13.5,
    },
  });
  const handleThemeChange = () => {
    const newDarkState = !darkState;
    setDarkState(newDarkState);
    localStorage.setItem(LOCAL_STORAGE_KEY.DARK_MODE, newDarkState);
    sendMessage({
      action: WEBVIEW_ACTION.TOGGLE_DARK_MODE,
      darkMode: newDarkState,
    });
  };
  const shareProfile = () => {
    let profileUrl = username
      ? window.location.href
      : `${window.location.href}/${profile.UserName}`;
    sendMessage({
      action: WEBVIEW_ACTION.ON_SHARE,
      url: profileUrl,
    });
  };

  const handleReviewsGiggerClick = () => {
    let profileName = username || profile.UserName;
    sendMessage({
      action: WEBVIEW_ACTION.GO_TO_RATING_PAGE,
      url: `${CLIENT_DOMAIN}user/${profileName}/${REVIEW_TYPE.GIGER}/userprofilereview`,
      title: "Reviews for Gigger",
    });
  };

  const handleReviewsGigBossClick = () => {
    let profileName = username || profile.UserName;
    sendMessage({
      action: WEBVIEW_ACTION.GO_TO_RATING_PAGE,
      url: `${CLIENT_DOMAIN}user/${profileName}/${REVIEW_TYPE.GIGBOSS}/userprofilereview`,
      title: "Reviews for Gig Boss",
    });
  };
  const handleCloseEditModal = () => {
    setOpenState(false);
  };
  const handleOpenEditModal = () => {
    if (!username) {
      setOpenState(true);
    }
  };
  const handleClickUrl = () => {
    console.log(profile.Website);
    if (profile.Website) {
      openUrl(profile.Website, onApp);
    }
  };
  const handleClickSocial = (socialLink) => {
    if (socialLink) {
      openUrl(socialLink, onApp);
    }
  };
  const openLinkExternal = (link) => {
    openUrl(link, onApp);
  };
  const openWebviewPdf = (filePdf) => {
    if (filePdf) {
      sendMessage({
        action: WEBVIEW_ACTION.OPEN_PDF,
        url: `https://docs.google.com/viewer?url=https://gigsomething.sg/profile/${filePdf}&embedded=true`,
        title: removePdfExt(filePdf),
      });
    }
  };
  const validateUrl = (websiteLink) => {
    let urlRegex = new RegExp(REGEX.URL);
    const isValidUrl = urlRegex.test(websiteLink);
    return isValidUrl;
  };
  const handleOpenCompanyImage = () => {
    if (profile.CompanyImage && isReadOnly) {
      setOpenCompanyImage(true);
    }
  };
  const handleOpenUrl = ({ type }) => {
    //console.log({ type });
    if (onApp) {
      let paramUrl = type;
      sendMessage({
        action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
        url: `${CLIENT_DOMAIN}${paramUrl}`,
        open: true,
      });
    } else {
      // let paramUrl = type;
      // let url = `${CLIENT_DOMAIN}${paramUrl}`;
      // console.log({ url });
    }
  };
  const toggleModal = () => {
    setShowReportModal(!showReportModal);
    // && !onForeGround
  };
  const openGigAlert = () => {
    setOpenGigFilter(true);
  };
  const closeGigAlert = () => {
    setOpenGigFilter(false);
  };
  const openNotificationModal = () => {
    sendMessage({ action: WEBVIEW_ACTION.ON_OPEN_MODAL_PROFILE });
    setOpenNotification(true);
  };
  const closeNotificationModal = () => {
    sendMessage({
      action: WEBVIEW_ACTION.ON_OPEN_MODAL_PROFILE,
      closeModal: true,
    });
    setOpenNotification(false);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      {Object.keys(profile).length > 0 ? (
        <div style={{ minHeight: "100vh" }}>
          <Helmet>{initGA()}</Helmet>
          <Sticky>
            <div
              className={
                isReadOnly
                  ? "boxUserProfile"
                  : "boxUserProfile boxUserProfileonEdit"
              }
            >
              <div className="buttonEdit">
                {onApp && username ? (
                  <Fragment>
                    {!isFollow ? (
                      <div className="followbtn">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={followGig}
                        >
                          {BUTTON_TEXT.FOLLOW}
                        </Button>
                      </div>
                    ) : (
                      <div className="followbtn">
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={unfollowGig}
                        >
                          {BUTTON_TEXT.UNFOLLOW}
                        </Button>
                      </div>
                    )}
                  </Fragment>
                ) : haveToken && !username ? (
                  <IconButton
                    onClick={isReadOnly ? allowEdit : updateProfile}
                    variant="inherit"
                    style={{ color: "#fff" }}
                  >
                    {isReadOnly ? <EditRoundedIcon /> : <SaveIcon />}
                  </IconButton>
                ) : (
                  <div className="followbtn"></div>
                )}
                {/* {!username ? (
                  <IconButton
                    onClick={isReadOnly ? allowEdit : updateProfile}
                    variant="inherit"
                    style={{ color: "#fff" }}
                  >
                    {isReadOnly ? <EditRoundedIcon /> : <SaveIcon />}
                  </IconButton>
                ) : null} */}

                {username ? null : (
                  <span>
                    {/* <Notifications
                      os={os}
                      data={alertData}
                      open={openNotification}
                      openModal={openNotificationModal}
                      closeModal={closeNotificationModal}
                      removeAlert={removeAllAlert}
                      updateAlert={updateCheckAlert}
                    /> */}
                    <IconButton
                      style={{ color: "#fff" }}
                      onClick={shareProfile}
                    >
                      <ShareIcon />
                    </IconButton>
                  </span>
                )}
              </div>

              <div className="avatarUser">
                {isReadOnly ? (
                  <Avatar
                    onClick={handleOpen}
                    alt={profile.username}
                    src={
                      previewPhoto["Image"]
                        ? previewPhoto["Image"]
                        : profile.Image
                        ? `${DOMAIN}profile/${profile.Image}`
                        : "/static/images/avatar/1.jpg"
                    }
                    className="avatar"
                  />
                ) : (
                  <Fragment>
                    <Avatar
                      alt={profile.username}
                      src={
                        previewPhoto["Image"]
                          ? previewPhoto["Image"]
                          : profile.Image
                          ? `${DOMAIN}profile/${profile.Image}`
                          : "/static/images/avatar/1.jpg"
                      }
                      className="avatar"
                    />
                    <input
                      type="file"
                      className="file"
                      name="Image"
                      accept="image/*"
                      onChange={(e) =>
                        handleChange(e, FORM_TYPES.FILE, "Image")
                      }
                    />
                  </Fragment>
                )}
                <div className="name" style={{ textAlign: "center" }}>
                  <h5>{`${profile.UserName ? profile.UserName : ""}`}</h5>
                  {/* <Typography
                  variant="subtitle1"
                  style={{ color: "#999", marginBottom: 10, fontSize: 14 }}
                >
                  {`${profile.Skills}`}
                </Typography> */}
                </div>
              </div>
              <div className="profile-widgets">
                <div>
                  <h6
                    className="text-muted"
                    onClick={
                      reviewsGiggerAverageStars > 0
                        ? handleReviewsGiggerClick
                        : null
                    }
                  >
                    Reviews for Gigger{" "}
                    <span>
                      {" "}
                      {`(${
                        reviewsGiggerAverageStars > 0
                          ? reviewsGiggerAverageStars
                          : 0
                      } / 5)`}{" "}
                    </span>
                  </h6>
                  <Rating
                    name="half-rating-read"
                    value={
                      reviewsGiggerAverageStars > 0
                        ? reviewsGiggerAverageStars
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </div>
                <div>
                  <h6
                    className="text-muted"
                    onClick={
                      reviewsGigBossAverageStars > 0
                        ? handleReviewsGigBossClick
                        : null
                    }
                  >
                    Reviews for Gig Boss{" "}
                    <span>
                      {" "}
                      {`${
                        reviewsGigBossAverageStars > 0
                          ? reviewsGigBossAverageStars
                          : 0
                      } / 5`}{" "}
                    </span>
                  </h6>
                  <Rating
                    name="half-rating-read"
                    value={
                      reviewsGigBossAverageStars > 0
                        ? reviewsGigBossAverageStars
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </Sticky>
          {/* {console.log(profile.followers.length)} */}
          {profile.followers && profile.followings ? (
            <Follows
              followers={profile.followers}
              following={profile.followings}
              username={username}
              followerTitle={FOLLOW_KEYWORD.FOLLOWERS}
              followingTitle={FOLLOW_KEYWORD.FOLLOWING}
            />
          ) : null}
          {/* {profile.followers && profile.followers.length > 0 ? (
            <FollowProfile
              follows={profile.followers}
              username={username}
              title="Followers"
            />
          ) : null}
          {profile.followings && profile.followings.length > 0 && !username ? (
            <FollowProfile
              follows={profile.followings}
              username={username}
              title="Following"
            />
          ) : null} */}
          <Gigs
            os={os}
            profile={profile}
            profileEdit={profileEdit}
            selectedSkills={profile.SkillIds}
            handleSelected={handleSelected}
            isReadOnly={isReadOnly}
            companyImage={companyImage}
            previewPhoto={previewPhoto}
            previewPhotosList={previewPhotosList}
            previewLinks={previewLinks}
            exceedPhotos={exceedPhotos}
            exceedPdfSize={exceedPdfSize}
            youtubeLinkError={youtubeLinkError}
            removePhotosItem={removePhotosItem}
            removePdfItem={removePdfItem}
            removeYoutubeLink={removeYoutubeLink}
            updateYoutubeLink={updateYoutubeLink}
            clearPreviewLinks={clearPreviewLinks}
            openLinkExternal={openLinkExternal}
            openPdf={openWebviewPdf}
            openYoutube={handleOpenYoutubeDialog}
            openDeleteDialog={openDeleteDialog}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleCloseDeleteDialog={handleCloseDeleteDialog}
            updateProfileYoutubeLinks={updateProfileYoutubeLinks}
            username={username}
            inValidUrl={inValidUrl}
            errorObj={errorObj}
            handleClickSocial={handleClickSocial}
            handleChange={handleChange}
            handleChangeLink={handleChangeLink}
            inputLink={inputLink}
            handleOpenEditModal={handleOpenEditModal}
            handleClickUrl={handleClickUrl}
            handleOpen={handleOpenCompanyImage}
            handleOpenPhoto={handleOpenPhoto}
            refValues={refValues}
          />
          {profile.Image && isReadOnly && (
            <ImageComponent
              avatar={`${DOMAIN}profile/${profile.Image}`}
              open={open}
              handleClose={handleClose}
            />
          )}
          {profile.CompanyImage && isReadOnly && (
            <ImageComponent
              avatar={`${DOMAIN}profile/${profile.CompanyImage}`}
              open={openCompanyImage}
              handleClose={handleClose}
            />
          )}
          {profile.Photos && isReadOnly && (
            <ModalSliderPhotos
              photos={profile.Photos && profile.Photos.split(",")}
              open={openPhoto}
              handleClose={handleClose}
            />
          )}
          {username ? null : (
            <Fragment>
              <div className="links">
                <ListItem button style={{ paddingRight: 7 }}>
                  Dark Mode{" "}
                  <Switch
                    color="primary"
                    checked={darkState}
                    onChange={handleThemeChange}
                  />
                </ListItem>
                <ListItem className="GigAlert" onClick={() => openGigAlert()}>
                  Gig Alert
                  <ArrowForwardIosIcon
                    style={{ color: "#832cff", fontSize: 15 }}
                  />
                </ListItem>

                {!username && currentId && (
                  <ListItem
                    className="GigAlert"
                    onClick={() => handleOpenUrl({ type: "LikedGig" })}
                    style={{ borderTop: 0 }}
                  >
                    Liked Gigs
                    <ArrowForwardIosIcon
                      style={{ color: "#832cff", fontSize: 15 }}
                    />
                  </ListItem>
                )}
                {/* <JobBrowsingDialog
                  openDialog={openGigFilter}
                  handleDialogClose={closeGigAlert}
                  // onFilterJobsApply={handleFilterJobsApply}
                  // existingFilterData={filterData}
                /> */}
                {/* <JobBrowsing fromUserProfile={true} /> */}

                {/* {[1, 2].length > 0 && (
                  <ReviewsGigs
                    data={[
                      {
                        GigerId: 1,
                        Title: "Travel Sales Ambassador",
                        GigBossId: 16,
                        completedId: 103,
                        Image: "photo-1598608713318.jpeg",
                        CompanyImage: null,
                        jobId: 231,
                      },
                      {
                        GigerId: 1,
                        Title: "?TEMP SCREENER?",
                        GigBossId: 282,
                        completedId: 102,
                        Image: "photo-1602654629472.jpeg",
                        CompanyImage: null,
                        jobId: 210,
                      },
                    ]}
                  />
                )} */}
                <ListItem onClick={() => handleOpenUrl({ type: "About" })}>
                  About gigsomething
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                </ListItem>
                <ListItem onClick={() => handleOpenUrl({ type: "Community" })}>
                  Community Standards
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                  {/* <CommunityStandards /> */}
                </ListItem>
                <ListItem onClick={() => handleOpenUrl({ type: "Faq" })}>
                  FAQ
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                  {/* <FAQ /> */}
                </ListItem>
                <GigAlert
                  fromProfile={true}
                  onForeground={onForeground}
                  openState={openGigFilter}
                  handleClose={closeGigAlert}
                />

                <ListItem
                  onClick={() => handleOpenUrl({ type: "ChangePassword" })}
                >
                  Change Password
                  <ArrowForwardIosIcon style={{ fontSize: 15, opacity: 0.5 }} />
                </ListItem>
              </div>
              {isReadOnly ? (
                <div className="logOut">
                  <Button onClick={logOut} color="secondary">
                    {BUTTON_TEXT.LOG_OUT}
                  </Button>
                </div>
              ) : null}
            </Fragment>
          )}
          <DialogComponent
            openState={openState}
            showEdit={true}
            handleClose={handleCloseEditModal}
          />
          {onApp && username && currentId && (
            <div style={{ margin: "0 16px" }}>
              <Link
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#832cff",
                }}
                onClick={toggleModal}
              >
                {BUTTON_TEXT.REPORT}
              </Link>
            </div>
          )}
          {showReportModal ? (
            <ReportModal
              openDialog={showReportModal}
              toggleModal={toggleModal}
              reportGigger={reportGigger}
            />
          ) : null}
          {openDeleteDialog && (
            <DeleteDialog
              openState={openDeleteDialog}
              handleClose={handleCloseDeleteDialog}
              handleOk={deleteSelectedItem}
              message={PORTFOLIO_MESSAGE.DIALOG.DELETE_ITEM}
            />
          )}
          {openYoutubeDialog && linkYoutube && (
            <YoutubeDialog
              openState={openYoutubeDialog}
              handleClose={handleCloseYoutubeDialog}
              linkYoutube={linkYoutube}
            />
          )}
        </div>
      ) : (
        <Fragment>
          <Loading />
        </Fragment>
      )}
      {isLoading ? <Loading /> : null}
    </ThemeProvider>
  );
}
