import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../config/config";
import ReviewRatingComments from "./Reviews/ReviewRatingComments";
import ReviewRatingPercent from "./Reviews/ReviewRatingPercent";

const Reviews = ({ job, giggerJob, props }) => {
  //#region initial

  const [gigBossName, setGigBossName] = useState("");
  const [reviewRatingPercentKey, setReviewRatingPercentKey] = useState(
    Math.floor(Math.random() * 90000) + 10000
  );

  //#endregion

  //#region useEffect

  useEffect(() => {
    const fetchData = async () => {
      const getGigBossById = await axios.post(API.GET_GIGGER_BY_ID, {
        giggerId: job.GigBossId
      });

      if (getGigBossById.data) {
        const {
          FirstName,
          LastName,
          UserName,
          CompanyName
        } = getGigBossById.data;
        let gigBossName =
          FirstName && FirstName.trim() !== ""
            ? `${FirstName}${
                LastName && LastName.trim() !== "" ? ` ${LastName}` : ""
              }`
            : `${UserName}`;
        setGigBossName(
          CompanyName && CompanyName.length > 0 ? CompanyName : gigBossName
        );
      }
    };

    fetchData();
  }, [job]);

  //#endregion

  return (
    <div className="reviews">
      <h5>Reviews {gigBossName.length > 0 ? `for ${gigBossName}` : ""}</h5>
      <ReviewRatingPercent
        key={reviewRatingPercentKey}
        isPostComment={true}
        job={job}
        giggerJob={giggerJob}
        props={props}
        setReviewRatingPercentKey={setReviewRatingPercentKey}
      />
      <ReviewRatingComments isDialogReviews={true} job={job} props={props} />
    </div>
  );
};

export default Reviews;
