import React, { Fragment, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { createMuiTheme } from "@material-ui/core/styles";
import { DOMAIN, WEBVIEW_ACTION, CLIENT_DOMAIN } from "../../../config/config";
import { sendMessage } from "../../../ultils/ultils";
import { useDispatch, useSelector } from "react-redux";
import { ON_SEND_MESSAGE } from "../../../store/actions/actionTypes";

export default function CategoryItem({ category }) {
  const [isAllow, setIsAllow] = useState(true);
  const onSendMessage = useSelector(
    (state) => state.reduxReducer.onSendMessage
  );
  const dispatch = useDispatch();
  const goToGigCategory = (item) => {
    if (isAllow && !onSendMessage) {
      setIsAllow(false);
      dispatch({ type: ON_SEND_MESSAGE });
      console.log({ cate: item });
      sendMessage({
        action: WEBVIEW_ACTION.GO_TO_GIGS_CATEGORY,
        url: `${CLIENT_DOMAIN}gigsByCategory/${item.Id}`,
        categoryName: item.Name,
      });
    }
  };
  useEffect(() => {
    if (!isAllow) {
      setTimeout(() => {
        dispatch({ type: ON_SEND_MESSAGE });
        setIsAllow(true);
      }, 1000);
    }
  }, [isAllow]);
  return (
    <Fragment>
      <li>
        {category.map((item) => (
          <ListItem
            button
            className="item"
            onClick={() => goToGigCategory(item)}
          >
            <img src={`${DOMAIN}/job-category/${item.Icon}`} />
            <p>
              <span>{item.Name}</span>
            </p>
          </ListItem>
        ))}
      </li>
    </Fragment>
  );
}
