import React from "react";

export default function EULA() {
  return (
    <div className="eula">
      <h2>
        End-User License Agreement (EULA) of
        <span class="app_name"> gigsomething</span>
      </h2>
      <p>
        This End-User License Agreement ("EULA") is a legal agreement between
        you and <span class="company_name"> Bigfoot Events Pte Ltd</span>.
      </p>
      <p>
        This EULA agreement governs your acquisition and use of our
        <span class="app_name">gigsomething</span> app directly from
        <span class="company_name"> Bigfoot Events Pte Ltd</span>.
      </p>
      <p>
        Please read this EULA agreement carefully before completing the
        installation process and using the{" "}
        <span class="app_name">gigsomething</span> app. It provides a license to
        use the <span class="app_name">gigsomething</span> app and contains
        warranty information and liability disclaimers.
      </p>
      <p>
        By clicking "accept" or installing and/or using the
        <span class="app_name">gigsomething</span> app, you are confirming your
        acceptance of the app and agreeing to become bound by the terms of this
        EULA agreement.
      </p>
      <p>
        If you are entering into this EULA agreement on behalf of a company or
        other legal entity, you represent that you have the authority to bind
        such entity and its affiliates to these terms and conditions. If you do
        not have such authority or if you do not agree with the terms and
        conditions of this EULA agreement, do not install or use the app, and
        you must not accept this EULA agreement.
      </p>
      <p>
        This EULA agreement shall apply only to the app supplied by
        <span class="company_name"> Bigfoot Events Pte Ltd</span> herewith
        regardless of whether other app is referred to or described herein. The
        terms also apply to any{" "}
        <span class="company_name"> Bigfoot Events Pte Ltd</span> updates,
        supplements, Internet-based services, and support services for the app,
        unless other terms accompany those items on delivery. If so, those terms
        apply.
      </p>
      <h3>License Grant</h3>
      <p>
        <span class="company_name"> Bigfoot Events Pte Ltd</span> hereby grants
        you a personal, non-transferable, non-exclusive licence to use the
        <span class="app_name">gigsomething</span> app on your devices in
        accordance with the terms of this EULA agreement.
      </p>
      <p>
        You are permitted to load the <span class="app_name">gigsomething</span>{" "}
        app (for example a PC, laptop, mobile or tablet) under your control. You
        are responsible for ensuring your device meets the minimum requirements
        of the
        <span class="app_name">gigsomething</span> app.
      </p>
      <p>You are not permitted to:</p>
      <ul>
        <li>
          Edit, alter, modify, adapt, translate or otherwise change the whole or
          any part of the app nor permit the whole or any part of the app to be
          combined with or become incorporated in any other app, nor decompile,
          disassemble or reverse engineer the app or attempt to do any such
          things
        </li>
        <li>
          Reproduce, copy, distribute, resell or otherwise use the app for any
          commercial purpose
        </li>
        <li>
          Allow any third party to use the app on behalf of or for the benefit
          of any third party
        </li>
        <li>
          Use the app in any way which breaches any applicable local, national
          or international law
        </li>
        <li>
          use the app for any purpose that
          <span class="company_name"> Bigfoot Events Pte Ltd</span> considers is
          a breach of this EULA agreement
        </li>
      </ul>
      <h3>Intellectual Property and Ownership</h3>
      <p>
        <span class="company_name"> Bigfoot Events Pte Ltd</span> shall at all
        times retain ownership of the app as originally downloaded by you and
        all subsequent downloads of the app by you. The app (and the copyright,
        and other intellectual property rights of whatever nature in the app,
        including any modifications made thereto) are and shall remain the
        property of
        <span class="company_name"> Bigfoot Events Pte Ltd</span>.
      </p>
      <p>
        <span class="company_name"> Bigfoot Events Pte Ltd</span> reserves the
        right to grant licences to use the app to third parties.
      </p>
      <h3>User Content</h3>
      <p>
        You represent that the Content that you provide to gigsomething shall
        not contain any material (a) protected by copyright, trademark, trade
        secret, patent or any other intellectual property right without
        authorization, or (b) that is defamatory, trade libelous, unlawfully
        threatening or harassing, pornographic, obscene or harmful to minors, or
        (c) that violates any law or regulation, including without limitation,
        the laws and regulations governing export control, unfair competition,
        anti-discrimination, or false advertising.
        <span class="company_name"> Bigfoot Events Pte Ltd</span> reserves the
        right to delete any Content that it determines in its sole discretion
        breaches any of the representations and warranties and covenants set
        forth above, and you agree to indemnify and hold{" "}
        <span class="company_name"> Bigfoot Events Pte Ltd</span> and its
        subsidiaries, affiliates, officers, employees, suppliers, service
        providers and Partner Companies harmless for any claims, losses,
        liabilities and expenses arising out of or relating to any breach of
        this section.
      </p>
      <h3>Termination</h3>
      <p>
        This EULA agreement is effective from the date you first use the app and
        shall continue until terminated. You may terminate it at any time upon
        written notice to{" "}
        <span class="company_name"> Bigfoot Events Pte Ltd</span>.
      </p>
      <p>
        It will also terminate immediately if you fail to comply with any term
        of this EULA agreement. Upon such termination, the licenses granted by
        this EULA agreement will immediately terminate and you agree to stop all
        access and use of the app. The provisions that by their nature continue
        and survive will survive any termination of this EULA agreement.
      </p>
      <h3>Governing Law</h3>
      <p>
        This EULA agreement, and any dispute arising out of or in connection
        with this EULA agreement, shall be governed by and construed in
        accordance with the laws of <span class="country">Singapore</span>.
      </p>
    </div>
  );
}
