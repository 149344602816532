import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Fragment } from "react";
import { PATTERN } from "../../config/config";
export const YoutubeDialog = ({
  openState,
  handleClose,
  handleOk,
  linkYoutube,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const embedLinkYoutube = (url) => {
    let newLink =
      url.includes(PATTERN.HTTP) || url.includes(PATTERN.HTTPS)
        ? url
        : `${PATTERN.HTTPS}${url}`;
    if (newLink.includes("youtu.be")) {
      newLink = newLink.replace("youtu.be", "youtube.com");
    }
    return newLink.replace(".com", ".com/embed/");
  };
  return (
    <Fragment>
      <Dialog
        open={openState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ visibility: "hidden", height: 0 }}>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </div>
        <div className="video-container">
          {/* {console.log(embedLinkYoutube(linkYoutube))} */}
          <iframe
            width="100%"
            src={embedLinkYoutube(linkYoutube)}
            //src="https://youtube.com/embed//iX6_QiqsXYQ"
            //src="https://youtube.com/iX6_QiqsXYQ"
            //src="https://youtu.be/iX6_QiqsXYQ"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
        </div>
      </Dialog>
    </Fragment>
  );
};
