import React, { Fragment } from "react";

export default function CollapseContent({ innerHTML, content }) {
  return (
    <Fragment>
      {innerHTML ? (
        <p dangerouslySetInnerHTML={{ __html: content }}></p>
      ) : (
        <p>{content}</p>
      )}
    </Fragment>
  );
}
