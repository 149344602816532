import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import CollapseTitle from "./CollapseTitle";
import CollapseContent from "./CollapseContent";
export default function CollapseItem({ title, innerHTML, values }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Fragment>
      <li>
        <div className="headertxt" onClick={handleExpandClick}>
          <span aria-expanded={expanded}></span>
          <CollapseTitle title={title} />
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {values.map((value, index) => (
            <CollapseContent
              key={index}
              content={value}
              innerHTML={innerHTML}
            />
          ))}
        </Collapse>
      </li>
    </Fragment>
  );
}
