import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useSelector } from "react-redux";
function ArrowIcon({ isUpward }) {
  return (
    <Fragment>
      {isUpward ? (
        <ArrowUpwardIcon
          style={{ fontSize: 14, marginLeft: "5px", opacity: 0.5 }}
        />
      ) : (
        <ArrowDownwardIcon
          style={{ fontSize: 14, marginLeft: "5px", opacity: 0.5 }}
        />
      )}
    </Fragment>
  );
}

export default ArrowIcon;
