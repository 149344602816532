import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import React, { useState, useEffect, Fragment } from "react";
import { BUTTON_TEXT } from "../../config/config";

const PostJobErrorDialog = (props) => {
  const { openDialog, closeDialog, errorMessages } = props;
  const [renderedMessages, setRenderedMessages] = useState([]);

  useEffect(() => {
    let errors = Object.values(errorMessages);
    setRenderedMessages(errors);
  }, [errorMessages]);

  const handleClose = () => {
    if (closeDialog) {
      closeDialog();
    }
  };

  const handleClick = async () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="dialog">
          {renderedMessages.map((error) => (
            <Fragment key={renderedMessages.indexOf(error)}>
              {error}
              <br />
            </Fragment>
          ))}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClick} color="primary" autoFocus>
            {BUTTON_TEXT.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PostJobErrorDialog;
