import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { Container, Grid, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { WEBVIEW_ACTION } from "../config/config";
import { sendMessage } from "../ultils/ultils";
import DarkMode from "./DarkMode/DarkMode";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CommunityStandards() {
  const classes = useStyles();
  const [darkState, setDarkState] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    sendMessage({
      action: WEBVIEW_ACTION.OPEN_NEW_PAGE,
      open: false,
    });
  };
  return (
    <DarkMode darkState={darkState}>
      <div>
        <Container className="bgCs" maxWidth="sm">
          <div className="cstandard">
            <div className="close">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <h2 className="mb-4">
              Gigsomething <br />
              Community Standards
            </h2>
            <p>
              Creating a professional community requires a foundation of trust
              amongst all users of our app. We’ve established these Community
              Standards to lay out and codify the core values that ground the
              gigsomething community.
            </p>

            <p>
              As we constantly refine our approach to meet the needs of our
              community, these four standards –{" "}
              <b>respect, safety, authenticity and reliability</b> – remain
              central pillars in our efforts to foster belonging and community
              spirit.
            </p>

            <p>
              We’re always working to make sure these guidelines are adhered to
              and strongly recommend all users to follow them.
            </p>
            <h3 class="mt-4 mb-3">Respect </h3>

            <p>
              The gig community is diverse, unique and vibrant like the world we
              live in. We place a great emphasis on mutual respect to do great
              work together. Let’s do so by treating everyone with respect in
              every interaction: in accordance to all applicable laws and not
              discriminate against others because of their ethnicity,
              nationality, religious beliefs, sexual orientation, gender,
              disability or medical conditions.
            </p>

            <h3 className="mt-4 mb-3">Safety</h3>
            <p>
              Everyone wants to work and belong in a community that makes them
              feel safe. Giggers who require specialised personal protection
              equipment (PPE) should be notified in advance and Gigbosses should
              indicate if they are providing it.
            </p>

            <p>
              Giggers should also have obtained the necessary safety training
              and certifications before any commencement of work. The
              gigsomething app serves only as a connecting platform and will not
              be held responsible for any incidents of failure or lapses in
              safety measures.
            </p>

            <p>
              Harassment (sexual or otherwise) and/or bullying can happen in
              many different forms and places. From making threats to unwanted
              malignant contact to releasing personally identifiable information
              and even sending threatening messages.
            </p>

            <p>
              We do not condone this kind of behaviour because it prevents the
              gigsomething community from feeling safe and respected.
            </p>

            <h3 className="mt-4 mb-3">Authenticity</h3>

            <p>
              We believe that everyone has their own unique experiences and are
              entitled to their own views and thoughts about each gig, namely,
              the good and the areas of improvement.
            </p>

            <p>
              Authenticity and fairness of every gig review is crucial to
              building a constructive and professional community. Hence, we
              encourage reviewers to be kind and tactful while providing a
              balanced review based on their first-hand experience during the
              gig.
            </p>

            <p>
              Reviews that are deemed to have malicious intent, slander or
              contain abusive/hate/discriminatory text will be removed and
              further action may be taken where necessary. Let’s all do our part
              to ensure gigsomething is a safe and kind community.
            </p>

            <h3 className="mt-4 mb-3">Reliability</h3>

            <p>
              Creating a community means a place filled with people you can
              count on. We urge Giggers and Gigbosses to live up to their gig
              commitments professionally, consistently and be accountable for
              their actions.
            </p>

            <p>
              Giggers and Gigbosses who have proven unreliable will be removed
              and blacklisted in the community.
            </p>
          </div>
        </Container>
      </div>
    </DarkMode>
  );
}
