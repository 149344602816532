import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  chipFilter: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    },

    marginTop: 14
  }
}));

const ReviewsFilter = ({ handleFilterClick }) => {
  const classes = useStyles();

  const handleClick = (e, filterValue) => {
    handleFilterClick(filterValue);
  };

  return (
    <div className={classes.chipFilter}>
      <Chip
        label="Newest"
        size="small"
        onClick={(e) => handleClick(e, "newest")}
      />{" "}
      <Chip
        label="Oldest"
        size="small"
        onClick={(e) => handleClick(e, "oldest")}
      />
      <Chip
        label="Hightest Rating"
        size="small"
        onClick={(e) => handleClick(e, "hightestRating")}
      />{" "}
      <Chip
        label="Lowest Rating"
        size="small"
        onClick={(e) => handleClick(e, "lowestRating")}
      />
    </div>
  );
};

export default ReviewsFilter;
