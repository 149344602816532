import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import SliderItem from "./SliderItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SliderBanner({ banners, isBanner = false }) {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 5000,
    dots: true,
  };
  return (
    <div>
      <Slider {...settings} className="slider">
        {banners.map((item, i) => (
          <SliderItem
            key={i}
            image={item.image}
            link={item.link}
            isBanner={isBanner}
          />
        ))}
        {/* <SliderItem
          image={
            "https://www.myfitnesschat.com/wp-content/uploads/2019/03/pexels-photo-1509428.jpeg"
          }
          link={"https://gigsomething.sg/Gigs/Details/42135"}
          openInternal={true}
        />
        <SliderItem
          image={
            "https://media.npr.org/assets/img/2020/03/13/gettyimages-667018224_wide-e69134a24ff79fd3a338ff83661815c6299e58bd-s800-c85.jpg"
          }
          link={"https://www.google.com/"}
          openInternal={false}
        /> */}
      </Slider>
    </div>
  );
}
